// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Avatar from "react-avatar";
import MDTypography from "components/MDTypography";

function AvatarCell({ image, name, color, size }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>
        <Avatar name={name} src={image} round={true} size={28} />
      </MDBox>
      <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
        {name}
      </MDTypography>
    </MDBox>
  );
}

// Setting default value for the props of AvatarCell
AvatarCell.defaultProps = {
  image: "",
  color: "dark",
  size: "28",
};

// Typechecking props for the AvatarCell
AvatarCell.propTypes = {
  image: PropTypes.string,
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

export default AvatarCell;
