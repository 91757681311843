/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Chat from "layouts/pages/account/messaging/components/Chat";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";

function MsgInformation() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [bills,setBills] = useState([]);
  const [refresh,setRefresh] = useState(0);
  const [newPreloaded,setNewPreloaded]= useState(false);

  useEffect(() => {
    fetch(
    `${process.env.REACT_APP_BACKEND_URL}/skoopCrmPreloadedResponses`
    ,{
      method: "GET",
      headers: {
        "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
        "Content-type": "application/json; charset=UTF-8"
      }
    })
    .then(res => res.json())
    .then(data => setBills(data))
    .catch(error => console.error('Error:', error))
  }, [refresh,newPreloaded]);

  const handleDeleteCard = async(id) => {
    if(id==null){
      setNewPreloaded(false);
      return ;
    }
    try{
      let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteCrmPreloaded/${id}`,{
        method: "DELETE",
        headers: {
          "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      if(response.ok){
        toast.success("Message Deleted Successfully");
        setRefresh(!refresh);
      }
      else{
        let jsonData = await response.json();
        throw new Error(jsonData.message);
      }
    }catch(err){
      toast.error(err.message);
    }
  };

  const handleAddMessage = () => {
    setNewPreloaded(true);
  }


  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="medium">
          Template Messages
        </MDTypography>
        <MDBox mr={1}>
              <MDButton variant="heading" color="error" onClick={handleAddMessage}>
                <Icon>add</Icon>&nbsp;Add New
              </MDButton>
            </MDBox>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {newPreloaded &&
          <Chat
          billId={null}
          messageData={{}}
          newPreloaded={newPreloaded}
          onDelete={async () => handleDeleteCard(null)}
          closeNew={()=>setNewPreloaded(false)}
          />
          }
          {bills.map((bill, index) => (
            <Chat
              billId={bill.id}
              messageData={bill}
              newPreloaded={newPreloaded}
              noGutter={index === bills.length - 1} // Remove gutter for the last bill
              onDelete={async () => handleDeleteCard(bill.id)}
              closeNew={()=>setNewPreloaded(false)}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

Chat.defaultProps = {
  noGutter: false,
};

Chat.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default MsgInformation;
