
const form = {
    formId: "new-product-form",
    formField: {
        name: {
            name: "name",
            label: "Name",
            type: "text",
            errorMsg: "Name is required.",
            invalidMsg: "Name must be at least 3 character",
        },
        description: {
            name: "description",
            label: "Description",
            type: "text",
            errorMsg: "Description is required.",
            invalidMsg: "Description must be at least 3 character",
        },
        active: {
            name: "active",
            label: "Active",
            type: "radio",
        },
        price: {
            name: "price",
            label: "Price",
            type: "number",
            errorMsg: "Price is required.",
        },
        interval: {
            name: "interval",
            label: "Interval",
            type: "radio",
        }
    },
};

export default form;
