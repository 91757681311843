import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import List from "./components/List";

function ActivityLog(){

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);
    
    return (
        <BaseLayout>
            <List />
        </BaseLayout>
    );
}

export default ActivityLog;