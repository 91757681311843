import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

export default function ConfirmDelete({ title, message, confirm, showDialog, closeButtonText, confirmButtonText }) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        confirm(false);
    };

    const handleConfirm = () => {
        confirm(true);
    };

    useEffect(() => {
        setOpen(showDialog);
    }, [showDialog]);

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <MDBox>
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ textAlign: "center" }}
                    >
                        <MDBox sx={{ display: "flex", alignItems: "center" }}>
                            <MDTypography variant="h5" fontWeight="medium">
                                {message}
                            </MDTypography>
                        </MDBox>
                    </DialogTitle>
                    <DialogActions
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <MDButton
                            color="primary"
                            variant="outlined"
                            onClick={handleClose}
                        >
                            {closeButtonText}
                        </MDButton>
                        <MDButton
                            color="primary"
                            variant="gradient"
                            onClick={handleConfirm}
                        >
                            {confirmButtonText}
                        </MDButton>
                    </DialogActions>
                </MDBox>
            </Dialog>
        </Fragment>
    );
}


// Setting default value for the props of ConfirmDelete
ConfirmDelete.defaultProps = {
    closeButtonText: "Cancel",
    confirmButtonText: "Confirm",
};

// Typechecking props for the ConfirmDelete
ConfirmDelete.propTypes = {
    closeButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    message: PropTypes.string.isRequired,
    confirm: PropTypes.func.isRequired,
    showDialog: PropTypes.bool.isRequired,
};
