/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { paginationConstants } from "constants/pagination"
import DataTable from "components/Tables/DataTable";
import DefaultCell from "components/Cell/DefaultCell";
import toast from "react-hot-toast";
import Form from "./Form"

function List() {
    const [productList, setProductList] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [newPreloaded, setNewPreloaded] = useState(false);
    const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
    const [pageSize, setPageSize] = useState(paginationConstants.perPage);
    const [editedProduct, setEditedProduct] = useState({});

    const deleteProduct = async (id) => {
        setRefresh(false);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/product/${id}`, {
                method: "DELETE",
                headers: {
                    "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            if (response.ok) {
                setRefresh(true);
                toast.success("Stripe Product Deleted Successfully");
            }
            else {
                const jsonData = await response.json();
                throw new Error(jsonData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    const editProduct = (product) => {
        setIsEditing(true);
        setEditedProduct(product);
    }

    const changeNewPreloaded = () => {
        setNewPreloaded(!newPreloaded);
        setEditedProduct({});
    }

    const changeIsEditing = () => {
        setIsEditing(!isEditing);
        setEditedProduct({});
    }

    const ButtonCell = ({ product }) => {

        return (
            <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <MDBox mr={2}>
                    <MDBox variant="text" sx={{ display: "flex", alignItems: "center" }} color="error" className="cursor-pointer" onClick={(e) => { e.preventDefault(); deleteProduct(product.id) }} >
                        <Icon>delete</Icon>&nbsp;
                    </MDBox>
                </MDBox>
                <MDBox variant="text" sx={{ display: "flex", alignItems: "center" }} color="info" className="cursor-pointer" onClick={(e) => { e.preventDefault(); editProduct(product) }}>
                    <Icon>edit</Icon>&nbsp;
                </MDBox>
            </MDBox>
        );
    }

    const loadData = (productData) => {
        if (productData && productData.length > 0) {
            let columns = [
                { Header: "id", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "name", accessor: "name", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "price", accessor: "price", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "action", accessor: "action", isSorted: "Hi", displaySorted: false, Cell: ({ value }) => <ButtonCell product={value} /> },
            ];

            let rows = productData.map((product) => {
                let name = product.name.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ');
                return {
                    id: product.id.toString(),
                    name: name,
                    price: product.price,
                    action: product
                }
            })

            setDataTable({ columns: columns, rows: rows });
        }
        else{
            setDataTable({ columns: [], rows: [] });
        }
    }

    const getProductList = async () => {

        let response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/product`
            , {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                    "Content-type": "application/json; charset=UTF-8"
                }
            });

        if (response.ok) {
            const jsonResponse = await response.json();
            setProductList(jsonResponse);
            loadData(jsonResponse);
        }

    }

    useEffect(() => {
        if (refresh) {
            getProductList();
        }
    }, [refresh]);

    return (
        <>
            {(!isEditing && !newPreloaded) && (<Card id="user-list-card">
                <MDBox p={2}>
                    <MDBox display="flex" alignItems="center" justifyContent="space-between">
                        <MDTypography variant="h6" fontWeight="medium">
                            Stripe Products
                        </MDTypography>
                        {(!isEditing || !newPreloaded) && (<MDBox>
                            <MDButton variant="heading" color="error" onClick={() => { setNewPreloaded(true) }}>
                                <Icon>add</Icon>&nbsp;Add New
                            </MDButton>
                        </MDBox>)}
                    </MDBox>
                    {(!isEditing && !newPreloaded) && (<MDBox my={2}>
                        <DataTable table={dataTable} entriesPerPage={pageSize} canSearch />
                    </MDBox>)}
                </MDBox>
            </Card>)}

            {newPreloaded && (
                <MDBox my={2}>
                    <Form onRefresh={(val) => setRefresh(val)}
                        editedProduct={null}
                        onClose={changeNewPreloaded}
                    />
                </MDBox>
            )}
            {isEditing && (
                <MDBox my={2}>
                    <Form
                        key={editedProduct?.id}
                        onRefresh={(val) => setRefresh(val)}
                        editedProduct={editedProduct}
                        onClose={changeIsEditing}
                    />
                </MDBox>
            )}

        </>
    );
}

export default List;
