/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import User from "layouts/pages/affiliate/myUsers/components/User";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";
import DataTable from "examples/Tables/DataTable";
import API_ENDPOINTS from "apiConfig";

function UsersView() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [bills,setBills] = useState([]);
  const [refresh,setRefresh] = useState(0);
  const [newPreloaded,setNewPreloaded]= useState(false);
  const [dataTable,setDataTable]=useState({columns: [],rows: []})
  const [editing,setEditing]=useState({});

  useEffect(() => {
    (async () => {
        try {
            setBills([]);
            const response = await fetch(API_ENDPOINTS.myReferedUser, {
              method: "GET",
              headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
              },
            });
      
            const resjson = await response.json();
            const data=resjson.users
            const columns = [];
            const keys = Object.keys(data[0]);
            keys.forEach(key => {
                if (key !== 'username' && key !== 'id' && key !== 'image') {
                    const newObj = {
                        Header: key,
                        accessor: key,
                        width: "55%"
                    };
                    columns.push(newObj);
                }
            });

            // columns.push({ Header: 'Delete', accessor: 'Delete', width: "55%" });
            // columns.push({ Header: 'Edit', accessor: 'Edit', width: "55%" });

            const rows = data.map(item => {
                item.Delete = <MDButton color="error" onClick={async () => { await handleDeleteCard(item.id) }}>Delete</MDButton>;
                item.Edit = <MDButton color="info" onClick={() => setEditing(item)}>Select</MDButton>;
                return item;
            });

            setDataTable({ columns: columns, rows: rows });
         
        } catch (error) {
            console.error('Error:', error);
        }
    })();
}, [refresh, newPreloaded, editing]);




  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="medium">
          My Refered Users
        </MDTypography>
       
      </MDBox>
 
      <MDBox py={1}>
          <DataTable
            table={dataTable}
            entriesPerPage={true}
            showTotalEntries={true}
            isSorted={true}
            noEndBorder
          />
        </MDBox>
    </Card>
  );
}

User.defaultProps = {
  noGutter: false,
};

User.propTypes = {
    data: PropTypes.object,
  noGutter: PropTypes.bool
};

export default UsersView;