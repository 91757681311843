import { useState, useEffect, useRef } from "react";
// react-router-dom components
import { Link, useSearchParams } from "react-router-dom";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

// @mui material components
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { Fade } from '@mui/material';
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Images
import bgImage from "assets/crmimages/bg-sign-in-cover.jpeg";
import verifyEmailAnimation from "../../assets/animation/verify-animation.json";
import loadingAnimation from "../../assets/animation/loading-animation.json";
import verifiedAnimation from "../../assets/animation/verified-animation.json";
import failedAnimation from "../../assets/animation/failed-animation.json";

// CSS
import "../../styles/success-fade-in.css";
import "../../styles/failed-fade-in.css";

import API_ENDPOINTS from "apiConfig";
import { Margin } from "@mui/icons-material";
const extensionURL = process.env.REACT_APP_EXTENSION_URL;

const VerifyEmail = () => {

  const navigate = useNavigate();
  const verifiedRefAnimation = useRef(null);
  const [isVerified, setIsVerified] = useState("");
  const [message, setMessage] = useState("");

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const verifyUserEmail = async () => {
    try {
      if (!token) {
        setIsVerified("not verified");
        setMessage("Token is Required!");
        return;
      }
      const res = await fetch(API_ENDPOINTS.verifyUserEmail, {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          token,
        }),
      });

      const resData = await res.json();
      setMessage(resData.message)
      if (res.ok) {
        setIsVerified("verified");
      }
      else {
        setIsVerified("not verified");
      }
    } catch (err) {
      console.error({ err });
    }
  };

  const handleMoveToExtension = () => {
    window.open(extensionURL, '_blank');
  };

  const handleMoveToLogin = () => {
    window.open('/login', '_blank');
  };

  useEffect(() => {
    setIsVerified("");
    setTimeout(() => {
      verifyUserEmail();
    }, 3000);
  }, [])

  return (
    <PageLayout>
      <MDBox
        width="100%"
        minHeight="100%"
        height="100vh"
        borderRadius="xl"
        sx={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {isVerified == "" && (<MDBox sx={{ width: "200px", height: "200px", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px', margin: '0px' }}>
          <Lottie animationData={loadingAnimation} loop={true} />
        </MDBox>)}
        {isVerified === "verified" && (
          <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <MDBox sx={{ width: "200px", height: "200px", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px', margin: '0px' }}>
              <Lottie lottieRef={verifiedRefAnimation} animationData={verifiedAnimation} loop={false} initialSegment={[0, 170]} />
            </MDBox>
            <div className="success-fade-in">
              <h4>{message}</h4>
              <MDBox mt={1}>
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <MDBox>
              <MDButton variant="outlined" color="info" size="small" onClick={handleMoveToExtension} >
                   Extension
              </MDButton>
            </MDBox>
            <MDBox ml={2}>
              <MDButton variant="gradient" color="info" size="small" onClick={handleMoveToLogin}>
                  CRM
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
            </div>
          </MDBox>
        )}
        {isVerified === "not verified" && (
          <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <MDBox sx={{ width: "200px", height: "170px", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px', margin: '0px' }}>
              <Lottie animationData={failedAnimation} loop={false} initialSegment={[0, 150]} />
            </MDBox>
            <div className="failed-fade-in">
              <h4>{message}</h4>
            </div>
          </MDBox>
        )}
      </MDBox>
    </PageLayout>
  );
}

export default VerifyEmail;
