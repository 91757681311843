/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useContext } from "react";
import "./booking.css";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Booking page components

// Booking layout schemas for form and form feilds
import validations from "layouts/pages/booking/schemas/validations";
import form from "layouts/pages/booking/schemas/form";
import initialValues from "layouts/pages/booking/schemas/initialValues";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/crmimages/bg-sign-in-cover.jpeg";
import ServiceAndProvider from "./components/ServiceAndProvider";
import DateTimePicker from "./components/DateTimePicker";
import Success from "./components/Success";
import AuthContext from "context/Authcontext";
import {
  Avatar,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Button } from "@mui/base";
import MDTimePicker from "components/MDTimePicker/MDTimePicker";
function getSteps() {
  return [
    "Service And Provider",
    "Appointment Date & Time",
    "Customer Information",
    "Appointment Confirmation",
  ];
}
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDDatePicker from "components/MDDatePicker";
import TimeSlotSelector from "./components/TimeSlotSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { addDays, formatDate, dateInHumanReadableFormat, formatTimeIn12hr, calculateMeetingTimes } from "lib/helper";
import formData from "./formData";
import UserInfo from "./components/UserInfo";
import HistoryIcon from "@mui/icons-material/History";
import EventIcon from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { timezones } from "./components/TimeZones";
import MDTypography from "components/MDTypography";
import { AppBar, Toolbar } from "@mui/material";
import Link from "@mui/material/Link";
import logo from "assets/crmimages/skoop-logo-white.svg";
import { position } from "stylis";
import toast from "react-hot-toast";

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    default:
      return null;
  }
}

function Booking() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [email, setEmail] = useState("");
  const [duration, setDuration] = useState("");
  const [slots, setSlots] = useState();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(formatDate(addDays(new Date(), 1)));
  const [user, setUser] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [screen, setScreen] = useState("dateTimeSelection");
  const [cname, setCName] = useState("");
  const [cemail, setCEmail] = useState("");
  const [mettingDetails, setMettingDetails] = useState("");
  const [errors, setErrors] = useState({});
  const [calendarLink, setCalendarLink] = useState();
  const [meetLink, setMeetLink] = useState();
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [userTimezone, setUserTimezone] = useState("");
  const [userProfileImage, setUserProfileImage] = useState();
  const [syncGoogleCalendar ,setSyncGoogleCalendar] = useState(true);
  const [syncMicrosoftCalendar ,setSyncMicrosoftCalendar] = useState(false);
  const [redableDate, setRedableDate] = useState(null);
  const [redableTime, setRedableTime] = useState(null);
  const { getUserPreferencesDetial, userPreferencesDetails, getProfileDetails, profileDetails, calendarSync } =
    useContext(AuthContext);

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Simple validation
    let tempErrors = {};
    if (!cname) tempErrors.name = "Name is required";
    if (!cemail) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email is invalid";
    }

    setErrors(tempErrors);

    if (!profileDetails?.calendar_info && !syncGoogleCalendar && !syncMicrosoftCalendar) {
      toast.error("Please select a calendar; otherwise, a meeting link will not be added to the appointment.");
      return;
    }

    if (Object.keys(tempErrors).length === 0) {
      try {
        const { startDateTime, endDateTime } = calculateMeetingTimes(
          selectedDate,
          selectedSlot,
          duration
        );

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/appointments`, {
          method: "POST",
          body: JSON.stringify({
            title: `Virtual meet with customer ${cname}`,
            email: email,
            description: `Virtual meet with customer ${cname} ${cemail}`,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            attendees: [cemail],
            timeZone: userTimezone,
            ...(mettingDetails.length > 0 ? {mettingDetails: mettingDetails}:{})
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const resjson = await response.json();
        if (response.ok) {
          setScreen("success");
          setCalendarLink(resjson.googleEventInfo?.addToCalendarLink);
          setMeetLink(resjson.googleEventInfo?.meetLink);
          toast.success("Appointment created successfully");

          if(!profileDetails?.calendar_info) {
            const fullPath = location.pathname + location.search;
            localStorage.setItem("pagePath", fullPath);
            if(syncGoogleCalendar) {
              localStorage.setItem("activeAction", "sync google calendar");
              localStorage.setItem("appointmentId", resjson.appointmentId);
              return calendarSync('google', true);
            }
  
            else if(syncMicrosoftCalendar) {
              localStorage.setItem("activeAction", "sync microsoft calendar");
              localStorage.setItem("appointmentId", resjson.appointmentId);
  
              return calendarSync('microsoft', true);
            }
          }
        }
        else {
          throw new Error(resjson.message);
        }

      } catch (err) {
        console.log(err);
        toast.error( err?.message || "something went wrong please try again");
      }
    } else {
      toast.error("Please fill all the required fields");
    }
  };
  const handleNameChange = (newName) => {
    setCName(newName);
  };
  const handleTimezoneChange = (event) => {
    setUserTimezone(event.target.value);
  };
  const handleEmailChange = (newEmail) => {
    setCEmail(newEmail);
  };

  const handleMettingDetailsChange = (newMettingDetails) => {
    setMettingDetails(newMettingDetails);
  }
  useEffect(() => {
    async function setup() {
      const searchParams = new URLSearchParams(location.search);
      const emailParam = decodeURI(searchParams.get("email") || "");
      const duration = decodeURI(searchParams.get("duration") || "");
      setEmail(emailParam);
      setDuration(duration);

      // Only proceed if emailParam is not empty
      if (emailParam) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/available-slots`, {
            method: "POST",
            body: JSON.stringify({
              email: emailParam,
              duration: searchParams.get("duration"),
              date: selectedDate,
              timezone:
                userTimezone == ""
                  ? Intl.DateTimeFormat().resolvedOptions().timeZone
                  : userTimezone,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          });

          if (response.ok) {
            const resjson = await response.json();
            setSlots(resjson?.availableSlots);
            setUser(resjson?.user);
            if(resjson?.user?.image?.startsWith("http")){
              setUserProfileImage(resjson?.user?.image);
            }
            else if (!resjson?.user?.image?.startsWith("publics")) {
              setUserProfileImage(process.env.REACT_APP_BACKEND_URL + "/" + resjson?.user?.image);
            }
          } else {
            const jsonResponse = await response.json();
            throw new Error(jsonResponse?.message);
          }
        } catch (err) {
          console.error("Network error:", err);
          toast.error(err?.message || "something went wrong please try again");
        }
      } else {
        console.error("Email parameter is missing");
        toast.error("Email is required");
      }
    }

    if(selectedDate && userTimezone) {
      setup();
    }
    getUserPreferencesDetial();
  }, [selectedDate, userTimezone]); // Removed email from dependency array

  useEffect(() => {
    if(selectedDate && selectedDate.length > 0) {
      let formatedDate = dateInHumanReadableFormat(selectedDate);
      setRedableDate(formatedDate);
    }

    if( selectedSlot && Object.keys(selectedSlot).length > 0) {
      let startTime = formatTimeIn12hr(selectedSlot?.updatedStart);
      let endTime = formatTimeIn12hr(selectedSlot?.updatedEnd);
      setRedableTime({startTime, endTime});
    }
  }, [selectedDate, selectedSlot]);

  useEffect(() => {}, [selectedSlot, screen]);

  // Handler function to be called when the date changes
  const handleDateChange = (date) => {
    setSelectedDate(formatDate(date));
    setIsDateSelected(true);
    setShowDateTimePicker(true);

    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("selectedDate", formatDate(date));

    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString(),
    });
  };
  const moveToCustomerInfoScreen = () => {
    setScreen("customerInfo");
  };

  const handleBackButtonClick = () => {
    if (screen === "customerInfo") {
      setScreen("dateTimeSelection");
    } else if (screen === "success") {
      setScreen("customerInfo");
    }
  };

  const handleBackToCalendar = () => {
    setShowDateTimePicker(!showDateTimePicker);
  };

  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(detectedTimezone);

    async function fetchProfileDetails() {
      if (!profileDetails || Object.keys(profileDetails).length == 0) {
         await getProfileDetails();
      }
    }
  
    fetchProfileDetails();
  }, []);

  useEffect(() => {

  
  }, []);

  return (
    <>
      <MDBox className="page-container">
        <AppBar position="static" color="info">
          <Toolbar sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Link href="https://skoopapp.com/" style={{ textDecoration: "none" }} sx={{ flex: 1 }}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <img src={logo} alt="logo" className="skoop-app-icon" />
              </IconButton>
            </Link>
            <MDTypography
              sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: { xs: 'right', sm: 'right', md: 'right', lg: 'center' } }}
              variant="h4"
              fontWeight="medium"
              color="white"
            >
              Instant Video for LinkedIn & More
            </MDTypography>
            <MDBox  sx={{ flex:{ xs: 0, sm: 0, md: 0, lg: 1 }, display: 'flex', justifyContent: 'flex-end' }}>

            </MDBox>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: 3,
            mt: { xs: 4, sm: 12, md: 12, lg: 0 },
            mb: { xs: 2, sm: 2, md: 8, lg: 0 },
            pl: { xs: "0", sm: "0", md: "0", lg: "5%" },
            pr: { xs: "0", sm: "0", md: "0", lg: "5%" },
          }}
        >
          <Paper
            elevation={3}
            sx={{
              height: "auto",
              maxWidth: screen === "success" ? 600 : isDateSelected ? { sm: 600, lg: 1000 } : 500,
              width: "100%",
            }}
          >
            {(screen === "customerInfo" || screen === "success") && (
              <IconButton
                onClick={handleBackButtonClick}
                sx={{ position: "static", top: 6, left: 4 }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}

            <Grid container sx={{ height: "100%" }}>
              {screen !== "success" && (
                <Grid
                  p={3}
                  item
                  xs={12}
                  md={12}
                  lg={isDateSelected || screen === "dateTimeSelection" ? 4 : 6}
                  height="auto"
                  display="flex"
                  sx={{
                    position: "relative",
                    borderBottom: { xs: screen === "dateTimeSelection" ? 1 : 0, lg: 0 },
                    borderRight: { lg: screen === "dateTimeSelection" ? 1 : 0 },
                    borderColor: "divider",
                  }}
                >
                  <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                    {screen === "dateTimeSelection" && isDateSelected && showDateTimePicker && (
                      <IconButton
                        onClick={handleBackToCalendar}
                        sx={{
                          position: "absolute",
                          top: 12,
                          left: 4,
                          display: { sm: showDateTimePicker ? "block" : "none", md: "none" },
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    )}

                    <Avatar sx={{ width: 90, height: 90, mb: 3 }} src={userProfileImage} />
                    <Typography variant="h4" gutterBottom sx={{ mb: 1 }}>
                      Chat with {user?.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: "medium", mb: 1 }}
                      gutterBottom
                    >
                      Time Zone - {userPreferencesDetails?.time_zone || user?.time_zone}
                    </Typography>
                    <MDBox sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: { lg: "center", xl: "flex-start" },
                          mb: 1,
                        }}>
                        <HistoryIcon fontSize="medium" className="mr-5" />
                      <Typography
                        variant="body2"
                        color="textprimary"
                      >
                         {duration} minutes
                      </Typography>
                    </MDBox>
                    {(screen === "customerInfo" || screen === "success") && (
                      <>
                        { redableDate &&(<MDBox mb={1}>
                          <Typography
                            variant="body2"
                            color="textprimary"
                          >
                            <strong>Date: </strong>{redableDate}
                          </Typography>
                        </MDBox>)}

                        { redableTime && (<MDBox mb={2}>
                          <Typography
                            variant="body2"
                            color="textprimary"
                          >
                            <strong>Time: </strong>{redableTime?.startTime } - {redableTime?.endTime}
                          </Typography>
                        </MDBox>)}
                      </>
                    )}

                    {userPreferencesDetails?.additional_details && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "flex-start",
                          overflowY: "auto",
                        }}
                      >
                        <Typography sx={{ display: "inline" }} variant="body2">
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "medium", display: "inline" }}
                          >
                            Description -{" "}
                          </Typography>
                          {userPreferencesDetails?.additional_details}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
              {screen === "dateTimeSelection" && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={isDateSelected ? 8 : 6}
                  p={3}
                  position="relative"
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={isDateSelected ? 6 : 12}
                      lg={isDateSelected ? 6 : 12}
                      sx={{
                        position: "sticky",
                        top: 0,
                        display: {
                          xs: !showDateTimePicker ? "flex" : "none",
                          sm: !showDateTimePicker ? "flex" : "none",
                          md: "flex",
                          lg: "flex",
                          xl: "flex",
                        },
                        flexDirection: "column",
                        alignItems: { xs: "center", sm: "center", md: "flex-start" },
                      }}
                    >
                      <Box
                        mb={1}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: { xs: "center", sm: "center", md: "start" },
                        }}
                      >
                        <Typography variant="h6">Select a Date & Time</Typography>
                      </Box>
                      <MDDatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        alwaysOpen={true}
                        options={{ minDate: formatDate(addDays(new Date(), 1)) }}
                      />
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: { xs: "none", md: "flex", lg: "flex", xl: "flex" },
                          flexDirection: "column",
                          alignItems: { xs: "start", sm: "start" },
                        }}
                      >
                        <FormControl variant="standard" fullWidth sx={{ mt: 5 }}>
                          <InputLabel id="timezone-label">Select Timezone</InputLabel>
                          <Select
                            labelId="timezone-label"
                            id="timezone"
                            name="timezone"
                            value={userTimezone}
                            label="Select Timezone"
                            onChange={handleTimezoneChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: "200px",
                                },
                              },
                            }}
                          >
                            {timezones.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {isDateSelected && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                          display: {
                            xs: showDateTimePicker ? "flex" : "none",
                            sm: showDateTimePicker ? "flex" : "none",
                            md: "flex",
                            lg: "flex",
                            xl: "flex",
                          },
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box
                          mb={2}
                          sx={{ display: { xs: "block", md: "none", lg: "none", xl: "none" } }}
                        >
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="timezone-label">Select Timezone</InputLabel>
                            <Select
                              labelId="timezone-label"
                              id="timezone"
                              name="timezone"
                              value={userTimezone}
                              label="Select Timezone"
                              onChange={handleTimezoneChange}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: "200px",
                                    zIndex: 999,
                                  },
                                },
                              }}
                            >
                              {timezones.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <Typography
                          variant="h6"
                          color="textprimary"
                          sx={{
                            ml: { xs: 0, md: 4, lg: 4 },
                            display: "flex",
                            alignItems: "center",
                            mb: 1,
                          }}
                        >
                          <EventIcon fontSize="medium" sx={{ marginRight: "4px" }} /> {selectedDate}
                        </Typography>
                        <TimeSlotSelector
                          slots={slots}
                          onChange={setSelectedSlot}
                          nextPage={moveToCustomerInfoScreen}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {screen === "customerInfo" && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <UserInfo
                    onNameChange={handleNameChange}
                    onEmailChange={handleEmailChange}
                    onMettingDetailsChange={handleMettingDetailsChange}
                    onSubmit={handleSubmit}
                    syncGoogleCalendar={syncGoogleCalendar} 
                    setSyncGoogleCalendar={setSyncGoogleCalendar}
                    syncMicrosoftCalendar={syncMicrosoftCalendar}
                    setSyncMicrosoftCalendar={setSyncMicrosoftCalendar}
                  />
                </Grid>
              )}
              {screen === "success" && (
                <Grid item p={3}>
                  <Success
                    data={{ calendarLink, meetLink }}
                    appointmentDate={redableDate}
                    appointmentTime={redableTime}
                    serviceProvider={user?.name}
                    timeZone={userTimezone}
                    onBackButtonClick={handleBackButtonClick}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
        <Footer />
      </MDBox>
    </>
  );
}

export default Booking;
