
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ light ,fixed}) {
  const { size } = typography;

  return (
    <MDBox width="100%" bottom={0} py={1} id={fixed?"footer":""} className="footer">
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="center"
          alignItems="center"
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "white" : "text"}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()}, made with
            <MDBox fontSize={size.md}  mb={-0.5} mx={0.25}>
              <Icon color="inherit" fontSize="inherit">
                favorite
              </Icon>
            </MDBox>
            by
            <Link href="https://thetroyagency.com/" target="_blank">
              <MDTypography cursor="pointer" variant="button" fontWeight="medium" >
                &nbsp;The Troy Agency&nbsp;
              </MDTypography>
            </Link>
            for a better web. &nbsp; ------------------------------------------ &nbsp; &nbsp;
            <Link href="/privacypolicy">
              <MDTypography cursor="pointer" variant="button" fontWeight="medium" >
                Privacy Policy
              </MDTypography>
            </Link>
            &nbsp; | &nbsp;
            <Link href="/termsofuse">
              <MDTypography cursor="pointer" variant="button" fontWeight="medium" >
                Terms of Use
              </MDTypography>
            </Link>
            &nbsp; | &nbsp;
            <Link href="/affiliatepolicies">
              <MDTypography cursor="pointer" variant="button" fontWeight="medium" >
                Affiliate Policies
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
