/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import React, { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { paginationConstants } from "constants/pagination"
import DataTable from "components/Tables/DataTable";
import DefaultCell from "components/Cell/DefaultCell";
import CustomerCell from "components/Cell/CustomerCell";
import toast from "react-hot-toast";
import field from "./schemas/form";
import {capitalizeFirstLetter, formatISODate} from "lib/helper";

// component
import NewSubscription from "./NewSubscription";
import AuthContext from "context/Authcontext";

function List() {
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
    const [pageSize, setPageSize] = useState(paginationConstants.perPage);
    const [editedSubscription, setEditedSubscription] = useState({});
const {deleteSubscriptions}=useContext(AuthContext)
    const {
        formField: { status },
    } = field;

    const editSubscription = (subscription) => {
        setIsEditing(true);
        setEditedSubscription(subscription);
    }
    const deleteSubscription =async (subscription) => {
       
       await deleteSubscriptions(subscription.id)
       setRefresh(!refresh)
    }
    const changeIsEditing = () => {
        setIsEditing(!isEditing);
        setEditedSubscription({});
    }

    const ButtonCell = ({ subscription }) => {

        return (
            <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                 <MDBox mr={2}>
                 <MDBox variant="text" sx={{display: "flex", alignItems: "center"}} color="error" className="cursor-pointer" onClick={(e) => {e.preventDefault(); deleteSubscription(subscription)}} >
                    <CloseIcon/>&nbsp;
                 </MDBox>
                 </MDBox>
                <MDBox variant="text" sx={{ display: "flex", alignItems: "center" }} color="info" className="cursor-pointer" onClick={(e) => { e.preventDefault(); editSubscription(subscription) }}>
                    <Icon>edit</Icon>&nbsp;
                </MDBox>
            </MDBox>
        );
    }

    const loadData = (subscriptionData) => {
        if (subscriptionData && subscriptionData.length > 0) {
            let columns = [
                { Header: "id", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "Name", accessor: "name", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "Plan Type", accessor: "plan_type", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "Status", accessor: "status", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "Start Date", accessor: "start_date", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "action", accessor: "action", isSorted: "Hi", displaySorted: false, Cell: ({ value }) => <ButtonCell subscription={value} /> },
            ];

            let rows = subscriptionData.map((subscription) => {
                let name = capitalizeFirstLetter(subscription?.full_name);
                let formatedDate = formatISODate(subscription?.start_date);
                let statusObj = status.options.find((option) => option.value === subscription.status);
                return {
                    id: subscription?.id.toString(),
                    name: name,
                    plan_type: subscription.plan_type, 
                    status: statusObj?.label,
                    start_date: formatedDate,
                    action: subscription
                }
            })

            setDataTable({ columns: columns, rows: rows });
        }
        else{
            setDataTable({ columns: [], rows: [] });
        }
    }

    const getList = async () => {

        let response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/subscription/list`
            , {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                    "Content-type": "application/json; charset=UTF-8"
                }
            });

        if (response.ok) {
            const jsonResponse = await response.json();
            setSubscriptionList(jsonResponse);
            loadData(jsonResponse);
        }

    }

    useEffect(() => {
        if (refresh) {
            getList();
        }
    }, [refresh]);

    return (
        <>

            {!isEditing && (<Card id="subscription-list-card">
                <MDBox p={2}>
                    <MDBox display="flex" alignItems="center" justifyContent="space-between">
                        <MDTypography variant="h6" fontWeight="medium">
                            User Subscription
                        </MDTypography>
                    </MDBox>
                    {!isEditing && (<MDBox my={2}>
                        <DataTable table={dataTable} entriesPerPage={pageSize} canSearch />
                    </MDBox>)}
                </MDBox>
            </Card>)}
            {isEditing && (
                <MDBox my={2}>
                    <NewSubscription
                        key={editedSubscription?.id}
                        onRefresh={(val) => setRefresh(val)}
                        editedSubscription={editedSubscription}
                        onClose={changeIsEditing}
                    />
                    
                </MDBox>
            )}

        </>
    );
}

export default List;
