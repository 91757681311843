import * as React from "react";
import { Box, Typography, Container, Link } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { questions } from "constants/privacy";
const PrivacyPolicies = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container maxWidth="md">
      <Box m={7}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome! This Privacy Policy (this "Policy") explains how we at The Troy Agency.,
          operating as SKOOP, collect, use, disclose and otherwise process personal information. We
          understand that you are aware of and care about your own personal privacy interests, and
          we take that seriously. This Policy describes the personal information we may collect from
          you, the purposes for which we collect it, how we use it and how we keep it secure. This
          Policy also describes the choices you can make about your personal information, including
          how you can manage, delete or have access to your personal information. Skoop will not
          collect, use or disclose your personal information other than in compliance with this
          Policy.
        </Typography>
        <Typography variant="body1" component="h1" gutterBottom>
          Founder : The Troy Agency
          <br />
        </Typography>
        <Typography variant="body2" paragraph>
          10111 Eden Mountain St Las Vegas NV 89141
          <br />
          <Link href="mailto:troy@thetroyagency.com">troy@thetroyagency.com</Link>
        </Typography>
        <Typography variant="body3" paragraph>
          Click on any of the links below to read a summary for that topic. 
        </Typography>
        {questions.map((item) => (
          <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordion-summary-content">
              <Typography variant="h5">
                {" "}
                {item.id}. {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph>
                {item.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="body2" paragraph>
          Last Updated: 03/27/2024
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicies;
