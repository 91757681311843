const backendUrl = process.env.REACT_APP_BACKEND_URL;

const API_ENDPOINTS = {
  backendUrl,
  tokenStatus: `${backendUrl}/tokenStatus`, // GET
  profileDetails: `${backendUrl}/userDetails`, // GET
  updateUserDetails: `${backendUrl}/updateUserDetails`, // PUT
  updateProfileImage: `${backendUrl}/updateProfileImage`, // POST
  changePassword: `${backendUrl}/changePassword`, // PATCH
  getOtpForPasswordReset: `${backendUrl}/getOtpForPasswordReset`, //GET
  resetPasswordUsingOtp: `${backendUrl}/resetPasswordUsingOtp`, //GET
  userDevices: `${backendUrl}/userdevices`, // GET
  deviceRemove: `${backendUrl}/userdevices`, // DELETE
  getUserAppointmentPreferences: `${backendUrl}/user-appointment-preferences`, // GET
  createAffiliateUser: `${backendUrl}/affiliate/register`, // Post
  myaffiliateCode: `${backendUrl}/affiliate/myaffiliateCode`, //GET
  myReferedUser:`${backendUrl}/affiliate/myReferedUser`, //GET
  updatePayoutInfo: `${backendUrl}/affiliate/updatePayoutInfo`, // Patch
  getUserList: `${backendUrl}/users`, // GET
  addUser: `${backendUrl}/user`, // POST
  getRoles: `${backendUrl}/roles`, // GET
  affiliatePaidPayments: `${backendUrl}/affiliate/payments/paid`, //GET
  affiliateDuePayments: `${backendUrl}/affiliate/payments/due`, //GET
  verifyUserEmail: `${backendUrl}/user/verify-email`, //GET
  logout: `${backendUrl}/logout`, // delete
  deleteAllJwtSessions:`${backendUrl}/jwtsession/all`, 
  linkedInLogInDeleteSession: `${backendUrl}/linkedInLogInDeleteSession`, //POST
  GoogleLogInDeleteSession: `${backendUrl}/SignInWithGoogleDeleteSession`, //POST
  jwtsocialsessions: `${backendUrl}/jwtsession/jwtsocialsessions`,
  getSubscriptions:`${backendUrl}/subscription`, //Get ,
  becomeAnAffiliate:`${backendUrl}/affiliate/becomeAffiliate`,
  generateAppSumoCoupons:`${backendUrl}/appsumo/generate-coupons`,
  linkedInLogIn: `${backendUrl}/linkedInLogIn`, //POST
  googleLogIn: `${backendUrl}/SignInWithGoogle`, //POST
  createEvent: `${backendUrl}/createEvent`, //POST
  googleAuthSession: `${backendUrl}/googleAuthSession`, //POST
  redeemCoupon: `${backendUrl}/appsumo/redeem-coupon`, //POST
  getAppSumoCoupons: `${backendUrl}/appsumo/read-coupons`, //GET
  signUp: `${backendUrl}/signup`, 
  mySubscriptions: `${backendUrl}/subscription`, // GET
  validateCoupon: `${backendUrl}/validate-coupon`,//GET
  createSubscription: `${backendUrl}/subscription`, // POST
  getProducts: `${backendUrl}/product`, //GET,
  refreshOnbordingUrl:`${backendUrl}/affiliate/refresh-onboarding-url`,
  coupon:`${backendUrl}/coupons`,
  syncCalendar: `${backendUrl}/sync-calendar`, // POST
  activityLog: `${backendUrl}/activity-log`, // GET
  linkData: `${backendUrl}/linkdata?`, // GET
  videoDirectories: `${backendUrl}/videoDirectories`, // GET
  addNewDirectory: `${backendUrl}/addNewDirectory`, // POST
  renameVideo: `${backendUrl}/renameVideo`, // PATCH
  deleteVideo: `${backendUrl}/deleteVideo/`, // DELETE
  moveVideos: `${backendUrl}/moveVideos`, // PATCH
  toggleFavourite: `${backendUrl}/toggleFavourite`, // PATCH
  getVideoDownloadLink: `${backendUrl}/getdownloadLink`, // GET
  getSupport: `${backendUrl}/support`, // GET
  updateSupport: `${backendUrl}/support`, // GET
  getSupportBySupportId: `${backendUrl}/support/`,
  getSupportMessages: `${backendUrl}/support/`,
  getUserById: `${backendUrl}/users/`,
  createSupportMessage: `${backendUrl}/support/`,
  updateSeenStatus: `${backendUrl}/support/messages/seen`,
  getAppConfig: `${backendUrl}/app-config`, // GET
  updateAppConfig: `${backendUrl}/app-config`, // PUT
  recieveVerificationMail: `${backendUrl}/user/send-verify-email`,
  getAllCoupons: `${backendUrl}/coupons`, // GET
  getCouponById: `${backendUrl}/coupons/`,
  getUsersByCouponId: `${backendUrl}/coupons/`,
  getLicenceDetails: `${backendUrl}/api/licence-count`, // GET
  getLinkDataByAccessId: `${backendUrl}/linkDataAccess/`,
  updateLinkDataByAccessId: `${backendUrl}/linkDataAccess/`,
  getMyUserSettings: `${backendUrl}/user-settings/mysettings`,
  getAppsumoLicenceDetails: `${backendUrl}/license-key`,
};

export default API_ENDPOINTS;
