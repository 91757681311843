import React, { useState, Fragment, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import Divider from "@mui/material/Divider";
import toast from "react-hot-toast";
import { position } from "stylis";

export default function ShowImageModal({
  open,
  setOpen,
  image
}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Dialog
        // className="dialog-box"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: { xs: "100%", sm: "80%", md: "70%", lg: "40%", xl: "30%" },
            height: "auto",
            py: 0,
            my: 0,
          },
        }}
      >
        <MDBox sx={{position: "relative", padding:"12px 12px 3px 12px"}}>
        <MDBox className="remove-img-button" >
            <CloseIcon onClick={handleClose} color="dark" fontSize="large" />
        </MDBox>
        <img src={image} className="message-image-dialog" />
        </MDBox>
      </Dialog>
    </Fragment>
  );
}
