import { Grid } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import React, { useContext, useState } from "react";
import ToggleContainerButton from "../ToggleContainerButton";
import MDButton from "components/MDButton";
import AuthContext from "context/Authcontext";
import toast from "react-hot-toast";

const ResetPasswordUsingOTP = ({ onToggle, isToggle }) => {
  const { sendOTP, resetPassword } = useContext(AuthContext);

  const [isSendOTPTimer, setIsSendOTPTimer] = useState(false);
  const [timer, setTimer] = useState();
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const startSendOTPTimer = () => {
    setIsSendOTPTimer(true);
    let seconds = 30;
    const interval = setInterval(function () {
      seconds--;
      setTimer(seconds);
      if (seconds == 0) {
        clearInterval(interval);
        setIsSendOTPTimer(false);
      }
    }, 1000);
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    const isOTPSent = await sendOTP(emailValue);
    if (isOTPSent) {
      setTimer(30);
      startSendOTPTimer();
      setIsOTPSent(true);
    }
  };

  const [onChangeValues, setOnChangeValues] = useState({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleOnChangeValue = (event) => {
    const { name, value } = event.target;
    setOnChangeValues({ ...onChangeValues, [name]: value });
  };

  const onResetPassword = async (event) => {
    event.preventDefault();
    if (onChangeValues.newPassword !== onChangeValues.confirmPassword) {
      toast.error("Password does not match!");
      return;
    } else {
      const isPasswordChanged = await resetPassword(
        emailValue,
        onChangeValues.otp,
        onChangeValues.newPassword
      );

      if (isPasswordChanged) {
        setOnChangeValues({
          otp: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
  };

  return (
    <MDBox>
      <Grid>
        {isOTPSent ? (
          <MDBox component="form" mb={3} onSubmit={handleSendOTP}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  label="Enter Email"
                  inputProps={{ type: "email", autoComplete: "" }}
                  name="email"
                  id="email"
                  value={emailValue}
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="end" mt={3}>
                <ToggleContainerButton onToggle={onToggle} isToggle={isToggle} />
                <MDBox>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                    disabled={isSendOTPTimer ? true : false}
                  >
                    {isSendOTPTimer ? `Send OTP in 00:${timer}` : "Send OTP"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          <MDBox component="form" onSubmit={onResetPassword}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={(e) => setIsOTPSent(false)}
                  >
                    Back
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  label="Enter OTP"
                  inputProps={{ type: "text" }}
                  name="otp"
                  id="otp"
                  value={onChangeValues.otp}
                  onChange={handleOnChangeValue}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="newPassword" required>
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    name="newPassword"
                    id="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    value={onChangeValues.newPassword}
                    onChange={handleOnChangeValue}
                    required={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="confirmPassword" required>
                    Confirm New Password
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    name="confirmPassword"
                    id="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    value={onChangeValues.confirmPassword}
                    onChange={handleOnChangeValue}
                    required={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm New Password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="end" mt={3}>
                <ToggleContainerButton onToggle={onToggle} isToggle={isToggle} />
                <MDBox>
                  <MDButton variant="gradient" color="dark" size="small" type="submit">
                    Reset password
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </Grid>
    </MDBox>
  );
};

export default ResetPasswordUsingOTP;
