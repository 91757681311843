import * as Yup from "yup";
import field from "./form";

const {
  formField: {
    name,
    description,
    duration,
    code,
    discount_type,
    percent_off,
    amount_off,
    duration_in_months,
    max_redemptions,
    redeem_by,
    lifetime_access_coupon,
  },
} = field;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().trim().min(3, name.invalidMsg).required(name.errorMsg).nullable(),
    [code.name]: Yup.string().trim().min(6, code.invalidMsg).required(code.errorMsg).nullable(),
    [description.name]: Yup.string().nullable(),
    [lifetime_access_coupon.name]: Yup.boolean(),
    [duration.name]: Yup.string()
      .nullable()
      .when(lifetime_access_coupon.name, {
        is: true,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required(duration.errorMsg),
      }),
    [discount_type.name]: Yup.string()
      .nullable()
      .when(lifetime_access_coupon.name, {
        is: false,
        then: Yup.string().required(discount_type.errorMsg),
      }),
    [percent_off.name]: Yup.number()
      .nullable()
      .when([lifetime_access_coupon.name, discount_type.name], {
        is: (lifetimeAccess, discountType) => !lifetimeAccess && discountType === "percent_off",
        then: Yup.number()
          .min(0, percent_off.invalidMsg)
          .max(100, percent_off.invalidMsg)
          .required(percent_off.errorMsg),
      }),
    [amount_off.name]: Yup.number()
      .nullable()
      .when([lifetime_access_coupon.name, discount_type.name], {
        is: (lifetimeAccess, discountType) => !lifetimeAccess && discountType === "amount_off",
        then: Yup.number().min(0, amount_off.invalidMsg).required(amount_off.errorMsg),
      }),
    [duration_in_months.name]: Yup.number()
      .nullable()
      .when([lifetime_access_coupon.name, duration.name], {
        is: (lifetimeAccess, durationValue) => !lifetimeAccess && durationValue === "repeating",
        then: Yup.number()
          .min(1, duration_in_months.invalidMsg)
          .required(duration_in_months.errorMsg),
      }),
    [max_redemptions.name]: Yup.number()
      .min(0, max_redemptions.invalidMsg)
      .max(100, max_redemptions.invalidMsg)
      .nullable(),
    [redeem_by.name]: Yup.string()
      .nullable()
      .when(lifetime_access_coupon.name, {
        is: false,
        then: Yup.string().required(redeem_by.errorMsg),
      }),
  }),

  Yup.object().shape({
    [name.name]: Yup.string().trim().min(3, name.invalidMsg).required(name.errorMsg).nullable(),
    [duration.name]: Yup.string().required(duration.errorMsg).nullable(),
    [code.name]: Yup.string().trim().min(3, code.invalidMsg).required(code.errorMsg).nullable(),
  }),
];

export default validations;
