import * as Yup from "yup";
import field from "./form";

const {
    formField: { status },
} = field;

const validations = [
    Yup.object().shape({
        [status.name]: Yup.string().required(status.errorMsg).nullable(),
    })
];

export default validations;
