import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import AuthContext from "context/Authcontext";
import Divider from "@mui/material/Divider";
import MDInput from "components/MDInput";
import toast from "react-hot-toast";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import Link from "@mui/material/Link";
import RemoveSession from "components/RemoveSession";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import skoopCrmBanner from "assets/crmimages/bg-sign-in-cover.jpeg";
import StripeSecure from "components/StripeSecure";
import Confirmation from "../ConfirmDelete";
import { FcGoogle } from "react-icons/fc";
import { FaLinkedin } from "react-icons/fa";
import { useSearchParams, useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../../apiConfig";



function UserRegistration({
  handleRegistration,
  cardTitle,
  submitButtonTitle,
  googleButtonTitle,
  linkedInButtonTitle,
}) {
  const [searchParams,setSearchParams]=useSearchParams();
  const [subscription, setSubscription]= useState(searchParams.get('subscription')); 
  const appsumoCode = searchParams.get('code');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {
    handleSocialLogin,
    showClearSessionDialog,
    setShowClearSessionDialog,
    setSocial,
    social,
    deleteMyAllJwtSessionsBySocial,
    setPageType,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const handleGoogleRegistration = () => {
    handleSocialLogin(2);
    setSocial(2);
    setPageType("register");
    localStorage.setItem("social", 2);
    localStorage.setItem("pageType", "register");
    localStorage.setItem("subscriptionType", subscription);
  };

  const hasSpaceInText = (text) => {
    return /\b\w+\s+\w+\b/.test(text);
  }

  const handleLinkedInRegistration = () => {
    handleSocialLogin(1);
    setSocial(1);
    setPageType("register");
    localStorage.setItem("social", 1);
    localStorage.setItem("pageType", "register");
    localStorage.setItem("subscriptionType", subscription);
  };

  const handleRegister = async () => {
    // await checkForAppsumoLicence();
    let missingFields = [];
    if (!firstName) missingFields.push("first name");
    if (!lastName) missingFields.push("last name");
    if (!email) missingFields.push("email");
    if (!password) missingFields.push("password");

    if (missingFields.length > 0) {
      const missingFieldsFormatted = missingFields.join(", ");
      toast.error(`Please fill in the following field: ${missingFieldsFormatted}.`);
      return;
    } 
    else if(hasSpaceInText(firstName) ) {
      toast.error(`First name must not contain spaces`);
      return;
    }

    handleRegistration(firstName, lastName, email, password,subscription);
  };

  const handleConfirmation = (confirm) => {
    if (confirm) {
      deleteMyAllJwtSessionsBySocial(social);
    }
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    ;(async () => {

    try {
      if (appsumoCode) {
      
      // const data =   {
      //   client_id: process.env.REACT_APP_APPSUMO_CLIENT_ID,
      //   client_secret: process.env.REACT_APP_APPSUMO_CLIENT_SECRET,
      //   code: appsumoCode,
      //   redirect_uri: process.env.REACT_APP_APPSUMO_REDIRECT_URI,
      //   grant_type: 'authorization_code', 
      // }
      
      // const response = await fetch(`${process.env.REACT_APP_APPSUMO_ACCESS_CODE_URL}`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-type': 'application/json',
      //   },
      //   body: JSON.stringify(data)
      // });

      // if(!response.ok) {
      //   throw new Error('failed to fetch access token');
      // }

      // const tokenData = await response.json();
      // const accessToken = tokenData["access_token"];

      // if(!accessToken) {
      //   throw new Error('invalid access token')
      // }

      // const licenseResponse = await fetch(`${process.env.REACT_APP_APPSUMO_LICENSE_URL}/?access_token=${accessToken}`, {
      //   method: 'GET',
      // });

      // if (!response.ok) {
      //   throw new Error('failed to fetch license')
      // }
      // const licenseData = await licenseResponse.json();

      // console.log('license data value ', licenseData);

      // if (licenseData['status'].toLowerCase() !== "active") {
      //   throw new Error('license is not active');
      // }

      const licenseData = {
        appsumoCode: appsumoCode,
        appsumoUser: true
      }

      const backendLicenceResponse = await fetch(API_ENDPOINTS.getAppsumoLicenceDetails, {
        method: 'POST',
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(licenseData),
      });


      if(!backendLicenceResponse.ok) {
        if (backendLicenceResponse.status === 404) {
          throw new Error('no license details found in backend');
        } else if(backendLicenceResponse.status == 400) {
          const result = await backendLicenceResponse.json();
          throw new Error(`${result?.message}`);
        } else { 
          throw new Error('failed to fetch license details from backend ');
        }
      }

      const result = await backendLicenceResponse.json();

      const licenceDetails = result?.data;


      if (licenceDetails?.user_id !== null) {
        navigate('/authentication/sign-in')
      }

      if (licenceDetails && licenceDetails?.appsumoStatus && licenceDetails.appsumoStatus.toLowerCase() == 'active') {
        //subscription = 'appsumoLicence';
        setSubscription('appsumoLicence');
        localStorage.setItem('license_key', licenceDetails.license_key);
      }
      
    }
    } catch(error) {
      console.log('error while fetching appsumo license details ', error);
   
    }
  })()
    
  }, []);
  

  return (
    <>
      <Confirmation
        title="Delete Sessions?"
        message="Are you sure you want to delete all sessions?"
        confirm={handleConfirmation}
        showDialog={showConfirmationModal}
      />
      <CoverLayout image={skoopCrmBanner} buttonText={"Sign In"}>
        <Card>
          <MDBox sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}} px={3}>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={1}
              mb={2}
              textAlign="center"
              sx={{ width: { xs: "80%", md: "70%", lg: "60%" } }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white">
                {cardTitle} 
              </MDTypography>
            </MDBox>
          </MDBox>
          {showClearSessionDialog && (
            <MDBox px={3} mt={-1} mb={1}>
              <RemoveSession onDelete={() => setShowConfirmationModal(true)} />
            </MDBox>
          )}

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            py={1}
            mb={1}
            px={3}
          >
            <Grid item xs={12}>
              <MDButton
                fullWidth
                variant="outlined"
                color="black"
                onClick={handleGoogleRegistration}
                size="medium"
                sx={{ fontSize: "24px", padding: "15px 13px" ,fontWeight:500, textTransform: "none"}}
              >
                <FcGoogle style={{ transform: "scale(1.5)", marginRight: "18px" }} size={24} />
                {googleButtonTitle}
              </MDButton>
            </Grid>
            <Grid item xs={12}>
              <MDButton
                fullWidth
                variant="outlined"
                color="black"
                size="medium"
                onClick={handleLinkedInRegistration}
                sx={{ fontSize: "24px", padding: "15px 13px" ,fontWeight:500, textTransform: "none"}}
              >
                <FaLinkedin
                  style={{ transform: "scale(1.5)", marginRight: "9px" }}
                  size={24}
                  className="linkedin-icon"
                />
                {linkedInButtonTitle}
              </MDButton>
            </Grid>
          </Grid>
          <Divider />
          <MDTypography variant="h5" gutterBottom align="center">
            OR
          </MDTypography>
          <MDBox pb={1} px={3} sx={{ display: "flex", justifyContent: "center" }}>
            <MDBox
              component="form"
              role="form"
              sx={{ width: { xs: "80%", md: "70%", lg: "50%" } }}
            >
              <MDBox mb={1}>
                <MDInput
                  type="text"
                  label="First name"
                  variant="standard"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDInput
                  type="text"
                  label="Last name"
                  variant="standard"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
              <MDBox mt={2} >
                <MDButton variant="outlined" color="black" fullWidth onClick={handleRegister} sx={{ textTransform: "none"}}>
                  {submitButtonTitle}
                </MDButton>
              </MDBox>
              <MDBox mt={0} mb={3} textAlign="center">
                <Link href="/authentication/reset-password">
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                    Reset Password
                    </MDTypography>
                </Link>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <StripeSecure />
      </CoverLayout>
    </>
  );
}

export default UserRegistration;
