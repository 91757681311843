import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import MDButton from "components/MDButton";
import { Link } from "@mui/material";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import AuthContext from "context/Authcontext";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
function RefreshOnboardingUrl() {
  const { refreshAffiliateOnboardingUrl } = useContext(AuthContext);
  useEffect(() => {
    const url = new URL(window.location.href);


    const accountId = url.searchParams.get("accountId");

    if (accountId) {
     refreshAffiliateOnboardingUrl(accountId)
    }
  }, []);

  return (
    <MDBox
      display="flex" // Use Flexbox
      flexDirection="column" // Stack children vertically
      justifyContent="center" // Center children vertically in the container
      alignItems="center"
    >
      <CircularProgress />
      <Footer />
    </MDBox>
  );
}

export default RefreshOnboardingUrl;
