
const form = {
    formId: "new-user-form",
    formField: {

        firstName: {
            name: "firstName",
            label: "First Name",
            type: "text",
            errorMsg: "First Name is required.",
            invalidMsg: "First Name must be at least 1 character",
        },
        lastName: {
            name: "lastName",
            label: "Last Name",
            type: "text",
            errorMsg: "Last Name is required.",
            invalidMsg: "Last Name must be at least 1 character",
        },
        email: {
            name: "email",
            label: "Email",
            type: "email",
            errorMsg: "Email is required.",
            invalidMsg: "Your Email address is invalid",
        },
        role: {
            name: "role",
            label: "Role",
            type: "text",
            errorMsg: "Role is required.",
        },
        gender: {
            name: "gender",
            label: "gender",
            type: "text",
            option: ["male", "female", "other"],
            errorMsg: "Gender is required.",
        },
        phone: {
            name: "phone",
            label: "phone",
            type: "number",
            errorMsg: "Phone is required.",
            invalidMsg: "Phone is not valid",
        },
        subscription: {
            name: "subscription",
            label: "Subscription",
            errorMsg: "Subscription is required.",
        },
        password: {
            name: "password",
            label: "Password",
            type: "text",
        },
        verificationStatus: {
            name: "verificationStatus",
            label: "Email Verification Status",
        },
        apiUser: {
            name: "apiUser",
            label: "API User",
            type: "text",
            errorMsg: "API User is required.",
            invalidMsg: "API User must be at least 1 character",
        },
        apiKey: {
            name: "apiKey",
            label: "API Key",
            type: "text",
            errorMsg: "API Key is required.",
            invalidMsg: "API Key must be 64 characters",
        },
        licenceCount: {
            name: "licenceCount",
            label: "Licence Count",
            type: "number",
            errorMsg: "Licence Count is required.",
            invalidMsg: "Licence Count must be at least 16 characters",
        },
    },
};

export default form;
