/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
//import Analytics from "layouts/dashboards/analytics";
//import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import Chatresp from "layouts/pages/account/messaging";

import SignInCover from "layouts/authentication/sign-in/cover";

import ResetCover from "layouts/authentication/reset-password/cover";
import ContactsResp from "layouts/pages/account/contactInfo";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import MyPayments from "layouts/pages/affiliate/myPayments";
import MyUsers from "layouts/pages/affiliate/myUsers";
import ExtensionIcon from "@mui/icons-material/Extension";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ChatIcon from "@mui/icons-material/Chat";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TextureIcon from '@mui/icons-material/Texture';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import Setting from "layouts/pages/settings";
import Video from "components/Watch";
// @mui icons
import Icon from "@mui/material/Icon";
import AddIcon from '@mui/icons-material/Add';
import { useContext, useState, useEffect } from "react";
import AuthContext from "context/Authcontext";
import GroupIcon from '@mui/icons-material/Group';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SupportIcon from '@mui/icons-material/Support';

// Images
import profilepic from "assets/crmimages/profile.jpg";
import Calendar from "layouts/pages/calendar";
import ChatPrompts from "layouts/pages/account/chatPrompts";
import Coupons from "layouts/pages/stripe/coupons";
import UserList from "layouts/pages/users/user-list";
import Subscription from "layouts/pages/stripe/subscription";
import Products from "layouts/pages/stripe/products";
import GeneRateNewCoupons from "layouts/pages/appsumo/GenerateNewCoupons";
import AppSumoCouponsList from "layouts/pages/appsumo/coupons";
import Videos from "layouts/pages/videos"
import Support from "layouts/pages/support";

const routes = [
  {
    type: "collapse",
    name: "Username",
    key: "username",
    icon: <MDAvatar src={profilepic} alt="" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "profile-overview",
        icon: <PersonIcon />,
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "settings",
        icon: <SettingsIcon />,
        route: "/pages/account/settings",
        component: <Settings />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Extension",
    key: "extension",
    icon: <ExtensionIcon />,
    collapse: [
      {
        name: "Message",
        key: "messaging",
        icon: <ChatIcon />,
        route: "/pages/account/messaging",
        component: <Chatresp />,
      },
      {
        name: "Contacts",
        key: "contact-info",
        icon: <ImportContactsIcon />,
        route: "/pages/account/contact-info",
        component: <ContactsResp />,
      },
      {
        name: "Chat Prompts",
        key: "chat-prompts",
        icon: <ChatBubbleOutlineIcon />,
        route: "/pages/account/chat-prompts",
        component: <ChatPrompts />,
      },
    ],
  },
  
  {
    type: "collapse",
    name: "Stripe",
    key: "stripe",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Coupons",
        key: "coupons",
        route: "/pages/stripe/coupons",
        icon: <LocalOfferSharpIcon />,
        component: <Coupons />,
      },
      {
        name: "Subscription",
        key: "subscription",
        route: "/pages/stripe/subscription",
        icon: <SubscriptionsOutlinedIcon />,
        component: <Subscription />,
      },
      {
        name: "Products",
        key: "products",
        route: "/pages/stripe/products",
        icon: <ProductionQuantityLimitsIcon />,
        component: <Products />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <GroupIcon />,
    collapse: [
      {
        name: "List Users",
        key: "list users",
        icon: <FormatListBulletedIcon />,
        route: "/pages/users/user-list",
        component: <UserList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Appsumo",
    key: "appsumo",
    icon: <AssessmentIcon />,
    collapse: [
      {
        name: "List coupons",
        key: "list coupons",
        icon: <FormatListBulletedIcon />,
        route: "/pages/appsumo/coupon-list",
        component: <AppSumoCouponsList />,
      },
      {
        name: "Generate coupons",
        key: "Generate coupons",
        icon: <AddIcon />,
        route: "/pages/appsumo/coupon-generate",
        component: <GeneRateNewCoupons />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Calendar",
    key: "calendar",
    icon: <CalendarMonthIcon />,
    collapse: [
      {
        name: "My Appointments",
        key: "my-appointments",
        icon: <EventAvailableIcon />,
        route: "/my-appointments",
        component: <Calendar />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Affiliate",
    key: "affiliate",
    icon: <CalendarMonthIcon />,
    collapse: [
      {
        name: "My Users",
        key: "my-users",
        icon: <RecentActorsIcon />,
        route: "/my-users",
        component: <MyUsers />,
      },
      {
        name: "My Payments",
        key: "my-Payment",
        icon: <MonetizationOnIcon />,
        route: "/my-payments",
        component: <MyPayments />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Videos",
    key: "videos",
    icon: <SmartDisplayIcon />,
    collapse: [
      {
        name: "Videos List",
        key: "video-list",
        icon: <VideoLibraryIcon />,
        route: "/pages/videos/video-list",
        component: <Videos />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <SupportIcon />,
    route: "/pages/support",
    component: <Support />,
    noCollapse: [],
    showCollapseIcon: false
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <SettingsIcon />,
    route: "/pages/settings",
    component: <Setting />,
    noCollapse: [],
    showCollapseIcon: false
  },
  {
    name:"video",
    route: "/watch/:accessId",
    component: <Video />, // the component that will render this page
  }
];


export default routes;
