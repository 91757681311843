// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import { useState } from "react";
import ChangePasswordUsingOldPassword from "./components/ChangePasswordUsingOldPassword";
import ResetPasswordUsingOTP from "./components/ResetPasswordUsingOTP";

function ChangePassword() {
  const [isToggleContainer, setIsToggleContainer] = useState(true);

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const toggleContainer = () => {
    setIsToggleContainer(!isToggleContainer);
  };

  return (
    <Card id="change-password">
      <MDBox py={3} px={3}>
        <MDBox pb={2}>
          <MDTypography variant="h5">
            {isToggleContainer ? "Change Password" : "Reset Password"}
          </MDTypography>
        </MDBox>
        {isToggleContainer ? (
          <ChangePasswordUsingOldPassword onToggle={toggleContainer} isToggle={isToggleContainer} />
        ) : (
          <ResetPasswordUsingOTP onToggle={toggleContainer} isToggle={isToggleContainer} />
        )}
        {/* <MDBox>
          <MDBox mt={6} mb={1}>
            <MDTypography variant="h5">Password requirements</MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="body2" color="text">
              Please follow this guide for a strong password
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderPasswordRequirements}
            </MDBox>
          </MDBox>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
