import * as React from "react";
import { Box, Container, Link } from "@mui/material";
import MDTypography from "components/MDTypography";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <Box m={7}>
        <MDTypography variant="h3" component="h1" paragraph>
          Terms of Use
          <br />
        </MDTypography>
        <MDTypography variant="body1" paragraph>
          Please read these terms of use very carefully before registering for the Skoop Services
          which include, but are not limited to; the Skoop website, the Skoop Chrome Extension (the
          “App”), Skoop{"\u2019"}s Video services, and any free services provided by Skoop
          (collectively the “Services”). The words “you” in the terms of use refers to you the User.
          These terms of use (the “Agreement”) govern the content you provide and any other use of
          the Services. The Agreement is conducted between you (the “User”) and Buildscale, Inc.
          (doing business and referred to as “Skoop”, “us” or “we” herein). The Agreement is subject
          to change by us as described below. By using the Services, you <br />
          <div className="left-padding-40">
            (i) accept the Agreement;
            <br />
            (ii) agree to be bound by the terms of the Agreement; <br />
            (iii) agree to be bound by these terms and conditions of our Privacy Policy and <br />
            (iv) have entered into a binding agreement between you and The Troy Agency, Inc.
          </div>
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          Occasionally we may, at our sole discretion, make changes to the Agreement. When we make
          material changes to the Agreement, we will provide you with prominent notice as
          appropriate under the circumstances, e.g., by displaying a prominent notice within the
          Services or by sending you a notice by email. In some cases, we will notify you in
          advance, and your continued use of the Services after the changes have been made will
          constitute your acceptance of the changes. Therefore, please ensure you read any such
          notice carefully and provide with Skoop updated contact information in a timely manner. If
          you do not wish to accept any revised terms of the Agreement, you may terminate the
          Agreement by contacting us through the Skoop Support contact form and immediately ceasing
          to use the Services.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          1. The Services, including but not limited to; the Skoop website, the App, Skoop{"\u2019"}
          s GoVideo service, the GoVideo website, and any free services provided by Skoop, along
          with the content respectively included therein (“Content”) and any applications provided
          in connection therewith may only be accessed in accordance with the Agreement. The Skoop
          Services are owned and operated by Buildscale, Inc and any violation of these terms and
          conditions or the copyright in the Content may be enforced by Skoop or the relevant
          copyright owner to the fullest extent allowed by law.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          2. You are granted a limited, non-transferable license to access and use the Services as
          described herein. Such license is subject to these Terms of Use and does not include: (a)
          any resale or commercial use of the Services; (b) modifying or otherwise making any
          derivative uses of the Services, or any portion thereof; (c) use of any data mining,
          robots or similar data gathering or extraction methods; (d) downloading (other than the
          page caching) of any portion of the Services or any information contained therein, except
          as expressly permitted on the Services; or (e) any use of the Services other than for its
          intended purpose. Any use of the other than as specifically authorized herein, without the
          prior written permission Skoop, is strictly prohibited and will terminate the license
          granted herein. Such unauthorized use may also violate applicable laws including without
          limitation copyright and trademark laws. Unless explicitly stated herein, nothing in this
          Agreement shall be construed as conferring any license to intellectual property rights,
          whether by estoppel, implication, or otherwise. This license is revocable at any time.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          3. Should you access and/or use the Services on the behalf of another legal entity, you
          hereby represent and warrant that you have the authority, actual or implied, to bind that
          entity to the Agreement.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          4. You must be at least 13 years old (or such other age as determined by us at our sole
          discretion, based on factors like where you live) to use the Services. If you are not yet
          legally considered an adult where you live, you may only use the Services if your parent
          or guardian agrees to this Agreement on your behalf. In using the Services, you (or your
          parent/guardian) represent and warrant that you have met these requirements.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          5. You acknowledge and agree that Skoop may stop (permanently or temporarily) providing
          the Services (or any features within the Services) to you or to users generally at Skoop
          {"\u2019"}s sole discretion, without prior notice to you. You may stop using the Services
          at any time. You do not need to expressly inform Skoop when you stop using the Services.
          You acknowledge and agree that if Skoop disables access to your account, you may be
          prevented from accessing the Services, your account details or any files or other
          materials which is contained in your account.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          6. You agree not to access (or attempt to access) any of the Services by any means other
          than through the interface that is provided by Skoop, unless you have been specifically
          allowed to do so in a separate agreement with Skoop.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          7. You, directly or indirectly, alone or with any other party, may not: modify, change,
          create derivative works of, disassemble, decompile or otherwise reverse engineer the
          Services or any software provided in connection with the Services (“Software”), or remove
          proprietary legends in the Services or Software; distribute, transfer, resell, rent,
          lease, or loan the Services or Software to any other party, except as described herein;
          make the Services or Software available to others in a Services bureau arrangement or for
          any similar commercial time- sharing or third-party training use; use the Services to
          harass, threaten or otherwise cause distress, unwanted attention or discomfort to a person
          or entity; post, transmit or otherwise use in connection with the Services any prohibited
          content including, without limitation, content that:
          <br />{" "}
          <div className="left-padding-40">
            (i) is pornographic, sexually explicit or offensive or contains a link to an adult
            website; <br />
            (ii) contains graphic or gratuitous violence including realistic portrayals of people or
            animals being killed, maimed, tortured, or abused, or other similar content;
            <br />
            (iii) is discriminatory, or mean-spirited content, including references or commentary
            about religion, race, sexual orientation, gender, national/ethnic origin, or other
            targeted groups, particularly if likely to humiliate, intimidate, or place a targeted
            individual or group in harm{"\u2019"}s way; <br />
            (iv) encourages or glorifies drug use; <br />
            (v) is predatory in nature, or is harassing or bullying;
            <br />
            (vi) promotes or incites racism, bigotry, hatred or physical harm of any kind against
            any group or individual; <br />
            (vii) constitutes or promotes information that you know or ought to know is false or
            misleading or promotes illegal activities; <br />
            (viii) constitutes conduct that is abusive, threatening, obscene, defamatory or
            libelous; <br />
            (ix) furthers or promotes criminal activity or provides instructional information about
            illegal activities; <br />
            (x) violates or attempts to violate the privacy rights, publicity rights, copyrights,
            trademark rights, contract rights or any other rights of any third party; or <br />
            (xi) is inflammatory religious commentary or inaccurate or misleading quotations of
            religious texts. send any content that is highly repetitive, unwanted, unsolicited,
            “spam”, or bulk commercial email; impersonate any person or entity, or falsely state or
            otherwise misrepresent your affiliation with a person or entity; violate (intentionally
            or unintentionally) any applicable local, state, national or international law
            including, but not limited to, any regulations having the force of law; or interfere
            with or disrupt the Services or Software, or servers or networks connected to the
            Software, or disobey any requirements, procedures, policies or regulations of networks
            connected to the Services or Software.
            <br />
          </div>
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          8. You agree that you are solely responsible for (and that Skoop has no responsibility to
          you or to any third party for) any breach of your obligations under the Agreement and for
          the consequences (including any loss or damage which Skoop may suffer) of any such breach.
          To the extent Skoop incurs any financial penalties or other costs and expenses (including
          investigation expenses) from Skoop{"\u2019"}s server hosting facility, internet Services
          provider or other vendors because of your use of the Software, you shall be obligated to
          immediately reimburse Skoop for any such penalties, costs or expenses.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          9. You understand that all information (such as data files, written text, computer
          software, music, audio files or other sounds, photographs, videos or other images) which
          you may have access to as part of, or through your use of, the Services is the sole
          responsibility of the person from which such materials originated, which may be you.
          Content you create may be stored on your local device, in the cloud, or any other method
          the Services so authorizes. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          10. You understand that you may choose not to allow the Services access to your camera,
          access your microphone, or to provide notifications related to the Services. Your choice
          not to allow such access, or provision of notifications, may impact the functionality of
          the Services and may render it unable to deliver some or all of its functions. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          11. Skoop reserves all rights not specifically granted herein. You shall not modify any
          copyright notices, proprietary legends, any trademark and Services mark attributions, any
          patent markings, and other indicia of ownership on the Content or other materials accessed
          through the Services. The delivery of, and license to, the Content and/or access to third
          party materials does not transfer to you any commercial or promotional use rights in the
          Content or any portion thereof. Any use of Content, or descriptions; any derivative use of
          this Services or its materials; and any use of data mining, robots, or similar data
          gathering and extraction tools is strictly prohibited. In no event shall the user frame
          any portion of the Services or any materials contained therein. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          12. While Skoop uses reasonable efforts to include accurate and up-to-date information on
          the Services, Skoop makes no warranties or representations as to its accuracy. Skoop
          assumes no liability or responsibility for any errors or representations in the Content or
          the Services. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          13. The Services may contain links to other sites on the Internet which are owned and
          operated by Third Party Vendors and other third parties (the “External Sites”). You
          acknowledge that Skoop is not responsible for the availability of, or the materials
          located on or through, any External Sites. You should contact the site administrator or
          webmaster for those External Sites if you have any concerns regarding such links or the
          materials located on such External Sites. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          14. We may work with a third party to offer certain Services functions, like third party
          sign-ins for example. If you use such a third party Services, you understand that the
          third party{"\u2019"}s terms of Services will apply to such use. It{"\u2019"}s your
          responsibility to read and understand the terms of Services related to the third party
          Services before you use it. We are not responsible or liable for your use of any third
          party Services functions, or actions the third party takes under their terms of Services.
          We make no representations or warranties about third party Services provision. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          15. You must comply with third party terms of agreement that you have entered into to
          access the Services (e.g. make sure that your download/use of the Services doesn{"\u2019"}
          t violate the terms of your wireless data Services agreement, or the agreement you strike
          with the provider of wi-fi internet access). By using the Services, you represent and
          warrant that you are not violating the terms of any third party agreement to which you are
          a party. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          16. You acknowledge that by accessing the Services, you may come into contact with content
          that you find harmful, offensive, threatening, indecent or objectionable and you
          acknowledge that Skoop shall have no liability to you for the content including, but not
          limited to explicit language and other potentially offensive material. The user agrees not
          to impersonate any person or communicate under a false name or a name the user is not
          entitled or authorized to use. Skoop has the right (but not the obligation) to remove,
          prohibit, edit or discontinue any content on the Services, including content that has been
          posted by users. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          17. Skoop reserves the right (but has no obligation) to filter, investigate, remove,
          prohibit, edit or discontinue any content on the Services, including content that has been
          posted by Users, in our sole discretion, that violates the provisions of the Agreement or
          any user guidelines or codes of conduct that we may provide to users. Without limiting the
          foregoing, Skoop may remove any offending Content without prior notice, terminate or
          suspend a Customer{"\u2019"}s Account or access to the Services and/or report such Content
          or activities to law enforcement authorities. You acknowledge and agree that Skoop may, at
          any time and for any reason, view any posted content or submitted material. Without
          limiting the foregoing, Skoop may use any means necessary to ensure that posted content
          and submitted materials do not breach the Agreement. Accounts under investigation may be
          temporarily locked. Skoop reserves the right to immediately and permanently terminate your
          account without notice in the event that, in its sole discretion, you violate the
          Agreement. You are responsible for maintaining any necessary copies of your content. If a
          user account has been terminated, the user will have no opportunity to retrieve or
          download content. Accounts created to replace terminated accounts may be terminated
          without further notice.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          18. If you post content or submit material (“Posted Materials”), the following shall
          apply:
          <br />
          <div className="left-padding-40">
            1.You represent and warrant that all Posted Materials you provide are your original work
            products and will not be based on or derived from any third party{"\u2019"}s work
            products unless you have secured permission from the third party and can demonstrate
            that to Skoop. Furthermore, you represent and warrant that your use of the Services in
            connection with any Posted Materials or third-party content complies with all laws
            including, but not limited to, copyright law. <br />
            2.You retain all ownership rights in Posted Materials that you are entitled to by law.
            <br />
            3.You grant Skoop and its affiliates a revocable, worldwide, royalty-free,
            non-exclusive, sublicensable, transferable, license to use, reproduce, perform,
            distribute, and display Posted Materials solely for the purpose of providing the
            Services and improving the Services in general. Furthermore, you grant Skoop, its
            affiliates, and sublicensees the right to use your name and/or user name in connection
            with Posted Materials.
            <br />
            4.You grant each user of the Services a nonexclusive license to access Posted Materials
            through the Services, and to use, reproduce, distribute, display and perform Posted
            Materials as permitted through the functionality of the Services and under this
            Agreement.
            <br />
            5.The licenses granted in Posted Materials terminate within a commercially reasonable
            time after you remove or delete Posted Materials from the Services; however, you
            understand, acknowledge and agree that Skoop may retain, but not display, server copies
            of Posted Materials that have been removed or deleted.
            <br />
            6.The licenses granted by you with respect to comments you submit are perpetual and
            irrevocable.
            <br />
            7.You will defend, indemnify and hold Skoop and its affiliates harmless from and against
            any claims resulting from Posted Materials or materials you provide hereunder or your
            use of the Services.
            <br />
          </div>
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          19. At your discretion, you may provide feedback to Skoop concerning the functionality and
          performance of the Services from time to time, including, without limitation, identifying
          potential errors, improvements, modifications, bug fixes, or enhancements (“Feedback”). If
          you, through your evaluation or otherwise, suggest any Feedback, you hereby assign the
          ownership in all Feedback to Skoop. In the event ownership in the Feedback cannot be
          granted to Skoop, you grant Skoop at no charge a perpetual, irrevocable, royalty-free,
          worldwide right and license to use, reproduce, disclose, sublicense, distribute, modify,
          and otherwise exploit such Feedback without restriction. You agree that Skoop may disclose
          any Feedback to any third party in any manner and you agree that Skoop has the ability to
          sublicense all Feedback in any form to any third party without restriction.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          20. If you gain access to the Services as part of your job, your employer may have agreed
          to additional terms or conditions related to their corporate subscription to the Services.
          It{"\u2019"}s your responsibility to understand those additional terms and comply with
          them in full. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          21. The Services may contain areas in which additional terms and conditions apply. For
          purposes of the use of such areas in connection with the Services, in the event of a
          conflict between the terms and conditions of such other areas and the Agreement, the terms
          and conditions of the other area shall prevail, to the extent that there is a conflict
          this the Agreement. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          22. The Services may use Cookies to provide information to Skoop{"\u2019"}s software or
          server on which the Services runs. “Cookies” are small pieces of information and
          programmatically generated data that are stored on a computer{"\u2019"}s hard drive.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          23. You shall not <br />{" "}
          <div className="left-padding-40">
            (i) transmit to Skoop or upload to this Services any Harmful Code or use or <br />
            (ii) misappropriate the data on this Services for your own commercial gain. “Harmful
            Code” shall mean any software (sometimes referred to as “viruses”, “worms”, “trojan
            horses”, “time bombs”, “time locks”, “drop dead devices”, “traps”, “access codes”,
            “cancelbots” or “trap door devices”) that: <br />
            <div className="left-padding-40">
              (a) is intentionally designed to damage, disrupt, disable, harm, impair, interfere
              with, intercept, expropriate or otherwise impede in any manner, any data, storage
              media, program, system, equipment or communication, based on any event, including for
              example but not limited to <br />
              <div className="left-padding-40">
                (A) exceeding a number of copies,
                <br />
                (B) exceeding a number of users, <br />
                (C) passage of a period of time, <br />
                (D) advancement to a particular date or other numeral, or <br />
                (E) use of a certain feature; or{" "}
              </div>
              <br />
              (b) would enable an unauthorized person to cause such result; or <br />
              (c) would enable an unauthorized person to access another person{"\u2019"}s
              information without such other person{"\u2019"}s knowledge and permission.
            </div>
          </div>
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          24. You may not use your username and password for any unauthorized purpose. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          25. As between the parties, Skoop owns and shall continue to own all right, title and
          interest in and to all performance and usage data generated through your use of the
          Services, Services or Software, including any aggregated and statistical information or
          analyses created and developed by Skoop from such data (collectively, “Usage Data”). In
          addition, Skoop may disclosure such Usage Data without restriction provided that it is
          de-identified so that you cannot be identified as the source within the Usage Data.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          26. Skoop may terminate your access to our Services for any reason at any time. The
          provisions that by their nature are designed to survive termination or expiration of the
          Agreement shall survive termination or expiration of the Agreement or your access to our
          Services.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          27. By using the Services, you agree to comply with all applicable laws and regulations,
          including but not limited to, privacy laws, copyright laws and other laws regarding
          intellectual property. If you do not agree to these terms of use, please do not use the
          Services. THE AGREEMENT SHALL BE GOVERNED AND INTERPRETED BY THE DOMESTIC LAWS OF THE
          PROVINCE OF ONTARIO WITHOUT REGARD TO THE CONFLICTS OF LAW PROVISIONS THEREOF. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          28. The materials on this Services are provided with “Restricted Rights.” Use,
          duplication, or disclosure by the Government is subject to restrictions as set forth in
          applicable laws and regulations. Use of the materials by the Government constitutes
          acknowledgment of Skoop{"\u2019"}s proprietary rights in them. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          29. THE MATERIALS, THE CONTENT ON THE SITE AND THE SERVICES ARE PROVIDED “AS IS”, AND “AS
          AVAILABLE” WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. Skoop SHALL HAVE NO
          RESPONSIBILITY OR LIABILITY FOR ANY CONTENT, MATERIALS POSTED ON THE SITE OR SERVICES.
          Skoop MAKES NO GUARANTEES AS TO UPTIME OR AVAILABILITY OF THE SERVICES. TO THE FULLEST
          EXTENT POSSIBLE PURSUANT TO THE APPLICABLE LAW, Skoop DISCLAIMS ALL WARRANTIES, EXPRESSED
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, NONINFRINGEMENT OR OTHER VIOLATION OF RIGHTS. THE SERVICES MAY
          BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET
          AND ELECTRONIC COMMUNICATIONS. Skoop IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES,
          OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS, INCLUDING INTERNET CONGESTION, VIRUS
          ATTACKS, AND DENIAL OF SERVICES (DOS) ATTACKS.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          30. TO THE EXTENT NOT PROHIBITED BY LAW, UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT
          LIMITED TO, NEGLIGENCE, BREACH OF CONTRACT, OR BREACH OF ANY STATUTORY OR OTHER DUTY OF
          CARE, SHALL Skoop OR ITS THIRD PARTY SUB-LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,
          SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA
          OR PROFIT, ARISING OUT OF THE USE, OR THE INABILITY TO USE, THE MATERIALS ON THIS SITE OR
          THE SERVICES, EVEN IF Skoop HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND
          NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. YOU HEREBY VOLUNTARILY
          RELEASE, FOREVER DISCHARGE, AND AGREE TO INDEMNIFY AND HOLD HARMLESS BUILDSCALE, INC. AND
          TO WAIVE ANY AND ALL CLAIMS, DEMANDS, OR CAUSES OF ACTION, THAT YOU HAVE OR MAY HAVE IN
          THE FUTURE AGAINST BUILDSCALE, INC., AND TO RELEASE BUILDSCALE, INC. FROM ANY AND ALL
          LIABILITY FOR ANY LOSS, DAMAGE, EXPENSE OR INJURY THAT YOU MAY SUFFER AS A RESULT OF YOUR
          USE OF THE SERVICES DUE TO ANY CAUSE WHATSOEVER, INCLUDING NEGLIGENCE, BREACH OF CONTRACT,
          OR BREACH OF ANY STATUTORY OR OTHER DUTY OF CARE. Skoop{"\u2019"}S AND ITS LICENSORS
          {"\u2019"} ENTIRE AND AGGREGATE LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING
          NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, SHALL NOT EXCEED $100, EVEN IF Skoop HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL
          PURPOSE OF ANY REMEDY. IF YOUR USE OF MATERIALS FROM THIS SITE OR THE SERVICES RESULTS IN
          THE NEED FOR SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ANY COSTS
          THEREOF. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
          CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          31. With respect to the App only, the following terms shall apply:
          <br />
          <div className="left-padding-40">
            1.The Agreement is conducted between you Skoop, and not with Apple Inc. nor its related
            entitles as described in the Apple Store Terms (“Apple”).
            <br />
            2.You are granted a limited, non-transferable license to access and use the App on any
            Apple-branded products that you own or control as permitted by the Usage Rules defined
            in the Apple App Store Terms of Services. Notwithstanding the foregoing, the App may be
            accessed and used by other accounts associated with yours via Family Sharing or volume
            purchasing.
            <br />
            3.In using the Services, you represent and warrant that <br />
            <div className="left-padding-40">
              (i) you are not located in a country that is subject to a U.S. Government embargo, or
              that has been designated by the U.S. Government as a “terrorist supporting” country;
              and
              <br />
              (ii) you are not listed on any U.S. Government list of prohibited or restricted
              parties.
            </div>
            <br />
            4.You may not use the App to facilitate illegal file sharing; you shall not save,
            convert, stream, or download media from third party sources (e.g. Apple Music, YouTube,
            SoundCloud, Vimeo, etc.) without explicit authorization from those sources the rights
            holders that possess the intellectual property rights associated with the relevant
            media. In the event that you do sa ve, convert, stream or download media, you must
            provide the necessary consents, agreements or other documentation to us or Apple upon
            request.
            <br />
            5.Apple shall not be responsible in any form for the App or the content thereof.In the
            event of a third party claim that the App, or your possession and use of the App
            infringes a third party{"\u2019"}s intellectual property rights, the terms of this
            Agreement shall apply and without limiting the foregoing, Apple will not be responsible
            for any investigation defense, settlement or discharge of any such intellectual property
            infringement claim.
            <br />
            6.In the event that the disclaimer of warranty described in section 29 above is held
            invalid or unenforceable under applicable law, you may notify Apple of any App
            deficiency and Apple will refund the purchase price of the App (if applicable) to you;
            and with that, to the maximum extent permitted by applicable law, Apple will have no
            warranty obligation whatsoever with respect to the App, and any other claims, losses,
            liabilities, damages, costs or expenses attributable to any failure to conform to any
            warranty will be subject to the terms of this Agreement and Skoop{"\u2019"}s
            corresponding responsibility.
            <br />
            7.The parties to this Agreement acknowledge that Apple shall not be responsible for
            addressing any of your claims or any third party claims relating to the App or your
            possession and/or use of that App, including, but not limited to: <br />
            <div className="left-padding-40">
              (i) product liability claims; <br />
              (ii) any claim that the App fails to conform to any applicable legal or regulatory
              requirement; or <br />
              (iii) claims arising under consumer protection, privacy, or similar legislation,
              including in connection with the App{"\u2019"}s use of the HealthKit and HomeKit
              frameworks (if applicable).
            </div>
            <br />
            8.The parties to this Agreement acknowledge that Apple has no obligation whatsoever to
            furnish any maintenance and support Services with respect to the App. Skoop will be
            responsible for the maintenance or support Services related to the App that are
            described herein or required under applicable law.
            <br />
            9.The parties to this Agreement acknowledge and agree that Apple, and Apple{"\u2019"}s
            subsidiaries, are third party beneficiaries of the Agreement, and that, upon your
            acceptance of this Agreement, Apple will have the right (and will be deemed to have
            accepted the right) to enforce the Agreement against you as a third party beneficiary
            thereof.
            <br />
            10.If any terms of this Agreement cause conflict with Apple{"\u2019"}s Minimum Terms for
            Developer{"\u2019"}s End User License Agreement or Apple Store Terms of Services
            (collectively, the “Apple Terms”), the Apple Terms will govern any such conflict.
            <br />
          </div>
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          32. The Agreement shall constitute the entire agreement between the parties regarding the
          subject matter hereof and supersede all proposals and prior discussions and writings
          between the parties with respect thereto. No failure or delay in enforcing any r ight or
          exercising any remedy under the Agreement will be deemed a waiver by Skoop of any right or
          remedy. Each provision of the Agreement is a separately enforceable provision. If any
          provision of the Agreement is determined to be or becomes unenforceable or illegal, such
          provision shall be reformed to the minimum extent necessary in order for the Agreement to
          remain in effect in accordance with its terms as modified by such reformation. The
          Agreement may not be modified, supplemented, amended or interpreted by any trade usage or
          prior course of dealing unless specifically agreed upon in writing. Reasonable attorneys
          {"\u2019"} fees and costs will be awarded to the prevailing party in the event of
          litigation involving the enforcement or interpretation of the Agreement. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          33.Skoop shall not be liable to you for any delay or failure of Skoop to perform its
          obligations hereunder if such delay or failure arises from any cause or causes beyond the
          reasonable control of Skoop. Such causes shall include, but are not limited to, acts of
          God, floods, fires, loss of electricity or other utilities, labor strike, or delays by you
          in performing any requirements hereunder. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          34. By accessing and/or using the Services on the behalf of another legal entity, you
          consent on behalf of that legal entity to Skoop publicly listing the name of that legal
          entity as a user of the Services and publicly displaying that legal entity{"\u2019"}s
          standard logo for the purpose of Skoop{"\u2019"}s marketing and publicity.
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          35. By accessing and/or using the Services for business purposes, you consent to us
          providing your use information to your employer and/or colleagues, identified by the email
          address used to register for your Account, for the purpose of marketing and promotion.{" "}
          <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          36. The provincial or federal courts sitting in Waterloo Region, in the Province of
          Ontario shall have exclusive jurisdiction and venue over any dispute arising out of the
          Agreement, and you hereby consent to the jurisdiction of such courts. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          37. All notices regarding the Agreement and the Services will be deemed given one (1)
          business day after e-mail transmission from Skoop to me, or two (2) business days after
          the date of posting. <br />
          <br />
        </MDTypography>

        <MDTypography variant="body1" component="h1" paragraph>
          38. Should you start using any of Skoop{"\u2019"}s paid Services, additional terms and
          conditions will apply. <br />
          <br />
        </MDTypography>
        <MDTypography variant="body1" component="h1" paragraph>
          39. Contact details for Skoop are as follows: email: support@skoop.com <br />
          <br />
        </MDTypography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
