
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import PaymentsView from "layouts/pages/affiliate/myPayments/components/PaymentsView";

function MyPayments() {
  return (
    <BaseLayout stickyNavbar>
      <PaymentsView />
    </BaseLayout>
  );
}

export default MyPayments;
