import { useContext, useEffect } from "react";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import StripeCoupons from "./components/StripeCoupons";
import AuthContext from "context/Authcontext";

function Coupons() {

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);

  return (
    <BaseLayout stickyNavbar>
      <StripeCoupons />
    </BaseLayout>
  );
}


export default Coupons;

