import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import VideoList from "./components/VideoList"

// css
import "../../../styles/videos/directory-tabs.css"


function Videos(){

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);
    
    return (
        <BaseLayout>
            <VideoList />
        </BaseLayout>
    );
}

export default Videos;