import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import List from "./components/List";

function Support() {
  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <BaseLayout>
      <Card id="Support">
        <MDBox>
          <List />
        </MDBox>
      </Card>
    </BaseLayout>
  );
}

export default Support;