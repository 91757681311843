import React from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

const DropDownMenu = ({ filteringParams, setFilteringParams, options, type}) => {

  const handleTypeChange = (option) => {
    let index = filteringParams?.findIndex((params)=> params.type === type);
    if (index !== -1) {
      setFilteringParams((filteringParams) => [
        ...filteringParams.slice(0, index),
        { ...filteringParams[index], value: option.value },
        ...filteringParams.slice(index + 1),
      ]);
    } else {
      setFilteringParams((filteringParams) => [
        ...filteringParams,
        { type, value: option.value },
      ]);
    }
  };

  return (
    <MDBox>

<Autocomplete
  disableClearable={true}
  options={options}
  getOptionLabel={(option) => option.label}
  value={options.find(
    (status) => status.value === filteringParams?.find((params) => params.type === type)?.value
  ) || null}
  onChange={(event, value)=>handleTypeChange(value)}
  renderInput={(params) => (
    <MDInput
      {...params}
      variant="standard"
      label={type?.length > 0 ? type.split("_").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ") : ""}
    />
  )}
/>
    </MDBox>
  );
};

export default DropDownMenu;
