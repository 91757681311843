/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";


function Contact({ data, onDelete, noGutter ,closeNew}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(data.id==null?true:false);
  const [editedName, setEditedName] = useState(data.name);
  const [editedEmail, setEditedEmail] = useState(data.personal_email);
  const [editedBEmail, setEditedBEmail] = useState(data.business_email);
  const [editedEEmail, setEditedEEmail] = useState(data.enriched_email);
  const [editedPhoneNumber,setEditedPhoneNumber]= useState(data.phone_number)
  const [editedAddress,setEditedAddress]= useState(data.address)
  const [editedLinkedInUrl,setEditedLinkedInUrl] = useState(data.linkedIn_url) 
  const [editedWebSiteUrl,setEditedWebSiteUrl] = useState(data.website_url)

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleSave = async () => {
    try{
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/CrmAddContactInfo`,{
        method: "POST",
        headers: {
          "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
          "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
          id: data.id,
          name: editedName,
          personal_email: editedEmail,
          business_email: editedBEmail,
          enriched_email: editedEEmail,
          phone_number: editedPhoneNumber,
          website_url: editedWebSiteUrl,
          linkedIn_url: editedLinkedInUrl,
          address: editedAddress
        })
      })
      setIsEditing(false);
      setEditedName(editedName);
      setEditedEmail(editedEmail);
      closeNew();
    }catch(err){
      alert(err);
    }
  };

  const handleDelete = async() => {
    if(data.id==null){
      closeNew();
      return ;
    }
    await onDelete();
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >  
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          {isEditing ? (
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Name"
              fullWidth
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
          ) : (
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {editedName}
            </MDTypography>
          )}
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton variant="text" color="error" onClick={handleDelete}>
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            <MDButton
              variant="text"
              color={darkMode ? "white" : "dark"}
              onClick={isEditing ? handleSave : handleEdit}
            >
              <Icon>edit</Icon>&nbsp;{isEditing ? "Save" : "Edit"}
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="black">
            &nbsp;&nbsp;&nbsp;
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Email"
                fullWidth
                size="small"
                color="black"
                value={editedEmail}
                onChange={(e) => setEditedEmail(e.target.value)}
              />
            ) : (
              
              <MDTypography variant="caption" fontWeight="medium">
                Personal Email: {editedEmail}
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="black">
            &nbsp;&nbsp;&nbsp;
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Business Email"
                fullWidth
                size="small"
                color="black"
                value={editedBEmail}
                onChange={(e) => setEditedBEmail(e.target.value)}
              />
            ) : (
              
              <MDTypography variant="caption" fontWeight="medium">
                Business Email: {editedBEmail}
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="black">
            &nbsp;&nbsp;&nbsp;
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enriched Email"
                fullWidth
                size="small"
                color="black"
                value={editedEEmail}
                onChange={(e) => setEditedEEmail(e.target.value)}
              />
            ) : (
              
              <MDTypography variant="caption" fontWeight="medium">
                Enriched Email: {editedEEmail}
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="black">
            &nbsp;&nbsp;&nbsp;
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Phone Number"
                fullWidth
                size="small"
                color="black"
                value={editedPhoneNumber}
                onChange={(e) => setEditedPhoneNumber(e.target.value)}
              />
            ) : (
              
              <MDTypography variant="caption" fontWeight="medium">
                Phone Number: {editedPhoneNumber}
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="black">
            &nbsp;&nbsp;&nbsp;
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Address"
                fullWidth
                size="small"
                color="black"
                value={editedAddress}
                onChange={(e) => setEditedAddress(e.target.value)}
              />
            ) : (
              
              <MDTypography variant="caption" fontWeight="medium">
                Address: {editedAddress}
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="black">
            &nbsp;&nbsp;&nbsp;
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Linkedin Url"
                fullWidth
                size="small"
                color="black"
                value={editedLinkedInUrl}
                onChange={(e) => setEditedLinkedInUrl(e.target.value)}
              />
            ) : (
              
              <MDTypography variant="caption" fontWeight="medium">
                Linkedin url: {editedLinkedInUrl}
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="black">
            &nbsp;&nbsp;&nbsp;
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="website"
                fullWidth
                size="small"
                color="black"
                value={editedWebSiteUrl}
                onChange={(e) => setEditedWebSiteUrl(e.target.value)}
              />
            ) : (
              
              <MDTypography variant="caption" fontWeight="medium">
                Website: {editedWebSiteUrl}
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Contact.defaultProps = {
  noGutter: false,
};

Contact.propTypes = {
  //heading: PropTypes.string.isRequired,
  //description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  //onDelete: PropTypes.func,
};

export default Contact;
