import React, { useState, useRef, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MyIcon from "assets/crmimages/skoop-logo-black.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import API_ENDPOINTS from "apiConfig";
import Plyr from "plyr";
import NotFound from "assets/crmimages/icons/not-found.svg";
import { useContext } from "react";
import AuthContext from "context/Authcontext";
import { Helmet } from "react-helmet";
import MDBox from "components/MDBox";
import { BiSolidPencil } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import EditCtaModal from "components/Watch/EditCta";
import ConfirmDeleteModal from "components/ConfirmDelete";
import toast from "react-hot-toast";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { MdManageHistory } from "react-icons/md";

const WatchPage = () => {
  const { accessId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [showCustomButtonCard, setShowCustomButtonCard] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [videoData, setVideoData] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const { getProfileDetails, fetchMySettings, profileDetails } = useContext(AuthContext);
  const [videoNotFound, setVideoNotFound] = useState(false);
  const [customData, setCustomData] = useState([]);
  const cardRef = useRef(null);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [baseUrl, setBaseUrl] = useState("");
  const [showEditCtaModal, setShowEditCtaModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [btnIndex, setBtnIndex] = useState(null);
  const [ctaText, setCtaText] = useState("");
  const [showCtaSection, setShowCtaSection] = useState(false);
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const [isHorizentalView, setIsHorizentalView] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [ctaStatus, setCtaStatus] = useState("");
  const [ctaLink, setCtaLink] = useState("");
  const [showCardSection, setShowCardSection] = useState(false);
  const [activeCardBtn, setActiveCardBtn] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 990);
  const [isPlaying, setIsPlaying] = useState(false);
  const isPlayingRef = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 990);
      setShowCardSection(window.innerWidth <= 990);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleApiResponse = (response) => {
    if (response.result && response.result[0]) {
      setVideoData(response.result[0]);
      if (response.result[0].custom_data) {
        let data = JSON.parse(response.result[0].custom_data);
        const updatedCustomData = data.map((item) => {
          return {
            ...item,
            is_created: true,
          };
        });
        setCustomData(updatedCustomData);
      }
      setTitle(response.result[0].video_title);
      setDescription(response.result[0].description);
      setVideoNotFound(false);
    } else {
      setVideoNotFound(true);
    }

    if (response?.cta_details) {
      setCtaStatus(Boolean(response.cta_details.cta_status));
      setCtaText(response.cta_details.cta_text);
      setCtaLink(response.cta_details.calendar_url);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (accessId) {
        let response = await fetch(`${API_ENDPOINTS.getLinkDataByAccessId}${accessId}`, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        response = await response.json();

        if (response.result && response.result[0]) {
          if (response.result[0].width == 288) {
            setVideoHeight(569);
            setVideoWidth(321);
            setIsHorizentalView(false);
          }
          if (response.result[0].width == 512) {
            setVideoHeight(288 * 1.3);
            setVideoWidth(512 * 1.3);
            setIsHorizentalView(true);
          }

          setThumbnailSrc(response.result[0].aws_thumbnail_link);
          setVideoSrc(response.result[0].aws_link);
        }

        handleApiResponse(response);
      }
    }
    fetchData();
  }, [accessId]);

  // useEffect(() => {
  //   if (videoSrc && videoRef.current) {
  //     if (playerRef.current) {
  //       playerRef.current.destroy();
  //       playerRef.current = null;
  //     }

  //     playerRef.current = new Plyr(videoRef.current, {
  //       controls: ["play", "progress", "current-time", "settings", "mute", "volume", "fullscreen"],
  //     });

  //     playerRef.current.on("play", () => setIsPlaying(true));
  //     playerRef.current.on("pause", () => setIsPlaying(false));
  //   }

  //   return () => {
  //     if (playerRef.current) {
  //       playerRef.current.destroy();
  //       playerRef.current = null;
  //     }
  //   };
  // }, [videoSrc]);

  useEffect(() => {
    if (videoSrc && videoRef.current && !playerRef.current) {
      playerRef.current = new Plyr(videoRef.current, {
        controls: ["play", "progress", "current-time", "settings", "mute", "volume", "fullscreen"],
      });

      playerRef.current.on("play", () => {
        setIsPlaying(true); // Update state when video starts playing
      });
      playerRef.current.on("pause", () => {
        setIsPlaying(false); // Update state when video pauses
      });
      playerRef.current.on("ended", () => {
        setIsPlaying(false); // Update state when video ends
      });

      return () => {
        if (playerRef.current) {
          playerRef.current.destroy();
          playerRef.current = null;
        }
      };
    }
  }, [videoSrc]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (videoData && videoData.username) {
        const user = await getProfileDetails();

        if (user && user.email == videoData.username) {
          setIsOwner(true);
        } else {
          setIsOwner(false);
        }
      }
    };

    fetchUserProfile();
    const url = window.location.origin;
    if (url) {
      setBaseUrl(url);
    }
  }, [videoData]);

  const goToCtaLink = () => {
    if (!ctaLink) {
      toast.error(
        "Your Call to Action link is not set. Please set it from the Account Settings section of the extension"
      );
      return;
    }

    window.open(ctaLink, "_blank");
  };

  const manageCtaClick = () => {
    if (videoData.video_title) {
      setTitle(videoData.video_title);
      setDescription(videoData.description);

      if (videoData?.custom_data) {
        let data = JSON.parse(videoData.custom_data);
        const updatedCustomData = data.map((item) => {
          return {
            ...item,
            is_created: true,
          };
        });
        setCustomData(updatedCustomData);
      }
    }
    setShowCard(true);
  };

  const handleCloseCard = () => {
    setShowCard(false);
    setShowCustomButtonCard(false);
  };

  // Handle switching to the "Create Custom Button" card
  const handleOpenCustomButtonCard = () => {
    setShowCustomButtonCard(true);
  };

  // Handle saving the custom button and updating the first card
  const handleSaveCustomButton = () => {
    if (customData?.length >= 3) {
      toast.error("You can only add up to 3 custom buttons");
      return;
    }

    if (buttonLabel?.trim().length === 0 || buttonLink?.trim().length === 0) {
      toast.error("Button label and link are required");
      return;
    }

    if (!buttonLink.startsWith("https")) {
      toast.error("Link should start with https");
      return;
    }

    if (buttonLabel && buttonLink) {
      const currentCustomData = customData || [];
      setCustomData((prevCustomData) => [
        ...currentCustomData,
        { label: buttonLabel, link: buttonLink, is_created: false },
      ]);
      setButtonLabel("");
      setButtonLink("");
      setShowCustomButtonCard(false);
    }
  };

  // Handle saving the entire card and hiding inputs
  const handleSaveDetails = async (updatedCustomData = null) => {
    setBtnIndex(null);

    if (title?.trim().length === 0) {
      toast.error("Title is required");
      return;
    }

    let customDataArr = [];

    if (updatedCustomData) {
      customDataArr = updatedCustomData.map((item) => {
        return {
          label: item.label,
          link: item.link,
        };
      });
    } else if (customData?.length > 0) {
      customDataArr = customData.map((item) => {
        return {
          label: item.label,
          link: item.link,
        };
      });
    }

    let response = await fetch(`${API_ENDPOINTS.updateLinkDataByAccessId}${accessId}`, {
      method: "PUT",
      body: JSON.stringify({
        video_title: title,
        description: description,
        custom_data: customDataArr,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    response = await response.json();
    setShowCard(false);
    handleApiResponse(response);
  };

  const handleConfirmation = (confirm) => {
    if (confirm) {
      const currentCustomData = customData || [];
      const data = [
        ...currentCustomData.slice(0, btnIndex),
        ...currentCustomData.slice(btnIndex + 1),
      ];
      setCustomData(data);
      setCustomData(data);
      handleSaveDetails(data);
    }
    setBtnIndex(null);
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    if (isOwner || (!showCard && title)) {
      setShowCtaSection(true);
    } else {
      setShowCtaSection(false);
    }
  }, [isOwner, showCard, title]);

  const handleShowCardSection = (activeCard) => {
    if (activeCardBtn === activeCard) {
      setActiveCardBtn("");
      setShowCardSection(false);
    } else {
      setActiveCardBtn(activeCard);
      setShowCardSection(true);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}>
        <Helmet>
          <meta property="og:title" content={videoData?.video_title} />
          <meta property="og:description" content={videoData?.username} />
          <meta property="og:url" content={`${baseUrl}/watch/${accessId}`} />
          <meta property="og:type" content="video.other" />
          <meta property="og:image:width" content="470" />
          <meta property="og:image:height" content="264" />
          <meta property="og:image" content={videoData?.aws_thumbnail_link} />
        </Helmet>
        {/* Header */}
        <AppBar position="static" sx={{ margin: 0, padding: 0 }}>
          <Toolbar
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
            className="padding-16"
          >
            <MDBox display="flex" alignItems="center" className="page-logo">
              <img src={MyIcon} alt="My Icon" style={{ width: "150px" }} />
            </MDBox>
          </Toolbar>
        </AppBar>
        {/* Video Player */}

        <MDBox
          sx={{
            maxHeight: "calc(100vh - 70px)",
            height: "calc(100vh - 70px)",
            padding: "16px",
          }}
        >
          <Grid
            container
            sx={{
              maxHeight: "100%",
              height: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {videoNotFound ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                    maxHeight: "calc(100vh - 100px)",
                    textAlign: "center",
                    padding: 3,
                  }}
                >
                  <img
                    src={NotFound}
                    alt="Video Not Found"
                    style={{ width: "400px", height: "auto", marginBottom: "20px" }}
                  />
                  <Typography variant="h4" gutterBottom>
                    Oops! Video Not Found
                  </Typography>
                  <Typography variant="body1" color="text.secondary" gutterBottom>
                    We couldn't find the video you were looking for. It may have been removed or the
                    link is broken.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => window.location.reload()}
                    sx={{ color: "white !important", marginTop: "20px" }}
                  >
                    Try Again
                  </Button>
                </Box>
              ) : videoSrc ? (
                <>
                  <MDBox
                    className="video-container"
                    sx={{
                      width: {
                        xs: isHorizentalView ? "95%" : "auto",
                        sm: isHorizentalView ? "95%" : "auto",
                        md: isHorizentalView ? "95%" : "auto",
                        lg: isHorizentalView ? (showCardSection ? "68%" : "72%") : "auto",
                        xl: isHorizentalView ? (showCardSection ? "68%" : "72%") : "auto",
                      },
                      height: {
                        xs: isHorizentalView ? "auto" : "66%", // Adjust height to auto for a responsive aspect ratio
                        sm: isHorizentalView ? "auto" : "66%",
                        md: isHorizentalView ? "auto" : "90%",
                        lg: isHorizentalView ? "auto" : "95%",
                        xl: isHorizentalView ? "auto" : "100%",
                      },
                      display: "flex",
                      justifyContent: "center",
                      maxHeight: { xs: "600px", lg: "auto" },
                      alignItems: "center",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <MDBox
                      className={`video-overlay ${isPlaying ? "hide" : ""}`}
                      key="video-overlay"
                    >
                      {videoData?.video_title && (
                        <Typography
                          variant="h4"
                          component="h2"
                          sx={{
                            fontSize: { xs: "1rem", sm: "1.2", md: "1.5rem" },
                          }}
                          className="video-overlay-title"
                        >
                          {videoData.video_title}
                        </Typography>
                      )}
                    </MDBox>
                    <video
                      ref={videoRef}
                      playsInline
                      controls
                      poster={thumbnailSrc ? thumbnailSrc : undefined} // Show poster only if aws_thumbnail_link is available
                    >
                      <source src={videoSrc} type="video/mp4" />
                    </video>
                  </MDBox>
                </>
              ) : null}

              {showCardSection && (
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "start",
                    ml: { xs: 0, md: 0, lg: 1.5 },
                  }}
                >
                  {!showCard &&
                    title && ( // Check if video_title has a value
                      <>
                        <Card
                          ref={cardRef}
                          sx={{
                            width: { xs: "94%", sm: "360px", md: "360px", lg: "360px" },
                            margin: { xs: "auto", md: "auto", lg: "0", xl: "0" },
                            p: 1,
                            position: "relative",
                            marginTop: { xs: "20px", sm: "20px", md: "20px", lg: "0px", xl: "0px" },
                            marginBottom: "20px",
                          }}
                        >
                          <IconButton
                            sx={{ position: "absolute", top: "3px", right: "3px" }}
                            onClick={handleCloseCard}
                          ></IconButton>
                          <CardContent>
                            <Typography variant="h5" mt={0.8}>
                              {title}
                            </Typography>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mt={1}
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 7, // Limit to 5 lines
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {description}
                            </MDTypography>

                            {ctaStatus && (
                              <Box sx={{ margin: "20px 0 8px 0", width: "100%" }}>
                                <MDButton
                                  variant="gradient"
                                  color="dark"
                                  sx={{
                                    display: "block",
                                    marginTop: "8px",
                                    position: "relative",
                                    width: "100%",
                                  }}
                                  onClick={goToCtaLink}
                                  fullWidth
                                >
                                  {ctaText && ctaText.length > 0 ? ctaText : "Book A Meeting"}
                                </MDButton>
                              </Box>
                            )}

                            {customData && customData.length > 0 && (
                              <Box sx={{ margin: "8px 0" }}>
                                {customData.map((button, index) => (
                                  <Button
                                    key={index}
                                    href={button.link}
                                    variant="contained"
                                    target="_blank"
                                    sx={{
                                      display: "block",
                                      marginTop: "8px",
                                      color: "white !important",
                                      position: "relative",
                                    }}
                                  >
                                    <Box sx={{ pointerEvents: "none", marginTop: "3px" }}>
                                      {" "}
                                      {/* Makes sure only icons are clickable */}
                                      {button.label}
                                    </Box>
                                    {isOwner && button?.is_created && (
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "30%",
                                          display: "flex",
                                          alignItems: "center",
                                          zIndex: 99999,
                                        }}
                                      >
                                        <Box
                                          mr={1}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowEditCtaModal(true);
                                            setBtnIndex(index);
                                          }}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <BiSolidPencil />
                                        </Box>
                                        <Box
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowConfirmationModal(true);
                                            setBtnIndex(index);
                                          }}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <MdDelete />
                                        </Box>
                                      </Box>
                                    )}
                                  </Button>
                                ))}
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </>
                    )}

                  {showCard && (
                    <Card
                      ref={cardRef}
                      sx={{
                        width: { xs: "94%", sm: "360px", md: "360px", lg: "360px" },
                        margin: { xs: "auto", md: "auto", lg: "0", xl: "0" },
                        p: 1,
                        position: "relative",
                        marginBottom: { xs: "40px", md: "40px", lg: "0px" },
                        marginTop: { xs: "20px", sm: "20px", md: "20px", lg: "0px", xl: "0px" },
                      }}
                    >
                      <IconButton
                        sx={{ position: "absolute", top: "3px", right: "3px" }}
                        onClick={handleCloseCard}
                      >
                        <CloseIcon />
                      </IconButton>
                      <CardContent>
                        {!showCustomButtonCard ? (
                          <>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              label="Title"
                              margin="normal"
                            />
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              placeholder="Add video description here... (optional)"
                              label="Description"
                              margin="normal"
                            />

                            {ctaStatus && (
                              <Box sx={{ margin: "20px 0 8px 0", width: "100%" }}>
                                <MDButton
                                  variant="gradient"
                                  color="dark"
                                  sx={{
                                    display: "block",
                                    marginTop: "8px",
                                    position: "relative",
                                    width: "100%",
                                  }}
                                  onClick={goToCtaLink}
                                  fullWidth
                                >
                                  {ctaText && ctaText.length > 0 ? ctaText : "Book A Meeting"}
                                </MDButton>
                              </Box>
                            )}

                            {/* Render Custom Buttons */}
                            {customData && customData.length > 0 && (
                              <Box sx={{ margin: "8px 0" }}>
                                {customData.map((button, index) => (
                                  <Button
                                    key={index}
                                    href={button.link}
                                    variant="contained"
                                    sx={{
                                      display: "block",
                                      marginTop: "8px",
                                      color: "white !important",
                                    }}
                                  >
                                    <Box sx={{ pointerEvents: "none", marginTop: "3px" }}>
                                      {" "}
                                      {/* Makes sure only icons are clickable */}
                                      {button.label}
                                    </Box>
                                    {isOwner && button?.is_created && (
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "30%",
                                          display: "flex",
                                          alignItems: "center",
                                          zIndex: 99999,
                                        }}
                                      >
                                        <Box
                                          mr={1}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowEditCtaModal(true);
                                            setBtnIndex(index);
                                          }}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <BiSolidPencil />
                                        </Box>
                                        <Box
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowConfirmationModal(true);
                                            setBtnIndex(index);
                                          }}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <MdDelete />
                                        </Box>
                                      </Box>
                                    )}
                                  </Button>
                                ))}
                              </Box>
                            )}

                            {/* Add Custom Button */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 2,
                                flexDirection: { xs: "column", sm: "column", md: "row" },
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{ color: "gray !important", width: { xs: "100%", sm: "auto" } }}
                                onClick={handleOpenCustomButtonCard}
                              >
                                + Create Custom Button
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  color: "white !important",
                                  width: { xs: "100%", sm: "auto" },
                                  mt: { xs: 1, sm: 1, md: 0 },
                                }}
                                onClick={() => handleSaveDetails()}
                              >
                                Save
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                              <IconButton onClick={() => setShowCustomButtonCard(false)}>
                                <ArrowBackIcon />
                              </IconButton>
                              <Typography id="modal-title" variant="h6" component="h2">
                                Create Custom Button
                              </Typography>
                            </Box>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Label"
                              value={buttonLabel}
                              onChange={(e) => setButtonLabel(e.target.value)}
                              placeholder="Click Me"
                              margin="normal"
                            />
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Link"
                              value={buttonLink}
                              onChange={(e) => setButtonLink(e.target.value)}
                              placeholder="https://www.website.com"
                              margin="normal"
                            />
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                              <Button
                                variant="outlined"
                                sx={{ color: "gray !important" }}
                                onClick={() => setShowCustomButtonCard(false)}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                sx={{ color: "white !important" }}
                                onClick={handleSaveCustomButton}
                              >
                                Add Button
                              </Button>
                            </Box>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  )}

                  {isOwner && !showCard && title && (
                    <Box
                      sx={{
                        textAlign: "center",
                        marginTop: "20px",
                        width: { xs: "94%", sm: "360px", md: "360px", lg: "360px" },
                        marginBottom: { xs: "40px", lg: "0px" },
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{ color: "white !important" }}
                        onClick={() => manageCtaClick()}
                      >
                        Manage CTA
                      </Button>
                    </Box>
                  )}
                </MDBox>
              )}

              {!isSmallScreen && (
                <Box
                  className="btn-container"
                  ml={1.5}
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // border:"2px solid red"
                  }}
                >
                  <MDBox
                    className={`watch-btn ${activeCardBtn === "manage-cta" ? "active" : ""}`}
                    onClick={() => handleShowCardSection("manage-cta")}
                  >
                    <MdManageHistory
                      style={{ fontSize: "27px" }}
                      color={activeCardBtn === "manage-cta" ? "white" : "black"}
                    />
                  </MDBox>
                </Box>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Box>

      <EditCtaModal
        setShowEditCtaModal={setShowEditCtaModal}
        showEditCtaModal={showEditCtaModal}
        btnIndex={btnIndex}
        customData={customData}
        setCustomData={setCustomData}
        handleSaveDetails={handleSaveDetails}
      />
      <ConfirmDeleteModal
        title="Delete Custom Button?"
        message="Are you sure you want to delete this custom button?"
        confirm={handleConfirmation}
        showDialog={showConfirmationModal}
      />
    </>
  );
};

export default WatchPage;
