import React from 'react';
import { useState, useEffect } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'; // for week and day views
import listPlugin from '@fullcalendar/list';
import MDBadgeDot from "components/MDBadgeDot";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { TroubleshootRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Divider from "@mui/material/Divider";
import MissedVideoCallOutlinedIcon from '@mui/icons-material/MissedVideoCallOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import Tooltip from '@mui/material/Tooltip';
import { toast } from "react-hot-toast";
import Alert from '@mui/material/Alert';
import { format, isSameDay } from 'date-fns';
import { formatInTimeZone,  toDate } from 'date-fns-tz';
import { formatDateTimeZone } from "lib/helper";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: ["90%", "70%", "53%", "42%"],
    p: 4,
};


dayjs.extend(utc);


const HandleAppointment = ({ appointment, handleClose, updateRefresh, setActiveStep }) => {

    const [values, setValues] = useState({
        appointmentTitle: '',
        description: '',
        meetingDetails: '',
    });

    const [errorMessage, setErrorMessage] = useState({
        appointmentTitle: '',
        description: '',
    });
    const [startDateTimeError, setStartDateTimeError] = useState("");
    const [endDateTimeError, setEndDateTimeError] = useState("");

    const [startDateTime, setStartDateTime] = useState(dayjs('2024-05-17T15:30'));
    const [endDateTime, setEndDateTime] = useState(dayjs('2024-05-17T15:30'));

    const validateFields = () => {
        if (values.appointmentTitle === '') {
            setErrorMessage((oldValues) => ({
                ...oldValues,
                appointmentTitle: 'Title is required',
            }))
        }
        if (values.description === '') {
            setErrorMessage((oldValues) => ({
                ...oldValues,
                description: 'Description is required',
            }))
        }
        if (startDateTime === '') {
            setStartDateTimeError("Start Date time is required");
        }
        if (endDateTime === '') {
            setEndDateTimeError("End Date time is required");
        }
    }

    const handleChange = (event) => {
        setValues((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }

    const backToDetail = () => {
        setActiveStep("view-detail");
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let convertedStartDay = formatDateTimeZone(startDateTime.$d, appointment?.time_zone);
        let convertedEndDay = formatDateTimeZone(endDateTime.$d, appointment?.time_zone);

        const payload = JSON.stringify({
            meetingDetails: values.meetingDetails,
            description: values.description,
            startDateTime: convertedStartDay,
            endDateTime: convertedEndDay,
            title: values.appointmentTitle,
            email: appointment?.email,
            eventId: appointment?.google_event_id
        });

        try {
            let response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/appointments/${appointment.appointment_id}`
                , {
                    method: "PUT",
                    body: payload,
                    headers: {
                        "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });

            if (response.ok) {
                toast.success("Appointment updated successfully");
                updateRefresh();
                handleClose();
            }
            else {
                let jsonData = await response.json();
                toast.error(jsonData.message || "Error saving Appointment")
            }
        }
        catch (err) {
        }
    }

    useEffect(() => {
        async function fetchData() {
            if ((Object.keys(appointment).length > 0)) {
                setValues(prevValues => ({
                    ...prevValues,
                    id: appointment.appointment_id,
                    appointmentTitle: appointment?.title,
                    description: appointment?.description,
                    meetingDetails: appointment?.meeting_details
                }));

                const formattedStartDate = format(new Date(appointment?.start_date_time), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
                 const formattedEndTime = format(new Date(appointment?.end_date_time), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

                const formatStartDateTime = formattedStartDate.slice(0, 16);
                const formatEndDateTime = formattedEndTime.slice(0, 16);
                const modifiedStartDateTime = await dayjs(formatStartDateTime);
                const modifiedEndDateTime = await dayjs(formatEndDateTime);
                setStartDateTime(modifiedStartDateTime);
                setEndDateTime(modifiedEndDateTime);
            }
        }

        fetchData();
    }, []);

    return (
        <>
            <MDBox mb={3} display="flex" flexDirection="row" justifyContent="space-between">
                <MDBox display="flex" alignItems="center">
                    <KeyboardBackspaceOutlinedIcon fontSize="medium" onClick={backToDetail} />
                    <MDTypography variant="h5" ml={1}>Update Appointment</MDTypography>
                </MDBox>
                <CloseIcon fontSize="medium" onClick={handleClose} />
            </MDBox>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>

                <MDBox mb={2}>
                    <TextField
                        error={values.appointmentTitle ? false : true}
                        fullWidth label="Title" name="appointmentTitle"
                        value={values.appointmentTitle}
                        onChange={handleChange} variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </MDBox>

                <MDBox mb={1} sx={{display: "flex", alignItems: "center", justifyContent:"right"}}>
                    {appointment?.time_zone && (<MDTypography variant="body2" fontWeight="regular">Time Zone: {appointment?.time_zone}</MDTypography>)}
                </MDBox>

                <MDBox mb={2}>
                    <Stack direction="column" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']} >
                                <DemoItem>
                                    <DateTimePicker
                                        value={startDateTime}
                                        onChange={(newValue) => {
                                            setStartDateTime(dayjs(newValue))
                                        }}
                                        label="Start Date Time"
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        renderInput={(params) => <TextField {...params}/>}
                                    />
                                </DemoItem>

                                <DemoItem>
                                    <DateTimePicker
                                        value={endDateTime}
                                        onChange={(newValue) => {
                                            setEndDateTime(dayjs(newValue))
                                        }}
                                        label="End Date Time"
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </DemoItem>

                            </DemoContainer>
                        </LocalizationProvider>
                    </Stack>
                </MDBox>

                <MDBox mb={3}>
                    <TextField fullWidth
                        id="standard-multiline-flexible"
                        label="Description" name="description"
                        multiline
                        defaultValue={values.description}
                        maxRows={3}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </MDBox>

                <MDBox mb={3}>
                    <TextField fullWidth
                        label="Meeting Details" name="meetingDetails"
                        multiline
                        defaultValue={values.meetingDetails}
                        maxRows={3}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </MDBox>

                <MDBox>
                    <MDBox display="flex" flexDirection="row" justifyContent="end" alignItems="flex-end" flexWrap="wrap">
                        <MDButton variant="gradient" color="dark" size="small" type="submit">
                            Save
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    );
}

const UserCard = ({ userDetail, organizer = null }) => {

    return (
        <MDBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" mt={1}>
            <MDBox >
                <Avatar sx={{ width: 30, height: 30 }}>{userDetail?.substring(0, 1)}</Avatar>
            </MDBox>
            <MDBox ml={1} display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
                <MDTypography variant="body2" fontWeight="regular">{userDetail}</MDTypography>
                <MDBox display="flex" justifyContent="flex-start" mr={"auto"}>
                    {userDetail == organizer && (<MDTypography variant="caption" fontWeight="regular">Organizer</MDTypography>)}
                </MDBox>
            </MDBox>
        </MDBox>
    );

}

const ManageAppointment = ({ events, appointMentDetail, setAppointMentDetail, updateRefresh }) => {
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState("view-detail");
    const [appointment, setAppointment] = useState({});
    const [timeString, setTimeString] = useState(null);
    const [attendees, setAttendees] = useState([]);
    const [userCards, setUserCards] = useState([]);
    const [copyText, setCopyText] = useState("Copy");

    const createUserCard = (users) => {
        users = [appointment?.email, ...users];
        setAttendees(users);
        if (users && users.length > 0) {
            let userCard = users.map((attendee, index) => {
                return <UserCard userDetail={attendee} organizer={appointment?.email} key={index} />
            })
            setUserCards(userCard);
        }
    }

    const handleDelete = async () => {
        try {
            const id = appointment?.appointment_id;
            let response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/appointments/${id}`
                , {
                    method: "DELETE",
                    headers: {
                        "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });

            if (response.ok) {
                toast.success('Appointment Deleted Successfully');
                updateRefresh();
                handleClose();
            }
            else {
                let jsonData = await response.json();
                toast.error(jsonData.message || "Appointment not deleted, try again later")
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setAppointMentDetail({});
        setActiveStep("view-detail");
    };

    function formatDate(startDateTime, endDateTime) {

        const zonedStartTime = new Date(startDateTime);
        const zonedEndTime = new Date(endDateTime);

        let formattedTime;

        if (isSameDay(zonedStartTime, zonedEndTime)) {
            // Format for the same day
            const formattedStartDate = format(zonedStartTime, "MMMM dd 'at' h:mm a");
            const formattedEndTime = format(zonedEndTime, "h:mm a");
            formattedTime = `${formattedStartDate} - ${formattedEndTime}`;
        } else {
            // Format for different days
            const formattedStartDate = format(zonedStartTime, "MMMM dd 'at' h:mm a");
            const formattedEndDate = format(zonedEndTime, "MMMM dd 'at' h:mm a");
            formattedTime = `${formattedStartDate} - ${formattedEndDate}`;
        }

        setTimeString(formattedTime);
    }

    const findDetail = () => {
        let id = appointMentDetail?.id;
        let detail = events.find((event) => event.appointment_id == id);
        setAppointment(detail);
    }

    const copyTextToClipboard = () => {
        if (appointment?.meet_link) {
            navigator.clipboard.writeText(appointment.meet_link)
                .then(() => {
                    setCopyText("Copied");
                })
                .catch((error) => {
                    setCopyText("Copy");
                });
        }
    };

    const handleClipboardChange = (event) => {
        navigator.clipboard.readText()
            .then((clipboardText) => {
                if (clipboardText === appointment?.meet_link) {
                    setCopyText("Copied");
                } else {
                    setCopyText("Copy");
                }
            })
            .catch((error) => {
                setCopyText("Copy");
            });
    };

    useEffect(() => {
        if ((Object.keys(appointMentDetail).length) && events) {
            handleOpen();
            findDetail();
        }
    }, [appointMentDetail])

    useEffect(() => {
        if ((Object.keys(appointment).length)) {
            formatDate(appointment?.start_date_time, appointment?.end_date_time, appointment?.time_zone);
            let users = appointment?.attendees.split(";");
            createUserCard(users);
        }
        document.addEventListener('copy', handleClipboardChange);
        return () => {
            document.removeEventListener('copy', handleClipboardChange);
        };
    }, [appointment, events])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={style}>
                {activeStep === "edit-detail" && (<HandleAppointment appointment={appointment} handleClose={handleClose} updateRefresh={updateRefresh} setActiveStep={setActiveStep} />)}

                {activeStep === "view-detail" && (
                    <MDBox style={{ maxHeight: '600px', overflowY: 'auto' }}>
                        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="start" m={0}>
                            <MDTypography variant="h4" style={{ overflow: 'hidden' }}>{appointment?.title}</MDTypography>
                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                <MDBox display="flex" alignItems="center">
                                    <EditOutlinedIcon fontSize="medium" onClick={() => setActiveStep("edit-detail")} />
                                </MDBox>
                                <MDBox ml={2} display="flex" alignItems="center">
                                    <DeleteOutlineOutlinedIcon fontSize="medium" onClick={handleDelete} />
                                </MDBox>

                                <MDBox ml={2} display="flex" alignItems="center">
                                    <CloseIcon fontSize="medium" ml={4} onClick={handleClose} />
                                </MDBox>
                            </MDBox>

                        </MDBox>
                        <MDTypography variant="body2" fontWeight="regular" p={0} m={0}>{timeString + (appointment?.time_zone ? ", " + appointment?.time_zone : "")}</MDTypography>
                        <MDTypography variant="body2" fontWeight="regular" p={0} m={0}>Weekly on Week Days</MDTypography>
                        <MDBox mt={0} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', overflowX: 'hidden, white-space: nowrap', textOverflow: 'ellipsis'}}>
                            <MDTypography variant="body2" fontWeight="regular" noWrap p={0} m={0}><strong>Meeting Details - </strong>{(appointment?.meeting_details?.length > 0) ? appointment?.meeting_details : "N/A" }</MDTypography>
                        </MDBox>
                        <Divider sx={{ margin: 0 }} light={true} />
                        <MDBox sx={{ minHeight: '300', overflowY: 'auto' }} style={{ maxHeight: '500px', overflowY: 'auto', padding: '10px' }}>
                            {appointment && appointment.meet_link ? (<MDBox >
                                <Grid container display={"flex"} alignItems={"center"} justifyContent={"start"} mt={2}>
                                    <Grid item sx={2} sm={1} lg={1} display={"flex"} alignItems={"center"}>
                                        <MissedVideoCallOutlinedIcon fontSize="medium" />
                                    </Grid>
                                    <Grid item sx={8} sm={10} lg={10}>
                                        <MDButton variant="gradient" color="info" size="small" type="button">
                                            Join with google meet
                                        </MDButton>
                                    </Grid>
                                    <Grid item sx={2} sm={1} lg={1} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                                        <Tooltip title={copyText} placement="top" arrow>
                                            <ContentCopyOutlinedIcon fontSize="medium" onClick={copyTextToClipboard} onFocus={() => setCopyText("Copy")} />
                                        </Tooltip>

                                    </Grid>
                                </Grid>
                                <Grid m={0} mt={0} py={0} container display={"flex"} alignItems={"center"} justifyContent={"start"}>
                                    <Grid item sx={2} sm={1} lg={1}>
                                    </Grid>
                                    <Grid item sx={8} sm={10} lg={10}>
                                        <MDBox mt={-1} p={0}>
                                            <MDTypography variant="caption" fontWeight="regular" p={0}>{appointment?.meet_link}</MDTypography>
                                        </MDBox>
                                    </Grid>
                                    <Grid item sx={2} sm={1} lg={1}>
                                    </Grid>
                                </Grid>


                            </MDBox>) : (
                                <MDBox mt={2} mx={0} px={0}>
                                        <Alert severity="error">Your calendar is not currently synced</Alert>
                                </MDBox>
                            )}
                            <MDBox mt={2}>
                                <Grid container display={"flex"} alignItems={"center"} justifyContent={"start"} mt={2}>
                                    <Grid item sx={2} sm={1} lg={1} display={"flex"} alignItems={"center"} >
                                        <PeopleOutlineOutlinedIcon fontSize="medium" />
                                    </Grid>
                                    <Grid item sx={8} sm={10} lg={10} display={"flex"} alignItems={"center"} >
                                        <MDBox p={0}>
                                            <MDTypography variant="body2" fontWeight="regular" p={0}>{attendees?.length + " guests"}</MDTypography>
                                        </MDBox>
                                    </Grid>
                                    <Grid item sx={2} sm={1} lg={1} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                                        <MDBox>
                                            <ChatBubbleOutlineOutlinedIcon fontSize="medium" />
                                        </MDBox>
                                        <MDBox ml={1}>
                                            <EmailOutlinedIcon fontSize="medium" />
                                        </MDBox>
                                    </Grid>
                                </Grid>

                                <Grid m={0} mt={0} py={0} container display={"flex"} alignItems={"center"} justifyContent={"start"}>
                                    <Grid item sx={2} sm={1} lg={1}>
                                    </Grid>
                                    <Grid item sx={8} sm={10} lg={10}>
                                        {userCards && (<MDBox>{userCards}</MDBox>)}
                                    </Grid>
                                    <Grid item sx={2} sm={1} lg={1}>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )}
            </Card>
        </Modal>
    );

}

const MyCalendar = ({ events, updateRefresh }) => {
    const [eventInfo, setEventInfo] = useState({});
    
    // Transform the events to the structure expected by FullCalendar
    const [calendarEvents, setCalendarEvents] = useState({});

    const handleEventClick = (info) => {
        setEventInfo(info?.event);
    }

    // Update the calendarEvents state whenever the events prop changes
    useEffect(() => {
        if(events && events.length > 0) {
            const calendarEventsArray = events.map(event => ({
                id: event.appointment_id,
                title: event.title,
                start: event.start_date_time,
                end: event.end_date_time,
                description: event.description,
            }));
            setCalendarEvents(calendarEventsArray);
        }
    }, [events]);

    // Set the initial date based on the first event's time zone
    const initialDate = new Date();
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    initialDate.toLocaleString('en-US', { timeZone: events[0]?.time_zone || clientTimeZone});

    return (
        <div>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                initialView="dayGridMonth"
                initialDate={initialDate}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }}
                weekends={true}
                dayMaxEvents={true}
                views={{ timeline: { eventMaxStack: 3 } }}
                events={calendarEvents} // Pass the updated calendarEvents state to FullCalendar
                eventClick={handleEventClick}
            />
            {eventInfo && (
                <ManageAppointment
                    events={events}
                    appointMentDetail={eventInfo}
                    setAppointMentDetail={setEventInfo}
                    updateRefresh={updateRefresh}
                />
            )}
        </div>
    );
};

export default MyCalendar;
