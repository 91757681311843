import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import MDInput from "components/MDInput";
import { forwardRef, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function MDDatePicker({ value,input, alwaysOpen, onChange, ...rest }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const flatpickrCalendars = document.querySelectorAll('.flatpickr-calendar');

    // flatpickrCalendars.forEach(calendar => {
    //   // Adjust the overall calendar size
    //   calendar.style.width = isSmallScreen ? '100%' : '350px';
    //   calendar.style.height = 'auto';

    //   // Adjust the individual day cell sizes
    //   const days = calendar.querySelectorAll('.flatpickr-day');
    //   days.forEach(day => {
    //     day.style.width = isSmallScreen ? '40px' : '60px'; // Smaller on small screens
    //     day.style.height = isSmallScreen ? '40px' : '60px';
    //     day.style.lineHeight = isSmallScreen ? '40px' : '60px'; // To vertically center the text
    //     day.style.fontSize = isSmallScreen ? '14px' : '20px'; // Smaller font on small screens
    //   });
    // });
  }, [isSmallScreen,value]); 

  // Define Flatpickr options
  const flatpickrOptions = {
    inline: alwaysOpen,
    mode: "single",
  //   onReady: (selectedDates, dateStr, instance) => {
  //     const today = instance.daysContainer.querySelector('.flatpickr-day.today');
  //     if (today) {
  //       today.classList.remove('today');
  //     }
  //     const tomorrow = new Date();
  // tomorrow.setDate(tomorrow.getDate() + 1);

  // // Set the defaultDate to tomorrow
  // instance.setDate(tomorrow, true);
  //   },
    onValueUpdate: (selectedDates, dateStr, instance) => {
  
      const today = instance.daysContainer.querySelector('.flatpickr-day.today');
      if (today) {
        today.classList.remove('today');
      }
    },
    ...rest.options, // Spread any other options passed to the component
  };

  const handleDateChange = (selectedDates, dateStr, instance) => {
    // Invoke the passed onChange handler from props, if it's provided
    if(onChange) {
      // If you only want the first selected date, pass selectedDates[0]
      onChange(selectedDates[0]);
    }
  };
  return (
    <Flatpickr
      {...rest}
      options={flatpickrOptions} 
      onChange={handleDateChange} 
      
    />
  );
}

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
  alwaysOpen: false, // By default, the picker is not always open
  onChange: undefined, // No onChange handler by default
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  alwaysOpen: PropTypes.bool, // Add propTypes for alwaysOpen
  onChange: PropTypes.func, // Add propTypes for the onChange handler
};

export default MDDatePicker;
