// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import { useSearchParams, useNavigate } from "react-router-dom";
// Overview page components
import Header from "layouts/pages/profile/components/Header";

import React, { useEffect, useContext, useState, forwardRef, Fragment } from "react";
import AuthContext from "context/Authcontext";
import toast from "react-hot-toast";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Overview() {
  const {
    verifyToken,
    getProfileDetails,
    profileDetails,
    profileImage,
    getMyAffiliateCode,
    affiliateCode,
    showModelToNewUser, 
    setShowModelToNewUser
  } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setShowModelToNewUser(false);
  };

  const redirectToSkoop = () => {
    window.open(process.env.REACT_APP_EXTENSION_URL, '_blank');
  }

  useEffect(() => {
    verifyToken();
    if(!profileDetails) {
      getProfileDetails();
    }
    getMyAffiliateCode();
    const sessionId = searchParams.get("session_id");

    if (sessionId) {
      const newWindow = window.open(process.env.REACT_APP_EXTENSION_URL, "_blank");
      setOpen(true);
    
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        toast('Pop-up to extension page was blocked. Please download it from download button at top right.');
      } else {
        // Pop-up was not blocked
        searchParams.delete('session_id');
        setSearchParams(searchParams);
      
        // Alternatively, to completely remove the query string:
        navigate(window.location.pathname, { replace: true });
      }
    }
  }, []);

  let info = {
    mobile: profileDetails.phone,
    email: profileDetails.email,
    location: profileDetails.location,
  };
  if (affiliateCode != "" && profileDetails?.role_id == 2) {
    info.affiliateCode = affiliateCode;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        name={profileDetails.full_name}
        title={profileDetails.title}
        profileImage={profileImage}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description={profileDetails.description}
                info={info}
                social={[
                  {
                    link: profileDetails.facebook_link,
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: profileDetails.twitter_link,
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: profileDetails.instagram_link,
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                  {
                    link: profileDetails.linkedin_link,
                    icon: <LinkedInIcon />,
                    color: "linkedin",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Fragment>
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth: { xs: '100%', sm: '80%', md: '70%', lg: '50%', xl: '40%' },
              height: "auto",
            },
          }}
        >
         <DialogTitle sx={{ m: 0, p: 2, textAlign: "center" }} id="customized-dialog-title">
          Skoop - Instant Video For LinkedIn & More
        </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-slide-description" sx={{fontSize:"20px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
              Streamline your LinkedIn and Gmail communications with Skoop App for creating
              personalized videos and voice memos. Enhance your LinkedIn DMs, posts, and Gmail
              responses with unique, practical multimedia messages.<br /> <br />
              To use the Skoop appointment booking system, please update your user preferences and sync with either Google or Microsoft Calendar to have the meeting link generated. You can update this from the account settings of the Skoop app.
            </DialogContentText>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 12,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogContent>
          <DialogActions>
            <MDBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ marginLeft: "10px", fontSize: "20px" }}
                onClick={redirectToSkoop}
              >
                <DownloadIcon sx={{ marginRight: "10px", transform: "scale(1.7)" }} /> Get Skoop App
              </MDButton>
            </MDBox>
          </DialogActions>
        </Dialog>
      </Fragment>
    </DashboardLayout>
  );
}

export default Overview;
