import BaseLayout from "layouts/pages/account/components/BaseLayout";
function RecordingPage() { 
    return (
        <div>
            <div className="warning"
                style={{
                    backgroundColor: '#ff0000',
                    color: '#fff',
                    padding: '10px',
                    fontSize: '18px',
                    textAlign: 'center' /* Center-align the text */
                }}
                >
                    Do not close this window
                </div>
                <div className="recording-message"
                style={{
                    backgroundColor: '#000',
                    color: '#fff',
                    padding: '20px',
                    fontSize: '24px',
                    textAlign: 'center', /* Center-align the text */
                    position: 'fixed', /* Fix it at the bottom */
                    left: '0', /* Stretch it across the screen */
                    right: '0', /* Stretch it across the screen */
                    bottom: '0', /* Anchor it to the bottom */
                }}
                > 
                You have initiated video recording. Please perform the actions you wish to record. 
            </div>
        </div>
    );
}

export default RecordingPage