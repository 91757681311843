import * as Yup from "yup";
import checkout from "../schemas/form";

const {
    formField: { firstName, lastName, role, email, subscription, password, apiUser, apiKey, licenceCount},
} = checkout;

const validations = [
    Yup.object().shape({
        [firstName.name]: Yup.string().min(1, firstName.invalidMsg).matches(/^\S*$/, 'First name must not contain spaces').required(firstName.errorMsg),
        [lastName.name]: Yup.string().trim().min(1, lastName.invalidMsg).matches(/^\S*$/, 'Last name must not contain spaces'),
        [email.name]: Yup.string().trim().required(email.errorMsg).email(email.invalidMsg),
        [role.name]: Yup.string().required(role.errorMsg).nullable(),
        [subscription.name]: Yup.string().required(subscription.errorMsg).nullable(),
        [password.name]: Yup.string()
        .notRequired()
        .when((value, schema) => value && value.length > 0 ? schema
          .matches(/[A-Z]/, 'Password must contain at least one uppercase character.')
          .matches(/[a-z]/, 'Password must contain at least one lowercase character.')
          .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/, 'Password must contain at least one special character.')
          .matches(/[0-9]/, 'Password must contain at least one numeric character.')
          .min(8, 'Password must be at least 8 characters long.')
          .max(16, 'Password must not be more than 16 characters long.')
        : schema),

        role: Yup.number(),
        [apiUser.name]: Yup.string().nullable()
            .when('role', {
                is: (val) => val === 4,  
                then: (schema) => 
                    schema
                        .required(apiUser.errorMsg)
                        .min(1, apiUser.invalidMsg),
            }),
        [apiKey.name]: Yup.string().nullable()
            .when('role', {
                is: (val) => val === 4,  
                then: (schema) => 
                    schema
                        .required(apiKey.errorMsg)
                        .length(64, apiKey.invalidMsg)
                        .matches(/^\S*$/, 'API Key must not contain spaces'),
            }),
        [licenceCount.name]: Yup.number().nullable()
            .when('role', {
                is: (val) => val === 4,
                then: (schema) => 
                    schema
                        .required(licenceCount.errorMsg)
                        .moreThan(1, 'Licence count must be greater than 1'),
            }),

    })
];

export default validations;
