import React, { useContext, useState } from "react";
import ToggleContainerButton from "../ToggleContainerButton";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import AuthContext from "context/Authcontext";

const ChangePasswordUsingOldPassword = ({ onToggle, isToggle }) => {
  const { changePasswordUsingOldPassword } = useContext(AuthContext);

  const [passwordValues, setPasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleOnChangeValue = (event) => {
    const { name, value } = event.target;
    setPasswordValues({ ...passwordValues, [name]: value });
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    if (passwordValues.newPassword !== passwordValues.confirmPassword) {
      toast.error("Password does not match!");
      return;
    } else {
      const isPasswordChanged = await changePasswordUsingOldPassword(passwordValues);
      if (isPasswordChanged) {
        setPasswordValues({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
  };

  return (
    <MDBox>
      <MDBox component="form" onSubmit={handleChangePassword}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Old Password"
              inputProps={{ type: "password" }}
              name="oldPassword"
              id="oldPassword"
              value={passwordValues.oldPassword}
              onChange={handleOnChangeValue}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="New Password"
              inputProps={{ type: "password" }}
              name="newPassword"
              id="newPassword"
              value={passwordValues.newPassword}
              onChange={handleOnChangeValue}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Confirm New Password"
              inputProps={{ type: "password" }}
              name="confirmPassword"
              id="confirmPassword"
              value={passwordValues.confirmPassword}
              onChange={handleOnChangeValue}
              required
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end" mt={3}>
            <ToggleContainerButton onToggle={onToggle} isToggle={isToggle} />
            <MDBox>
              <MDButton variant="gradient" color="dark" size="small" type="submit">
                Change password
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default ChangePasswordUsingOldPassword;
