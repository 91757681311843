// Tabs.js
import React from "react";
import MDBox from "components/MDBox";
import { LuPlus } from "react-icons/lu";

const DirectoryTabs = ({ activeTab, handleTabChange, folders, handleNewTab }) => (
  <div className="flex-row-container custom-video-nav">
    <MDBox
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      className="nav nav-tabs flex-nowrap"
    >
      {/* Existing tabs */}

      {/* Dynamically generated tabs from folders */}
      {folders.map((folder) => (
        <li
          className={`custom-video-nav-item ${activeTab === folder.directory_name ? "active" : ""}`}
          onClick={() => handleTabChange(folder.directory_name)}
        >
          {folder.directory_name}
        </li>
      ))}

      {/* New tab (folder) button */}
      <MDBox
        element="li"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        className="custom-video-nav-item"
      >
        <LuPlus color="white" onClick={handleNewTab} />
      </MDBox>
    </MDBox>
  </div>
);

export default DirectoryTabs;
