

import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React components
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import MsgInformation from "layouts/pages/account/messaging/components/MsgInformation";

function Chatresp() {

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);
  
  return (
    <BaseLayout stickyNavbar>
      <MsgInformation />
    </BaseLayout>
  );
}

export default Chatresp;
