import React, { useState, Fragment, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import API_ENDPOINTS from "apiConfig";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import Divider from "@mui/material/Divider";
import toast from "react-hot-toast";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  IconButton,
} from "@mui/material";

export default function EditCta({
  setShowEditCtaModal,
  showEditCtaModal,
  btnIndex,
  customData,
  setCustomData,
  handleSaveDetails,
}) {
  const [open, setOpen] = useState(showEditCtaModal);
  const [buttonLabel, setButtonLabel] = useState("");
  const [buttonLink, setButtonLink] = useState("");

  useEffect(() => {
    if (btnIndex !== null) {
      setButtonLabel(customData[btnIndex].label);
      setButtonLink(customData[btnIndex].link);
    }
  }, [customData, btnIndex]);

  const handleChange = (event) => {
    if (event.target.name === "label") {
      setButtonLabel(event.target.value);
    } else if (event.target.name === "link") {
      setButtonLink(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowEditCtaModal(false);
  };

  const handleSave = () => {
    if (buttonLabel?.trim().length === 0 || buttonLink?.trim().length === 0) {
      toast.error("Button label and link are required");
      return;
    }

    if (!buttonLink.startsWith("https")) {
      toast.error("Link should start with https");
      return;
    }
    const currentCustomData = customData || [];
    const data = [
      ...currentCustomData.slice(0, btnIndex),
      { label: buttonLabel, link: buttonLink },
      ...currentCustomData.slice(btnIndex + 1),
    ];
    setCustomData(data);
    handleSaveDetails(data);
    handleClose();
  };

  useEffect(() => {
    setOpen(showEditCtaModal);
  }, [showEditCtaModal]);

  return (
    <Fragment>
      <Dialog
        className="dialog-box"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: { xs: "100%", sm: "80%", md: "70%", lg: "40%", xl: "30%" },
            height: "auto",
            py: 0,
            my: 0,
          },
        }}
      >
        <MDBox p={2}>
          <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <MDTypography variant="h5" fontWeight="medium">
              Update Custom Button
            </MDTypography>
            <MDBox>
              <CloseIcon sx={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={handleClose} />
            </MDBox>
          </MDBox>
          <Divider
            className="divider-color"
            color="info"
            sx={{ bgcolor: "#344767", mb: 1.2, mt: 1 }}
          />
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Label"
                name="label"
                value={buttonLabel}
                onChange={handleChange}
                placeholder="Click Me"
                margin="normal"
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Link"
                name="link"
                value={buttonLink}
                onChange={handleChange}
                placeholder="https://www.website.com"
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  sx={{ color: "gray !important" }}
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <MDButton variant="contained" color="dark" onClick={handleSave}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
    </Fragment>
  );
}
