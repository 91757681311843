import React, { useState } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDMenu from "components/MDMenu";
import { FaFilter } from "react-icons/fa";

const FilterMenu = ({menuWidth, children}) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  return (
    <MDBox>
      <MDMenu
        openMenu={showFilterMenu}
        setOpenMenu={setShowFilterMenu}
        menuWidth={menuWidth}
        triggerElement={
          <>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDBox
                className="icon-button cursor-pointer"
                sx={{ ml:{xs:0, sm:0, md:0.5}, display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start"} }}
              >
                <FaFilter color="white" size={15} />
              </MDBox>
            </MDBox>
          </>
        }
      >
        <MDBox>
            {children}
        </MDBox>
      </MDMenu>
    </MDBox>
  );
};

export default FilterMenu;
