const logName =  [
    {
        label: "Auth",
        value: "auth",
    },
    {
        label: "Subscription",
        value: "subscription",
    }, 
    {
        label: "User",
        value: "user",
    },
    {
        label: "Affiliate",
        value: "affiliate",
    },
    {
        label: "Stripe",
        value: "stripe",
    },
    {
        label: "Video",
        value: "video",
    },
    {
        label: "Audio",
        value: "audio",
    },
    {
        label: "Video Thumbnail",
        value: "video_thumbnail",
    },
    {
        label: "Video Directory",
        value: "video_directory",
    },
    {
        label: "Vidyard Web Hook",
        value: "vidyard_web_hook",
    }
];
  
  const subjectType = [
    {
        label: "User data",
        value: "user_data",
    }, {
        label: "User subscriptions",
        value: "user_subscriptions",
    }, {
        label: "Affiliate coupons",
        value: "affiliate_coupons",
    },
    {
        label: "Stripe coupons",
        value: "stripe_coupons",
    }, {
        label: "Link data",
        value: "link_data",
    }, {
        label: "Users video directories",
        value: "users_video_directories",
    }, {
        label: "Vidyard dashboard video",
        value: "vidyard_dashboard_video",
    }, {
        label: "Views",
        value: "views",
    }
  ]
  
  const causerType = [
    {
        label: "Admin",
        value: "admin",
      },
      {
        label: "Affiliate",
        value: "affiliate",
      },
      {
        label: "User",
        value: "user",
      },
      {
        label: "Distributor",
        value: "distributor",
      },
  ]

  const options = [
    {
        type: "log_name", 
        option: logName
    }, 
    {
        type: "subject_type", 
        option: subjectType
    }, 
    {
        type: "causer_type", 
        option: causerType
    }
  ]


export { logName, subjectType, causerType, options }