import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import ChatPromptInformation from "../chatPrompts/components/ChatPromptsInformation";

const ChatPrompts = () => {

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);

  return (
    <BaseLayout>
        <ChatPromptInformation />
    </BaseLayout>
  );
};

export default ChatPrompts;
