import React, { createContext, useEffect, useState } from "react";
import { json, useLocation, useSearchParams } from "react-router-dom";
import API_ENDPOINTS from "../apiConfig";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ConstructionOutlined, FamilyRestroomTwoTone } from "@mui/icons-material";

const extensionURL = process.env.REACT_APP_EXTENSION_URL;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const [isAutheticated, setIsAutheticated] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [showClearSessionDialog, setShowClearSessionDialog] = useState(false);
  const [roleData, setRoleData] = useState([]);

  const [userPreferencesDetails, setUserPreferencesDetails] = useState({});
  const [profileImage, setProfileImage] = useState(
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgCIoKxXZss5sCIP3jHf-fWgBE9y5M3wKLRg&s"
  );
  const [loginDeviceList, setLoginDeviceList] = useState([]);
  const [fingerPrintId, setFingerPrintId] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [products, setProducts] = useState([]);
  const [isPro, setIsPro] = useState(false);
  const [newUser, setNewUser] = useState(false);

  const [gracePeriodCompleted, setGracePeriodCompleted] = useState(false);
  const [gracePeriod, setGracePeriod] = useState(0);
  const [social,setSocial]=useState(null)
  const [sessionDeletionToken, setSessionDeletionToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState(null);
  const [activeAction, setActiveAction] = useState(null);
  const [showModelToNewUser, setShowModelToNewUser] = useState(false);
  const [skoopUsername, setSkoopUsername] = useState(null);
  const [sessionUrl, setSessionUrl] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [couponValid, setCouponValid] = useState(false)
  const [couponInfo, setCouponInfo] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [couponType, setCouponType] = useState('');
  const [breadCrumbsText, setBreadCrumbsText] = useState("");
  const [userSettings, setUserSettings] = useState(null);
  const [ctaStatus, setCtaStatus] = useState(false);

  const navigate = useNavigate();

  function checkPathAndNavigate(pathsToCheck, navigateTo) {
    const currentPath = location.pathname;
    const shouldNavigate = pathsToCheck.some((path) => currentPath.includes(path));
    if (!shouldNavigate) {
      navigate(navigateTo);
    }
  }

  useEffect(() => {
    if (location.pathname.startsWith("/watch/")) {
      return;
    }
    if (location.pathname.includes("sign-in")) {
      verifyToken();
    }
    if (!isAutheticated) {
      checkPathAndNavigate(
        [
          "affiliate",
          "booking",
          "pages",
          "my-appointments",
          "privacypolicy",
          "termsofuse",
          "verify-email",
          "appsumo",
          "auth",
          "subscription",
          "payment",
          "activity-log"
        ],
        "/authentication/sign-in",
        "/authentication/register"
      );
    }
  }, []);

  const verifyToken = async (redirectTopages = true) => {
    try {
      const accessToken = JSON.parse(localStorage.getItem("skoopCrmAccessToken"));
      
      if(!accessToken) {
        return navigate("/authentication/sign-in");
      }
      const res = await fetch(API_ENDPOINTS.tokenStatus + "/crm", {
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const response = await res.json();
      
      if (res.ok) {
        if (response.isPro) {
          setIsPro(response.isPro);
        }
        setIsAutheticated(true);
        setGracePeriod(response.gracePeriod);
        setGracePeriodCompleted(response.gracePeriodCompleted);
      } 
      else{
        setIsAutheticated(false);
      }
      if(redirectTopages) {
        if(res.ok && !response.isPro) {
          if (!location.pathname.includes("/subscription")) {
            navigate("/subscription");
          }
        }
        else if(!res.ok) {
          if (!location.pathname.includes("/authentication/sign-in")) {
            navigate("/authentication/sign-in");
          }
        }
      }
      return res;
    } catch (err) {
      return { ok: false };
    }
  };

  const getProfileDetails = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.profileDetails, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        setProfileDetails(jsonResponse);
        setSkoopUsername(jsonResponse.email);
        localStorage.setItem("skoopUsername", jsonResponse.email);
        if(jsonResponse?.image_path?.startsWith("http")){
          setProfileImage(jsonResponse.image_path);
        }
        else if (!jsonResponse?.image_path?.startsWith("publics")) {
          setProfileImage(process.env.REACT_APP_BACKEND_URL + "/" + jsonResponse.image_path);
        }
        return jsonResponse;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  const getMyAffiliateCode = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.myaffiliateCode, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        setAffiliateCode(jsonResponse.code);
      }
    } catch (err) {
      console.error({ err });
    }
  };

  const handleLogout = async () => {
    const res = await fetch(API_ENDPOINTS.logout, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
      },
    });

    const isUserLoggedInFromExtension = localStorage.getItem("isUserLoggedInFromExtension");
    if(isUserLoggedInFromExtension == "false") {
      localStorage.setItem("userIsLoggedOut", true);
    }
    setIsAutheticated(false);
    localStorage.removeItem("skoopCrmAccessToken");
    localStorage.removeItem("social");
    localStorage.removeItem("sortingParams");
    localStorage.removeItem("filterByDate");
    localStorage.removeItem("filterSupportByDate");
    localStorage.removeItem("userFilteringParams");
    localStorage.removeItem("skoopUsername");
    localStorage.removeItem("activeSupportTab");
    localStorage.removeItem("activityLogFilteringParams");
    navigate("/authentication/sign-in");
    toast.success("Logged Out");
    setIsPro(false);
  };
  const deleteMyAllJwtSessions = async (username, password) => {
    try {
      const res = await fetch(API_ENDPOINTS.deleteAllJwtSessions, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      if (res.ok) {
        setShowClearSessionDialog(false);
        toast.success("Sessions deleted.Please login now");
        return res;
      }
    } catch (err) {
      return { ok: false };
    }
  };

  const deleteMyAllJwtSessionsBySocial = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(API_ENDPOINTS.jwtsocialsessions, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          'Session-Deletion-Token': sessionDeletionToken,
        },
      });
      const responseData = await res.json();
      if (res.ok) {
        setShowClearSessionDialog(false);
        setSocial(null);
        localStorage.removeItem("social");
        toast.success("Sessions deleted.Please login now");
      }
      else {
        throw new Error(responseData.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }


  const updateUserDetails = async (updateUserData) => {
    try {
      const response = await fetch(API_ENDPOINTS.updateUserDetails, {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateUserData),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        setProfileDetails(jsonResponse);
        toast.success("Profile updated successfully!");
      } else {
        throw new Error(jsonResponse.message);
      }
      return response;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updateProfilePicture = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("profileImage", file);

      try {
        const res = await fetch(API_ENDPOINTS.updateProfileImage, {
          method: "PATCH",
          body: formData, // Use FormData here
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            // Remove 'Content-Type' header when using FormData
          },
        });

        if (res.ok) {
          const jsonResponse = await res.json();
          setProfileImage(API_ENDPOINTS.backendUrl + "/" + jsonResponse.image_path); // Access image_path from JSON response
          toast.success("Profile Image Updated");
        } else {
          let responseData = await res.json();
          throw new Error(responseData.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const validatePassword = (password) => {
    if (!password) {
      return false;
    }
    // Password should contain minimum 8 characters, at least one uppercase letter, and one special character
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+,\-.;:'"<>=?/\|[\]{}~])(.{8,})$/;
    return passwordRegex.test(password);
  };

  const changePasswordUsingOldPassword = async (passwordValues) => {
    if (validatePassword(passwordValues.newPassword)) {
      try {
        const response = await fetch(API_ENDPOINTS.changePassword, {
          method: "PATCH",
          body: JSON.stringify({
            oldPassword: passwordValues.oldPassword,
            newPassword: passwordValues.newPassword,
          }),
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        if (response.ok) {
          toast.success("Password Changed!");
          return true;
        } else {
          toast.error("Password is not updated, Please try again!");
          return false;
        }
      } catch (err) {
        console.error("Password Not Updated, try Again:", err);
        return false;
      }
    } else {
      toast.error(
        "Password should contain minimum 8 characters, at least one uppercase letter, and one special character!"
      );
      return false;
    }
  };

  const sendOTP = async (email) => {
    try {
      const responseCode = await fetch(
        `${API_ENDPOINTS.getOtpForPasswordReset}?${new URLSearchParams({
          username: email,
        })}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      if (responseCode.ok) {
        toast.success("OTP has been sent!");
        return true;
      } else {
        toast.error("OTP has not been sent, Please try again!");
        console.error("OTP request failed with status:", responseCode.status);
        return false;
      }
    } catch (err) {
      console.error("Error occurred in getting the OTP for password reset", err);
      return false;
    }
  };

  const resetPassword = async (email, otp, newPassword) => {
    if (validatePassword(newPassword)) {
      try {
        const responseCode = await fetch(API_ENDPOINTS.resetPasswordUsingOtp, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            username: email,
            otp: otp,
            newPassword: newPassword,
          }),
        });
        if (responseCode.ok) {
          toast.success("Password Changed!");
          return true;
        } else {
          toast.error("Password is not updated, Please try again!");
          return false;
        }
      } catch (err) {
        console.error("could not make the call to reset password", err);
        return false;
      }
    } else {
      toast.error(
        "Password should contain minimum 8 characters, at least one uppercase letter, and one special character!"
      );
      return false;
    }
  };

  const getMultipleLogins = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.userDevices, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        if (jsonResponse != null) {
          setLoginDeviceList(jsonResponse);
        }
      }
    } catch (err) {
      console.error({ err });
    }
  };

  const deviceRemove = async (id, deviceId) => {
    try {
      let res = await fetch(`${API_ENDPOINTS.deviceRemove}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });
      if (res.ok) {
        if (deviceId == fingerPrintId) {
          handleLogout();
        }
      } else {
        console.error("Failed to delete device:", response.message);
      }
    } catch (err) {
      console.error("API call failed:", err);
    }
  };

  const getUserPreferencesDetial = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.getUserAppointmentPreferences, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        if (jsonResponse.length > 0) {
          setUserPreferencesDetails(jsonResponse[0]);
        }
      }
    } catch (err) {
      console.error({ err });
    }
  };
  const getMyReferredUser = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.myReferedUser, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();

        if (jsonResponse.length > 0) {
          setUserPreferencesDetails(jsonResponse[0]);
        }
      }
    } catch (err) {
      console.error({ err });
    }
  };
  const updatePayoutInfo = async (values) => {
    try {
      const responseCode = await fetch(API_ENDPOINTS.updatePayoutInfo, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
        body: JSON.stringify(values),
      });
      if (responseCode.ok) {
        toast.success("Payout Info Updated!");
        navigate("/pages/profile/profile-overview");
        return true;
      } else {
        toast.error("Payout Info not updated, Please try again!");
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const getRoles = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.getRoles, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        if (jsonResponse?.data.length > 0) {
          setRoleData(jsonResponse.data);
        }
      }
    } catch (err) {
      console.error({ err });
    }
  };
  const getDuePayments = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.affiliateDuePayments, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse;
      }
    } catch (err) {
      console.error({ err });
    }
  };
  const getPaidPayments = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.affiliatePaidPayments, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse;
      }
    } catch (err) {
      console.error({ err });
    }
  };
  const becomeAnAffiliate = async () => {
    try {
      const res = await fetch(API_ENDPOINTS.becomeAnAffiliate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });
      const response = await res.json();
      if (res.ok) {
        window.location.href = response.url;
        toast.success("Account updated to be an affiliate.");
        return res;
      }
    } catch (err) {
      return { ok: false };
    }
  };

  const handleSocialLogin = (type) => {
 
    try {
      if (type === 2) {
        // Google OAuth URL
        const GoogleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID
          }&redirect_uri=${encodeURIComponent(
            process.env.REACT_APP_GOOGLE_REDIRECT_URI
          )}&scope=profile%20email%20openid%20&access_type=offline`;

        window.location.href = GoogleAuthUrl;
      } else {
        // LinkedIn OAuth URL
        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID
          }&redirect_uri=${encodeURIComponent(
            process.env.REACT_APP_LINKEDIN_REDIRECT_URI
          )}&scope=openid%20profile%20email`;

        window.location.href = linkedInAuthUrl;
      }
    } catch (err) {
      toast.error("Something went wrong, please try again");
    }
  };
  const handleAuthCode = async (authCode, type) => {
    const url = type === 1 ? API_ENDPOINTS.linkedInLogIn : API_ENDPOINTS.googleLogIn;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let activePage = localStorage.getItem("pageType");
    let subscriptionType = localStorage.getItem("subscriptionType");
    const licenseKey = localStorage.getItem('license_key');
    if(!activePage) {
      activePage = "sign-in";
    }
    try {
      var response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          code: authCode,
          crm: true,
          timezone,
          type: activePage,
          subscriptionType,
          licenseKey
        }),
      });

      let result = await response.json();
      if (Number(response.status) === 200) {
        setIsAutheticated(true);
        localStorage.setItem("skoopCrmAccessToken", JSON.stringify(result.accessToken));
        localStorage.removeItem("userIsLoggedOut");
        setSkoopUsername(result.skoopUsername);
        localStorage.setItem("skoopUsername", result.skoopUsername);
        if (result.newUser) {
          setNewUser(true);
          setShowModelToNewUser(true);
        }
       
        if(result?.redirectToSignin && result.redirectToSignin === true) {
          setSkoopUsername(result.skoopUsername);
          localStorage.setItem("skoopUsername", result.skoopUsername);
          navigate('/authentication/sign-in');
        } else if(subscriptionType === 'appsumoLicence') {
          navigate(`/subscription?subscription=${subscriptionType}`);
        } else {
          navigate("/subscription");
        }
      } else if (response.status == 401) {
        setShowClearSessionDialog(true);
        setSessionDeletionToken(result.sessionDeletionToken);
        localStorage.removeItem("pageType");
        navigate(`/authentication/${activePage}`);
      } else if(response.status == 403) {
        toast.error(result.message, {
          className: "custom-toast",
        });
        navigate(`/authentication/${activePage}`);
      }
       else {
        toast.error("Could not sign in Correctly.", {
          className: "custom-toast",
        });
      }
      localStorage.removeItem("subscriptionType");
    } catch (err) {
      console.error(err);
      toast.error("Could not sign in");
    }
  };

  const handleCalendarSyncAuthCode = async (authCode, type) => {
    const url =  API_ENDPOINTS.createEvent;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const appointmentId = localStorage.getItem('appointmentId');
    try {
      var response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          code: authCode,
          crm: true,
          timezone,
          appointmentId: appointmentId,
          type
        }),
      });
      let result = await response.json();
      let token;
      if (Number(response.status) === 200) {
        token = result.accessToken;
        const pagePath = localStorage.getItem("pagePath");
        navigate(pagePath);
      } 
      localStorage.removeItem('appointmentId');
      localStorage.removeItem('pagePath');
    } catch (err) {
      console.error(err);
      toast.error("Could not sync calendar");
    }
  }

  const handleRegister = async (firstName, lastName, email, password,subscriptionType) => {
    try {
      setIsLoading(true)  
      if (!validatePassword(password)) {
        toast.error(
          'Password should contain minimum 8 characters, at least one uppercase letter, and one special character'
        )
        return
      }
    
      const licenseKey = localStorage.getItem('license_key');
      const toastId = toast.loading('Signing Up ')
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const body=JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        timezone: timezone,
        version: "crm",
        subscriptionType,
        crm:true,
        licenseKey
      })
    
      const res = await fetch(API_ENDPOINTS.signUp, {
        method: 'POST',
        body ,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const resjson = await res.json()
      if (res.ok) {
        localStorage.setItem("skoopCrmAccessToken", JSON.stringify(resjson.accessToken));
        toast.success('Success! A verification email has been sent to your inbox. Please confirm your email address to complete the login process.', {
          id: toastId,
        })
        setSkoopUsername(resjson.skoopUsername);
        localStorage.setItem("skoopUsername", resjson.skoopUsername);
        localStorage.removeItem("userIsLoggedOut");
        setGracePeriod(resjson.gracePeriod);
        if(resjson?.redirectToSignin && resjson.redirectToSignin === true) {
          navigate('/authentication/sign-in');
        } else if(subscriptionType) {
          navigate(`/subscription?subscription=${subscriptionType}`);
        } else {
          navigate(`/subscription`);
        }
          
        setShowModelToNewUser(true);
      } else {
        throw new Error(resjson.message || 'Email already exists');
      }
    } catch (err) {
      toast.dismiss()
      console.error(err)
      toast.error(err?.message ||'Something Went Wrong');
    } finally {
      setIsLoading(false)
    }
  }
  const getMySubscription = async () => {
    try {
      const res = await fetch(API_ENDPOINTS.mySubscriptions, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem('skoopCrmAccessToken')
          )}`,
        },
      })

      if (res.ok) {
        const subs = await res.json()
        return subs
      }
    } catch (err) {
      return { ok: false }
    }
  }
  const verifyCoupon = async (coupon) => {
    try {
      const res = await fetch(API_ENDPOINTS.validateCoupon + '/' + coupon, {
        method: 'GET',
      })

      return res
    } catch (err) {
      return { ok: false }
    }
  }

  const createAppSumoSubscription = async (code) => {
    const apiEndpoint = API_ENDPOINTS.redeemCoupon;
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        body: JSON.stringify({ code:code.trim() }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      const responsejson = await response.json()
      if (response.status == 200) {
        navigate("/pages/profile/profile-overview?session_id=Lifetime")
        toast(`Your coupon is redeemed Please download extension`)
      } 
      const newWindow = window.open(process.env.REACT_APP_EXTENSION_URL, "_blank");
    
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        toast('Pop-up to extension page was blocked. Please download it from download button at top right.');
      } 
    } catch (error) {
      console.error('There was an issue generating the coupons:', error);
      return { ok: false }
    }
  }

  const createSubscription = async (subscriptionData) => {
    const toastId = toast.loading('Processing Subscription...')
    
    if((couponType=='stripe' || couponType=='') && subscriptionData.plan_type !== 'freeTrial' && subscriptionData.plan_type !== 'appsumoLicence'){
      navigate('/payment')
    }
    
    try {
      let res = await fetch(API_ENDPOINTS.createSubscription, {
        method: 'POST',
        body: JSON.stringify(subscriptionData),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem('skoopCrmAccessToken')
          )}`,
        },
      })
      let response = await res.json()

      if (res.ok) {
        if((couponType=='stripe' || couponType=='' ) && subscriptionData.plan_type !== 'freeTrial'){
          window.location.href = response.url;
        }
        if(subscriptionData.plan_type === 'freeTrial' || response?.lifeTimeDealActivated) {
          navigate('/pages/profile/profile-overview')
        }
        if (response?.lifeTimeDealActivated) {
          toast.success(`${(response?.message) ? response?.message : 'Lifetime plan is applied'}`, {
            id: toastId,
          })
        } else if (subscriptionData.plan_type !== 'freeTrial') {
          toast.success('Please complete payment.', {
            id: toastId,
          })
        } else {
          toast.success(`${subscriptionData?.days}-day free trial plan is applied.`, {
            id: toastId,
          })
        }
        
        return response
      } else {
        toast.error('Your trial subscription is already finished', {
          id: toastId,
        })
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  const refreshAffiliateOnboardingUrl = async (accountId) => {

    try {
      let res = await fetch(API_ENDPOINTS.refreshOnbordingUrl, {
        method: 'POST',
        body: JSON.stringify({accountId}),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
         
        },
      })
      let response = await res.json()

      if (res.ok) {
        window.location.href = response.url;
        return response
      } else {
   
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  const getProducts = async () => {
    try {
      var response = await fetch(API_ENDPOINTS.getProducts, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem('skoopCrmAccessToken')
          )}`,
        },
      });

      if (response.ok) {
        let responseData = await response.json();
        if (responseData && responseData?.length >= 2) {

          let seen = new Set();
          let filteredProducts = responseData
            .filter((product) => {
              const productName = product.name.toLowerCase();
              if ((productName === 'monthly' || productName === 'yearly' || productName === 'weekly') && !seen.has(productName)) {
                seen.add(productName);
                return true;
              }
              return false;
            })
            .sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();
              if (nameA === 'monthly') return -1;
              if (nameB === 'monthly') return 1;
              return 0;
            });
          setProducts(filteredProducts);
        }
        else if (responseData && responseData?.length > 0 && responseData?.length < 2) {
          setProducts(responseData);
        }
      }

    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }
 
  async function deleteSubscriptions(id){
    let res = await fetch(API_ENDPOINTS.createSubscription+"/"+id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem('skoopCrmAccessToken')
        )}`,
      },
    })
    if(res.ok){
      toast.success("Subscription Deactivated")
      return res
    }
  }

  const calendarSync = async (type, redirectToNewTab = false) => {
    try{
       if(type === 'google') {
        const GoogleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID
          }&redirect_uri=${encodeURIComponent(
            process.env.REACT_APP_GOOGLE_REDIRECT_URI
          )}&scope=https://www.googleapis.com/auth/calendar&access_type=offline&prompt=consent`

          if(redirectToNewTab) {
            window.open(GoogleAuthUrl, "_blank");
          }
          else{
            window.location.href = GoogleAuthUrl;
          }

       }
       else if(type === 'calendly') {
      const CalendlyAuthUrl = `https://auth.calendly.com/oauth/authorize?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(
        redirectUri
      )}`

        window.location.href = CalendlyAuthUrl;
       }

       else if(type === 'microsoft') {
        const microsoftAuthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_MICROSOFT_REDIRECT_URI
      )}&scope=User.Read%20Calendars.ReadWrite%20offline_access&response_mode=query&state=12345&nonce=678910`
        
        if(redirectToNewTab) {
          window.open(microsoftAuthUrl, "_blank");
        }
        else{
          window.location.href = microsoftAuthUrl;
        }
       }
    }
    catch(error){
      console.error("Something went wrong", error);
    }
  }

  const handleCalendarAuthCode = async (authCode, type) => {
    try {
       
      let timeZone = localStorage.getItem('timezone');
      if(!timeZone || timeZone === 'null' || timeZone === 'undefined' || timeZone === null) {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      var response = await fetch(API_ENDPOINTS.syncCalendar, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem('skoopCrmAccessToken')
          )}`,
        },
        body: JSON.stringify({
          code: authCode,
          type,
          crm: true,
          time_zone: timeZone
        }),
      });

      let jsonResponse = await response.json();
      if (Number(response.status) === 200) {
        navigate('/my-appointments');
        toast.success(jsonResponse.message);
        setTimeout(() => {
          window.location.reload(true)
        }, 1000)
      } else {
        toast.error('Could not sync calendar')
      }
      localStorage.removeItem('timezone');
    } catch (err) {
      console.error(err)
      toast.error('Could not sign in')
    }
  }



  const getUserById = async (id) => {
    try {
      let response = await fetch(API_ENDPOINTS.getUserById + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem('skoopCrmAccessToken')
          )}`,
        },
      });
      let jsonResponse = await response.json();
      if (response.ok) {
        if (jsonResponse?.data?.image_path && !jsonResponse?.data?.image_path?.startsWith("publics")) {
              jsonResponse.data.image_path = process.env.REACT_APP_BACKEND_URL + "/" + jsonResponse.data.image_path;
        }
        return jsonResponse.data;  
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (error) {
      console.error("Something went wrong", error);
      return null;
    }
  }

  const recieveVerificationMail = async (email) => {
    try {
      if (!email || email?.length === 0 ) {
        toast.error("Email is required");
        return;
      }
      let response = await fetch(API_ENDPOINTS.recieveVerificationMail, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          email,
        }),
      });

      let jsonResponse = await response.json();
      if (response.ok) {
        toast.success(jsonResponse.message);
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteSubscription = async () => {
    try {
      let res = await fetch(API_ENDPOINTS.createSubscription, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem('skoopCrmAccessToken')
          )}`,
        },
      })
      if(res.ok) {
        return true;
      } else {
        return false;
      }

    } catch (error) {
        console.log(error);
        return false;
    }
  }

  const fetchMySettings = async () => {
    try {
      let response = await fetch(API_ENDPOINTS.getMyUserSettings, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      })
      let jsonResponse = await response.json();
      if(response.ok) {
          setUserSettings(jsonResponse);
          setCtaStatus(Boolean(jsonResponse?.ctainfo?.status))

          return jsonResponse;
      } else {
        return null;
      }
      
    } catch (error) {
      console.error('Error fetching user settings:', error);
      // Handle error (e.g., show notification)
      return null;
    }
  };


  return (
    <AuthContext.Provider
      value={{
        isAutheticated,
        setIsAutheticated,
        verifyToken,
        getProfileDetails,
        updateUserDetails,
        profileDetails,
        becomeAnAffiliate,
        updateProfilePicture,
        profileImage,
        changePasswordUsingOldPassword,
        sendOTP,
        resetPassword,
        getMultipleLogins,
        loginDeviceList,
        setFingerPrintId,
        fingerPrintId,
        deviceRemove,
        handleLogout,
        getUserPreferencesDetial,
        userPreferencesDetails,
        getMyAffiliateCode,
        affiliateCode,
        updatePayoutInfo,
        getMyReferredUser,
        getRoles,
        roleData,
        getPaidPayments,
        getDuePayments,
        deleteMyAllJwtSessions,
        showClearSessionDialog,
        setShowClearSessionDialog,
        deleteMyAllJwtSessions,
        deleteMyAllJwtSessionsBySocial,
        handleSocialLogin,
        handleAuthCode,
        handleRegister,
        getMySubscription,
        subscriptionType,
        setSubscriptionType,
        isPro,
        setIsPro,
        verifyCoupon,
        createSubscription,
        getProducts,
        products,
        setProducts,
        createAppSumoSubscription,
        gracePeriod,
        gracePeriodCompleted,
        setGracePeriod,
        setGracePeriodCompleted,
        social, 
        setSocial,
        sessionDeletionToken, 
        setSessionDeletionToken,
        isLoading, 
        setIsLoading,
        pageType, 
        setPageType,
        deleteSubscriptions,
        refreshAffiliateOnboardingUrl,
        calendarSync,
        handleCalendarAuthCode,
        activeAction, 
        setActiveAction,
        showModelToNewUser, 
        setShowModelToNewUser,
        handleCalendarSyncAuthCode,
        getUserById,
        recieveVerificationMail,
        skoopUsername, 
        setSkoopUsername,
        sessionUrl, setSessionUrl,couponCode, setCouponCode,couponValid, setCouponValid,couponInfo, setCouponInfo,showConfirmationModal, setShowConfirmationModal,couponType, setCouponType,
        breadCrumbsText, setBreadCrumbsText,
        deleteSubscription,
        fetchMySettings,
        ctaStatus, 
        setCtaStatus,
        userSettings, 
        setUserSettings
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
