/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";
import DataTable from "examples/Tables/DataTable";
import toast from "react-hot-toast";
import DefaultImage from "assets/crmimages/profile.jpg"
import AvatarCell from "components/Cell/AvatarCell";
import ContactForm from "../ContactForm";
import Link from '@mui/material/Link';

function ContactsView() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [bills, setBills] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [editing, setEditing] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = (data) => {
    console.log("data for edit is ", data);
    setEditing(data);
    setIsEditing(true);
  };

  const handleClose = () => {
    setEditing({});
    setIsEditing(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setBills([]);

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/SkoopCrmGetContactInfo`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        const resjson = await response.json();
        const data = resjson.Contacts;
        const columns = [];
        const keys = Object.keys(data[0]);

        columns.push({ Header: "Avatar", accessor: "Avatar", width: "55%" });
        columns.push({ Header: "LinkedIn Id", accessor: "linkedinId", width: "55%" });
        const fieldsNotToDisplay = ["id", "image", "enriched_email", "business_email", "linkedin_id", "username", "linkedIn_url", "website_url" ];
        keys.forEach((key) => {
          if (!fieldsNotToDisplay.includes(key)) {
            const newObj = {
              Header: key.split("_").join(" "),
              accessor: key,
              width: "55%",
            };

            if(key === "address") {
              columns.push({ Header: "LinkedIn Url", accessor: "linkedUrl", width: "55%" });
              columns.push({ Header: "Website Url", accessor: "WebsiteUrl", width: "55%" });
            }
            columns.push(newObj);
          }
        });
        columns.push({ Header: "Delete", accessor: "Delete", width: "55%" });
        columns.push({ Header: "Edit", accessor: "Edit", width: "55%" });

        const rows = data.map((item) => {

          let image;
          if(item && item?.image) {
            image = process.env.REACT_APP_BACKEND_URL + "/" + item?.image;
          }
          else {
            image = DefaultImage;
          }

          item.Avatar = (
            <AvatarCell image={image} color={"dark"} size="sm" />
          );

          item.linkedinId = item.linkedin_id

          item.linkedUrl = (
            <Link href={item.linkedIn_url} target="_blank" rel="noopener noreferrer">
              {item.linkedIn_url}
             </Link>
          );

          item.WebsiteUrl  = (
            <Link href={item.website_url} target="_blank" rel="noopener noreferrer">
              {item.website_url}
             </Link>
          );

          item.Delete = (
            <MDButton
              color="error"
              onClick={async () => {
                await handleDeleteCard(item.id);
              }}
            >
              Delete
            </MDButton>
          );
          item.Edit = (
            <MDButton color="info" onClick={() => handleEdit(item)}>
              Edit
            </MDButton>
          );
          return item;
        });

        if (keys.length > 0) {
          setDataTable({ columns: columns, rows: rows });
        } else {
          setDataTable({ columns: [], rows: [] });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    })();
  }, [refresh, editing]);

  const handleDeleteCard = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/skoopCrmDeleteContactInfo/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        toast.success(jsonResponse.message);
        setDataTable({ columns: [], rows: [] });
        setRefresh(true);
        setEditing({});
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      {!isEditing && (<Card id="delete-account">
        <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
          <MDBox ml={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Saved LinkedIn Contacts
            </MDTypography>
          </MDBox>
        </MDBox>
        {dataTable && dataTable?.rows?.length === 0 && (
          <MDBox pt={1} px={2}>
            <MDTypography variant="caption" fontWeight="medium">
              Your saved profile contact details will appear here when you save a linkedin profile.
            </MDTypography>
          </MDBox>
      )}
      <MDBox py={1} mx={3}>
        <DataTable
          table={dataTable}
          entriesPerPage={false}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
        />
      </MDBox>
    </Card>)}
    {isEditing && ( <ContactForm userDetail={editing} onClose={handleClose} />)}
    </>
  );
}

export default ContactsView;
