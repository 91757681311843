import React, { createContext, useEffect, useState } from "react";
import { paginationConstants } from "constants/pagination";

const TableContext = createContext();

const TableProvider = ({ children }) => {
  const [baseQuery, setBaseQuery] = useState("");
  const [pageSize, setPageSize] = useState(paginationConstants.perPage);
  const [isSearching, setIsSearching] = useState(false);

  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState({
    entries: ["5", "10", "15", "20", "25"],
  });
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [enteries, setEnteries] = useState({
    totalMediaCount: 0,
    totalItems: 0,
    totalPages: 0,
    enteriesStart: 0,
    enteriesEnd: 0,
  });

  const initializeTable = () => {
    setBaseQuery("");
    setPageSize(paginationConstants.perPage);
    setIsSearching(false);
    setSearch("");
    setEntriesPerPage({
      entries: ["5", "10", "15", "20", "25"],
    });
    setOrderBy("");
    setOrder("");
    setCurrentPage(1);
    setEnteries({
      totalMediaCount: 0,
      totalItems: 0,
      totalPages: 0,
      enteriesStart: 0,
      enteriesEnd: 0,
    });
  };
  return (
    <TableContext.Provider
      value={{
        baseQuery,
        setBaseQuery,
        pageSize,
        setPageSize,
        isSearching,
        setIsSearching,
        search,
        setSearch,
        entriesPerPage,
        setEntriesPerPage,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        currentPage,
        setCurrentPage,
        enteries,
        setEnteries,
        initializeTable,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export { TableProvider };

export default TableContext;
