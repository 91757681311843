/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Payment from "layouts/pages/affiliate/myPayments/components/Payment";
import React, { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";
import DataTable from "examples/Tables/DataTable";
import AuthContext from "context/Authcontext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function PaymentsView() {
  const [controller] = useMaterialUIController();

  const [dueTable, setDueTable] = useState({ columns: [], rows: [] });
  const [paidTable, setPaidTable] = useState({ columns: [], rows: [] });
  const [total, setTotal] = useState({ due: 0, paid: 0 });
  const { getPaidPayments, getDuePayments } = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    (async () => {
      try {
        const duePayments = await getDuePayments();

        const dueData = duePayments.duePayments;
        const columns = [];
        const keys = Object.keys(dueData[0]);
        keys.forEach((key) => {
          if (key !== "username" && key !== "id" && key !== "image") {
            const newObj = {
              Header: key,
              accessor: key,
              width: "55%",
            };
            columns.push(newObj);
          }
        });

        setDueTable({ columns: columns, rows: dueData });
        const paidPayments = await getPaidPayments();
        const paidData = paidPayments.paidPayments;
        const columns1 = [];
        const keys1 = Object.keys(paidData[0]);
        keys1.forEach((key) => {
          if (key !== "username" && key !== "id" && key !== "image") {
            const newObj = {
              Header: key,
              accessor: key,
              width: "55%",
            };
            columns1.push(newObj);
          }
        });

        setPaidTable({ columns: columns1, rows: paidData });
        setTotal({ due: duePayments.totalDueAmount, paid: paidPayments.totalPaidAmount });
      } catch (error) {
        console.error("Error:", error);
      }
    })();
  }, []);

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="medium">
          My payments
        </MDTypography>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Due Payments" />
          <Tab label="Paid" />
        </Tabs>
        <MDTypography variant="h6" fontWeight="medium">
          Total {value == 0 ? "Due" : "Paid"}: ${value == 0 ? total.due : total.paid}
        </MDTypography>
    
       
      </MDBox>
      <CustomTabPanel value={value} index={0}>
        <MDBox py={1}>
          <DataTable
            table={dueTable}
            entriesPerPage={true}
            showTotalEntries={true}
            isSorted={true}
            noEndBorder
          />
        </MDBox>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <MDBox py={1}>
          <DataTable
            table={paidTable}
            entriesPerPage={true}
            showTotalEntries={true}
            isSorted={true}
            noEndBorder
          />
        </MDBox>
      </CustomTabPanel>
    </Card>
  );
}

Payment.defaultProps = {
  noGutter: false,
};

Payment.propTypes = {
  data: PropTypes.object,
  noGutter: PropTypes.bool,
};

export default PaymentsView;
