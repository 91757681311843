import React, { useEffect, useState, useContext } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import API_ENDPOINTS from "apiConfig";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { WrapText } from "@mui/icons-material";
import toast from "react-hot-toast";

function Settings() {
  const [settingsDetail, setSettingsDetail] = useState(null);
  const [values, setValues] = useState(null)

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
    verifyToken();
  }, []);

  const updateAppConfig = async (event) => { 
    event.preventDefault();
    try{ 

        let emptyFields = settingsDetail.filter((appConfig) => {
            if(appConfig.value === "" || appConfig.value === null) {
                return true;
            }
        })

        if(settingsDetail.length === 0 || emptyFields.length > 0) {
            toast.error("Please fill all the fields");
            return
        }

        const response = await fetch(API_ENDPOINTS.updateAppConfig, {
            method: "PUT", 
            headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(settingsDetail)
        });

        const config = await response.json();
        if(response.ok) {
            toast.success(config.message);
            getAppConfigData();
        }
        else {
            throw new Error(config.message);
        }
    } catch(error) {
        console.log(error);
        toast.error(error.message);
    }
  }

  const getAppConfigData = async () => {
    const response = await fetch(API_ENDPOINTS.getAppConfig, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const config = await response.json();
    setSettingsDetail(config.data);
  };

  const handleOnChangeValue = (event) => {
    const { name, value, id } = event.target;
    
    const updatedSettings = settingsDetail.map((element) =>
        element.label === name ? { ...element, value: value } : element
      );

      setSettingsDetail(updatedSettings);
  };

  useEffect(() => {
    getAppConfigData();
  }, []);

  return (
    <BaseLayout>
      <Card id="setting">
        <MDBox p={2}>
          <MDBox display="flex" alignItems="center" justifyContent="space-between">
            <MDTypography variant="h6" fontWeight="medium">
              Settings
            </MDTypography>
          </MDBox>
          {settingsDetail && (
            <MDBox mt={3}>
              {settingsDetail.map(({ id, label, value }) => {
                const lableName = label.split("_").join(" ");
                return (
                  <>
                    <Grid
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                      mt={1}
                      spacing={2}
                    >
                      <Grid item xs={5} md={5}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          textTransform="capitalize"
                          sx={{ display: "flex", alignItems: "center" }}
                          className="word-break-wrap"
                        >
                          {lableName}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={7} md={5}>
                        <MDInput
                          fullWidth
                          inputProps={{ type: "text" }}
                          name={label}
                          id={label + "_" + id}
                          value={value}
                          onChange={handleOnChangeValue}
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              })}
              <Grid container sx={{ display: "flex", alignItems: "center" }} mt={3} mb={1}>
                <Grid
                  item
                  xs={12} md={10}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                >
                  <MDButton variant="gradient" color="dark" size="small" onClick={updateAppConfig}>
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BaseLayout>
  );
}

export default Settings;
