import * as Yup from "yup";
import checkout from "../schemas/form";

const {
    formField: { name, description, price },
} = checkout;

const validations = [
    Yup.object().shape({
        [name.name]: Yup.string().trim().min(3, name.invalidMsg).required(name.errorMsg),
        [description.name]: Yup.string().trim().min(3, description.invalidMsg).required(description.errorMsg),
        [price.name]: Yup.number().min(0, price.invalidMsg).required(price.errorMsg).nullable(),
    })
];

export default validations;
