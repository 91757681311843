import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import API_ENDPOINTS from "apiConfig";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultCell from "components/Cell/DefaultCell";
import {
  capitalizeFirstLetter,
  formatDateInDMY,
} from "lib/helper";
import AuthContext from "context/Authcontext";

import TableContext from "context/Tablecontext";
import MDTable from "components/MDTables";
import AvatarCell from "components/Cell/AvatarCell";

const UserList = ({ couponId }) => {
  const [refresh, setRefresh] = useState(true);
  const [userList, setUserList] = useState([]);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const { profileDetails } = useContext(AuthContext);
  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
  } = useContext(TableContext);
  const [canSearch, setCanSearch] = useState(true);

  const PlanCell = ({ subscriptionPlan }) => {
    return (
      <MDBox sx={{ display: "flex", alignItems: "center" }}>
        {subscriptionPlan?.length > 0 ? (
          <>
            <MDTypography variant="caption" fontWeight="medium" color="secondary">
              {subscriptionPlan}
            </MDTypography>
          </>
        ) : (
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            N/A
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const StatusCell = ({ subscriptionStatus }) => {
    return (
      <MDBox sx={{ display: "flex", alignItems: "center" }}>
        {subscriptionStatus?.length > 0 ? (
          <>
            <MDBox>
              <MDTypography variant="caption" fontWeight="medium" color="text">
                {subscriptionStatus}
              </MDTypography>
            </MDBox>
          </>
        ) : (
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            N/A
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const loadData = (users) => {
    if (users && users.length > 0) {
      let columns = [
        {
          Header: "subscription id",
          accessor: "subscription_id",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "user",
          accessor: "full_name",
          Cell: ({ value: [name, image] }) => <AvatarCell image={image} name={name} />,
        },
        {
          Header: "applied at",
          accessor: "subscription_start_date",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "plan",
          accessor: "subscription_plan",
          Cell: ({ value }) => <PlanCell subscriptionPlan={value} />,
        },
        {
          Header: "status",
          accessor: "subscription_status",
          Cell: ({ value }) => <StatusCell subscriptionStatus={value} />,
        },
      ];

      let rows = users.map((user) => {

        const status = user?.subscription_status
          ? capitalizeFirstLetter(user?.subscription_status)
          : "N/A";
        const plan = user?.subscription_plan
          ? capitalizeFirstLetter(user?.subscription_plan)
          : "N/A";
        const appliedAt = user?.subscription_start_date
          ? formatDateInDMY(user?.subscription_start_date)
          : "N/A";

        let imagePath = null;
        if (user?.image_path) {
          if (user.image_path.startsWith("http")) {
            imagePath = user.image_path;
          } else if (!user.image_path.startsWith("publics")) {
            imagePath = process.env.REACT_APP_BACKEND_URL + "/" + user.image_path;
          }
        }

        const fullName = user?.full_name ? capitalizeFirstLetter(user?.full_name) : "N/A";

        return {
          subscription_id: user?.subscription_id || "N/A",
          full_name: [fullName, imagePath],
          subscription_start_date: appliedAt,
          subscription_plan: plan,
          subscription_status: status,
        };
      });

      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const getUserList = async () => {
    let query = "";
    query += `page=${currentPage}&limit=${pageSize}`;

    if (search) {
      query += `&search=${search}`;
    }

    if (orderBy && order) {
      let sortOrder = order === "asc" ? "ASC" : "DESC";
      query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
    }

    let response = await fetch(
      `${API_ENDPOINTS.getUsersByCouponId}${couponId}/users` + `?${query}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    if (response.ok) {
      const jsonResponse = await response.json();
      setUserList(jsonResponse.items);
      loadData(jsonResponse.items);

      const totalPage = Math.ceil(jsonResponse.totalItems / pageSize);
      const startIndex = (currentPage - 1) * pageSize + 1;
      const endIndex = Math.min(currentPage * pageSize, jsonResponse.totalItems);
      setEnteries({
        ...enteries,
        totalItems: jsonResponse.totalItems,
        totalPages: jsonResponse.totalPages,
        totalMediaCount: totalPage,
        enteriesStart: startIndex,
        enteriesEnd: endIndex,
      });
    }
  };

  const handleStopRefresh = () => {
    setRefresh(false);
  };

  useEffect(() => {
    if (couponId && refresh) {
      initializeTable();
      getUserList();
      handleStopRefresh();
    }
  }, [couponId]);

  useEffect(() => {
    if (!refresh) {
      getUserList();
    }
  }, [pageSize, currentPage, orderBy, order]);

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }

    const timeoutId = setTimeout(() => {
      if (!refresh) {
        getUserList();
      }
    }, 500);
    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [search]);

  return (
    <MDBox>
      <MDTable table={dataTable} canSearch={canSearch} />
    </MDBox>
  );
};

export default UserList;
