import { useContext } from "react";
import AuthContext from "context/Authcontext";
import UserRegistration from "components/UserRegistration"
function RegisterAndRedeem() {
  const { handleRegister} = useContext(AuthContext);

  return (
    <UserRegistration handleRegistration={handleRegister}
      cardTitle="Redeem your AppSumo Coupon"
      submitButtonTitle="Redeem with email"
      googleButtonTitle="Redeem with Google"
      linkedInButtonTitle="Redeem with LinkedIn"
    /> 
  );
}

export default RegisterAndRedeem;
