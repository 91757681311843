// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import Footer from "examples/Footer";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import MDTypography from "components/MDTypography";
import Link from "@mui/material/Link";
import MDButton from "components/MDButton";
import logo from "assets/crmimages/skoop-logo-white.svg";

import { position } from "stylis";
function CoverLayout({ coverHeight, image, children, booking, buttonText }) {
  return (
    <>
      {" "}
      <MDBox className="page-container">
      <AppBar position="static" color="info">
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Link href="https://skoopapp.com/" style={{ textDecoration: 'none' }} sx={{ flex: 1 }}>
            <IconButton edge="start" color="inherit" aria-label="menu" >
              <img src={logo} alt="logo" className="skoop-app-icon" />
            </IconButton>
          </Link>
          <MDTypography sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
            variant="h4"
            fontWeight="medium"
            color="white"
          >
            Instant Video for LinkedIn & More
          </MDTypography>
          {buttonText && (
            <MDBox
              sx={{
                flex: { xs: 1, sm: 1, md: 1, lg: 1 },
                display: "flex",
                justifyContent: "flex-end",
              }}>
                <Link href={ buttonText === "Sign Up" ? "/authentication/register" : "/authentication/sign-in"}>
                <MDButton color="inherit">{buttonText}</MDButton>
              </Link>
          </MDBox>)}
        </Toolbar>
      </AppBar>
        <MDBox
          width="calc(100% - 2rem)"
          borderRadius="xl"
          mx={2}
          my={2}
          pt={6}
          pb={28}
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.4),
                rgba(gradients.dark.state, 0.4)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <MDBox mt={{ xs: -30, lg: -30 }} px={1} width="calc(100% - 2rem)" mx="auto">
          <Grid container justifyContent="center" alignItems="center">
          <MDBox py={3} mt={10} width="70vw">
              <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={6} xl={booking ? 20 : 6}>
              {children}
            </Grid>
            </Grid>
          </MDBox>
          </Grid>
        </MDBox>
        {!booking && <Footer/>}
      </MDBox>
    </>
  );
}

// Setting default props for the CoverLayout
CoverLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
