import React, { useState, Fragment, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import API_ENDPOINTS from "apiConfig";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import Divider from "@mui/material/Divider";
import toast from "react-hot-toast";

export default function RenameVideo({
  video,
  showRenameVideo,
  setShowRenameVideo,
  setRefresh,
  closeMenu,
}) {
  const [open, setOpen] = useState(false);
  const [VideoName, setVideoName] = useState("");

  const handleClose = () => {
    setOpen(false);
    setShowRenameVideo(false);
    setVideoName("");
    closeMenu();
  };

  const handleRenameSave = async () => {
    try {
      setRefresh(false);
      if (VideoName === "") {
        toast.error("Video name is required for rename");
        return;
      }
      if (video?.video_title?.trim() === VideoName.trim()) {
        toast.error("Video name is same as old name");
        return;
      }
      const id = parseInt(video?.id);
      const response = await fetch(API_ENDPOINTS.renameVideo + `/${id}`, {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          newTitle: VideoName,
        }),
      });

      if (response.ok) {
        toast.success("Video renamed successfully");
        handleClose();
        setRefresh(true);
      } else {
        throw new Error("Failed to rename video");
      }
    } catch (error) {
      toast.error(error?.message || "Failed to rename video.");
    }
  };

  const handleOnChangeValue = (event) => {
    setVideoName(event.target.value);
  };
  useEffect(() => {
    setVideoName(video?.video_title);
  }, [video]);
  useEffect(() => {
    setOpen(showRenameVideo);
  }, [showRenameVideo]);

  return (
    <Fragment>
      <Dialog
        className="dialog-box"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: { xs: "100%", sm: "80%", md: "70%", lg: "40%", xl: "30%" },
            height: "auto",
          },
        }}
      >
        <MDBox p={2}>
          <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <MDTypography variant="h5" fontWeight="medium">
              Rename Video
            </MDTypography>
            <MDBox>
              <CloseIcon sx={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={handleClose} />
            </MDBox>
          </MDBox>
          <Divider
            className="divider-color"
            color="info"
            sx={{ bgcolor: "#344767", mb: 3, mt: 1 }}
          />
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item xs={8}>
              <MDInput
                fullWidth
                label="Video Name"
                name="videoName"
                value={VideoName}
                onChange={handleOnChangeValue}
                required
              />
            </Grid>

            <Grid item xs={4}>
              <MDButton variant="contained" color="dark" fullWidth onClick={handleRenameSave}>
                Save
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
    </Fragment>
  );
}
