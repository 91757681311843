export const questions = [
  {
    id: 1,
    title: "What is personal information?",
    description:
      "Personal information is information that is about you as an identifiable individual. If you are a business customer it does not include your name and business contact information. Aggregated, statistical or de-identified information is not personal information. Information generated through your use of our websites or watching videos will not be personal information unless we can combine it with other information that would identify you.",
  },
  {
    id: 2,
    title: "When does this Privacy Policy apply?",
    description:
      "Skoop is a video platform service that helps businesses transform communications and sales through the use of online video. This Privacy Policy explains how we collect, use and disclose your personal information in the course of our business. This Privacy Policy also covers the information we may collect offline, such as when you telephone us, visit us in person or interact with us at an event, for example enter a contest or speak to us at a trade show. When our customers collect your personal information through our video platform service in the course of their business, their use of your personal information is governed by their privacy policy, not ours.",
  },
  {
    id: 3,
    title: "What are the Skoop Websites and Services?",
    description:
      "The Skoop Websites include our website www.Skoop.com, any subdomains, and any other Buildscale websites. Our Services include downloadable computer software and any products or services purchased or otherwise made available from Skoop Websites. Skoop's Services include video content libraries and distribution platforms that third parties can use to show viewers their videos.",
  },
  {
    id: 4,
    title: "How do we obtain your agreement?",
    description:
      "By using the Skoop Websites or the Skoop Services you agree to the practices outlined in this Privacy Policy. If you do not agree, you should not interact with any Skoop Websites or use any of the Services, or otherwise provide Skoop with your personal information.",
  },
  {
    id: 5,
    title: "When and how do we collect directly from you?",
    description:
      "In most cases, we collect information about you when you give it directly to us. For example, we you create an account or purchase our services. We also collect information when you call us, visit our offices or interact with us at an event. Also, like many websites and technologies, we automatically collect technical data when you visit and interact with Skoop Websites and Skoop Services.",
  },
  {
    id: 6,
    title: "What type of information do we collect?",
    description:
      "We collect a variety of data including your contact information, payment details, business information, user uploaded content, and communications you have with our team. The precise types of information that is collected will depend on your interaction with Skoop, Skoop Websites or the Skoop Services. We also collect information about your interaction with our Services, including the resources that you access, pages viewed, how much time you spent on a page, and how you reached our Website. We also log the details of your visits to our Services and certain information generated in the course of using our Services, such as mouse movements, clicks, page visits, text entered, how long you spent on a page, and other details of your actions on our website. We may share this information with trusted third-parties to analyze and organize any collected data on our behalf.",
  },
  {
    id: 7,
    title: "Does Skoop use cookies and other technologies to collect technical data?",
    description:
      "When you visit the Skoop Websites, we receive data from your devices, including your IP address. This technical data generally will not identify you as a visitor. However, in some instances, this technical data may be linked to you if you have given us identifying information about you.",
  },
  {
    id: 8,
    title: "What are business analytics and aggregated data?",
    description:
      "Business analytics involves the analysis of data to understand trends and insights about how individuals use the Skoop Websites and the Skoop Services. Aggregated data means data that is combined so that you are no longer identifiable. We use analytics and aggregated data to improve our business.",
  },
  {
    id: 9,
    title: "For what purpose do we collect your information?",
    description:
      "We use your personal information to communicate with you, to provide you with assistance, products and services that you request, and to adapt the Skoop Websites and the Skoop Services to your interests. We also use your personal information to understand how you use the Skoop Websites and the Skoop Services to protect and improve our business.",
  },
  {
    id: 10,
    title: "Tell me about uploaded content and third-party information",
    description:
      "When you post audio-visual materials to your website or to a third party website, we collect information about your account and the use of the content that you post. If you use our invitation features, such as chats and sending invitations to friends, Skoop may collect some information from them.",
  },
  {
    id: 11,
    title: "Tell me about advertising practices",
    description:
      "We use technologies and/or belong to marketing programs in order to provide visitors, users and customers with advertising targeted to their interests.",
  },
  {
    id: 12,
    title: "When does Skoop share or disclose personal information?",
    description:
      "Sometimes, Skoop shares or discloses your personal information and technical data to outside parties, such as our partners and service providers. For example, Skoop shares information with our service providers who assist Skoop with marketing, data hosting and credit card payments. Skoop may disclose personal information due to legal requirements or enforcement, such as defending against legal claims or to investigate and prevent illegal activities. We may also disclose viewer data to our customers, such as how many times a video was watched and location of the viewers.",
  },
  {
    id: 13,
    title: "Information collected by our customers using Skoop services",
    description:
      "We may provide customers with certain identifiable usage information directly related to the videos and other content that they make available through the Skoop Services. This includes information about who specifically viewed the content and how many times a particular content was watched by a particular viewer. Viewers should be aware that customers may share such usage information with other third parties (see the next section). We are not responsible for the use of any information by such customers and their third party services. With that said, Skoop requires all customer to use the Skoop Services in line with applicable laws.",
  },
  {
    id: 14,
    title: "Use of Third Party Integrations by our Customers",
    description:
      "Our customers may integrate the Skoop Services with other third party applications and platforms. In doing so, our customers may transfer your personal information from our platform to the third party. Skoop is not responsible for the transfer of such information by our customers.",
  },
  {
    id: 15,
    title: "How does Skoop keep personal information safe?",
    description:
      "The security of your personal information is important to us. Although we cannot guarantee 100% security, we use reasonably appropriate security measures to protect your personal information against unauthorized use, access, modification, destruction or disclosure. We require our third party service providers to use similar safeguards to those we use.",
  },
  {
    id: 16,
    title: "How long will personal information be retained?",
    description:
      "Skoop retains personal information only as long as necessary to serve the identified purposes for which it was collected, subject to legal requirements.",
  },
];
