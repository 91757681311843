import React, { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDMenu from "components/MDMenu";
import { FaFilter } from "react-icons/fa";

const FilterMenu = ({ filteringParams, setFilteringParams}) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [startDate, setStartDate] = useState(dayjs("2024-05-17"));
  const [endDate, setEndDate] = useState(dayjs("2024-05-17"));

  const extractDate = (dateTime) => {
    const dayjsDate = dayjs(dateTime);
    const formattedDate = dayjsDate.format("YYYY-MM-DD");
    return formattedDate;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formatteStartdDate = extractDate(startDate);
    const formattedEndDate = extractDate(endDate);
    setFilteringParams((prevParams) => ({ ...prevParams, startDate: formatteStartdDate, endDate: formattedEndDate, 
    }));
    setShowFilterMenu(false);
  };

  useEffect(() => {
    if (filteringParams) {
      if (filteringParams && filteringParams?.startDate !== "" && filteringParams?.endDate !== "") {
        setStartDate(dayjs(filteringParams?.startDate));
        setEndDate(dayjs(filteringParams?.endDate));
      } else {
        const date = new Date();
        const pastDate = new Date();
        pastDate.setDate(date.getDate() - 7);
        setStartDate(dayjs(pastDate));
        setEndDate(dayjs(date));
      }
    }
  }, [filteringParams, showFilterMenu]);

  return (
    <MDBox>
      <MDMenu
        key={filteringParams}
        openMenu={showFilterMenu}
        setOpenMenu={setShowFilterMenu}
        menuWidth={230}
        triggerElement={
          <>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDBox
                className="icon-button cursor-pointer"
                sx={{ ml:{xs:0, sm:0, md:0.5}, display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start"} }}
              >
                <FaFilter color="white" size={15} />
              </MDBox>
            </MDBox>
          </>
        }
      >
        <MDBox>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12}>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker", "DesktopDatePicker"]}>
                    <DemoItem
                      mt={0}
                      mb={0}
                      label={
                        <MDTypography variant="h6" fontWeight="medium" sx={{ mt: -1.5 }}>
                          From
                        </MDTypography>
                      }
                      sx={{ fontWeight: "medium" }}
                      style={{ fontWeight: "medium" }}
                    >
                      <DatePicker
                        defaultValue={dayjs(startDate)}
                        onChange={(newValue) => {
                          setStartDate(dayjs(newValue));
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DesktopDatePicker", "DesktopDatePicker"]}>
                  <DemoItem
                    label={
                      <MDTypography variant="h6" fontWeight="medium" sx={{ mt: 1 }}>
                        To
                      </MDTypography>
                    }
                  >
                    <DatePicker
                      defaultValue={dayjs(endDate)}
                      onChange={(newValue) => {
                        setEndDate(dayjs(newValue));
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <MDBox mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDButton variant="contained" color="dark" onClick={handleSubmit}>
                  Apply
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDMenu>
    </MDBox>
  );
};

export default FilterMenu;
