/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Tooltip from "@mui/material/Tooltip";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import React, { useEffect, useState, useContext } from "react";
import MDButton from "components/MDButton";
import { paginationConstants } from "constants/pagination";
import DataTable from "components/Tables/DataTable";
import DefaultCell from "components/Cell/DefaultCell";
import CustomerCell from "components/Cell/CustomerCell";
import field from "../schemas/form";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";
import {
  capitalizeFirstLetter,
  splitUnderscoreWithCaps,
  changeDateFormatWithTimeZone,
} from "lib/helper";
import AuthContext from "context/Authcontext";
import { FaPercentage } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa6";
import { MdContentCopy } from "react-icons/md";
import { IoChatbubbleEllipses } from "react-icons/io5";
import GlobalStateContext from "context/GlobalStateContext";

// component
import Coupon from "../Coupon";
import API_ENDPOINTS from "apiConfig";

function StripeCoupons() {
  const [coupons, setCoupons] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [newPreloaded, setNewPreloaded] = useState(false);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [pageSize, setPageSize] = useState(paginationConstants.perPage);
  const { getProfileDetails, profileDetails } = useContext(AuthContext);
  const {showCouponEditComp, setShowCouponEditComp, editedCoupon, setEditedCoupon} = useContext(GlobalStateContext);

  const {
    formField: { duration },
  } = field;

  const deleteCoupon = async (id) => {
    setRefresh(false);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/coupons/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (response.ok) {
        setRefresh(true);
        toast.success("Coupon Deleted Successfully");
      }
    } catch (err) {
      toast.error("Some error occoured while deleting coupon");
    }
  };

  const editCoupon = (coupon) => {
    setShowCouponEditComp(true);
    setEditedCoupon(coupon);
  };

  const changeNewPreloaded = () => {
    setNewPreloaded(!newPreloaded);
    setEditedCoupon({});
  };

  const changeIsEditing = () => {
    setShowCouponEditComp(!showCouponEditComp);
    setEditedCoupon({});
  };

  const NameCell = ({ name, couponId }) => {
    return (
      <MDBox display="flex" alignItems="center" style={{ cursor: "pointer" }}>
        {name ? (
          <Link to={`${couponId}`}>
            <MDBox style={{ cursor: "pointer" }}>
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                sx={{ display: "flex", alignItems: "center", lineHeight: 0, cursor: "pointer" }}
              >
                {name}
              </MDTypography>
            </MDBox>
          </Link>
        ) : (
          <MDTypography
            variant="caption"
            fontWeight="medium"
            color="text"
            sx={{ display: "flex", alignItems: "center", lineHeight: 0 }}
          >
            N/A
          </MDTypography>
        )}
      </MDBox>
    );
  }

  const CouponCell = ({ code }) => {
    const [showCoupon, setShowCoupon] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => console.log("Something went wrong", err));
    };
    return (
      <MDBox display="flex" alignItems="center">
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ cursor: "pointer" }}
          className="cursor-pointer"
          onClick={() => setShowCoupon(!showCoupon)}
        >
          {showCoupon ? (
            <MDBox sx={{ display: "flex", alignItems: "center", lineHeight: 0 }}>
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                sx={{ display: "flex", alignItems: "center", lineHeight: 0, cursor: "pointer" }}
              >
                {code ? code : "N/A"}
              </MDTypography>
            </MDBox>
          ) : (
            <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0, cursor: "pointer" }}>
              *****
            </MDTypography>
          )}
        </MDBox>
        {showCoupon && (
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={copied ? "Copied" : "Copy"}
            placement="top"
          >
            <MDBox ml={1} onClick={handleCopy} className="cursor-pointer">
              <MdContentCopy />
            </MDBox>
          </Tooltip>
        )}
      </MDBox>
    );
  };

  const DiscountCell = ({ discount, discountType }) => {
    return (
      <MDBox display="flex" alignItems="center" justifyContent="space-between">
        {discountType ? (
          <MDBox>
            <MDTypography
              variant="caption"
              fontWeight="medium"
              color="text"
              sx={{ display: "flex", alignItems: "center", lineHeight: 0 }}
            >
              {discount}
              {discountType === "percent" ? <FaPercentage /> : <FaDollarSign />}
            </MDTypography>
          </MDBox>
        ) : (
          <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
            N/A
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const FrequencyCell = ({ frequency, duration }) => {
    return (
      <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {frequency?.length > 0 ? (
          <>
            <MDBox mr={1}>
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                sx={{ lineHeight: 0 }}
              >
                {frequency}
              </MDTypography>
            </MDBox>
            {duration >= 0 && (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ lineHeight: 0 }}
                >
                  {`( ${duration} billings )`}
                </MDTypography>
              </MDBox>
            )}
          </>
        ) : (
          <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
            N/A
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const ButtonCell = ({ coupon }) => {
    return (
      <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MDBox mr={2}>
          <MDBox
            variant="text"
            sx={{ display: "flex", alignItems: "center" }}
            color="error"
            onClick={(e) => {
              e.preventDefault();
              deleteCoupon(coupon.id);
            }}
          >
            <Icon>delete</Icon>&nbsp;
          </MDBox>
        </MDBox>
        <MDBox mr={2}
          variant="text"
          sx={{ display: "flex", alignItems: "center" }}
          color="info"
          onClick={(e) => {
            e.preventDefault();
            editCoupon(coupon);
          }}
        >
          <Icon>edit</Icon>&nbsp;
        </MDBox>
        <Link to={`${coupon.id}`}>
      <MDBox
        variant="text"
        sx={{ display: "flex", alignItems: "center" }}
        color="info"
        className="cursor-pointer"
      >
        <IoChatbubbleEllipses />
        &nbsp;
      </MDBox>
      </Link>
      </MDBox>
    );
  };

  const loadData = (couponData) => {
    if (couponData && couponData.length > 0) {
      let columns = [
        { Header: "id", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "name", accessor: "name", Cell: ({ value: [name, couponId]}) => <NameCell name={name} couponId={couponId} /> },
        { Header: "code", accessor: "code", Cell: ({ value }) => <CouponCell code={value} /> },
        {
          Header: "frequency",
          accessor: "frequency",
          Cell: ({ value: [frequency, duration] }) => (
            <FrequencyCell frequency={frequency} duration={duration} />
          ),
        },
        {
          Header: "discount",
          accessor: "discount",
          Cell: ({ value: [discount, discountType] }) => (
            <DiscountCell discount={discount} discountType={discountType} />
          ),
        },
        {
          Header: "redemptions",
          accessor: "redemptions",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "valid till",
          accessor: "valid_till",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "created at",
          accessor: "created_at",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "action",
          accessor: "action",
          isSorted: "Hi",
          displaySorted: false,
          Cell: ({ value }) => <ButtonCell coupon={value} />,
        },
      ];

      let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      let rows = couponData.map((coupon) => {
        let name = coupon.name
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(" ");

        let couponId = coupon?.id ? coupon.id : "N/A";

        let discount = coupon?.discount_percent
          ? coupon.discount_percent
          : coupon?.discount_amount
          ? coupon.discount_amount
          : 0;
        let discountType = coupon?.discount_percent
          ? "percent"
          : coupon?.discount_amount
          ? "amount"
          : null;

        let duration = coupon?.frequency_type ? capitalizeFirstLetter(coupon.frequency_type) : null;

        let redemptions = "N/A";
        if (coupon?.max_redemptions && coupon.max_redemptions >= 0) {
            if(coupon?.redemptions_count && coupon.redemptions_count >= 0) {
                redemptions = `${coupon.redemptions_count}/${coupon.max_redemptions}`;
            } else {
                redemptions = `0/${coupon?.max_redemptions}`;
            }
        }

        const createdAt = coupon?.created_at
          ? changeDateFormatWithTimeZone(coupon?.created_at, timeZone)
          : "N/A";

        const validTill = coupon?.valid_till
          ? changeDateFormatWithTimeZone(coupon?.valid_till, timeZone)
          : "N/A";
        return {
          id: couponId,
          name: [name, coupon?.id],
          code: coupon.code,
          frequency: [duration || "Lifetime", coupon?.duration_in_months || 0],
          discount: [discount, discountType],
          redemptions: redemptions,
          created_at: createdAt,
          valid_till: validTill,
          action: coupon,
        };
      });

      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const getCouponList = async () => {
    let response = await fetch(API_ENDPOINTS.getAllCoupons, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      setCouponList(jsonResponse);
      loadData(jsonResponse);
    }
  };

  useEffect(() => {
    if (refresh) {
      getCouponList();
    }
  }, [refresh]);

  return (
    <>
      {!showCouponEditComp && !newPreloaded && (
        <Card id="coupons-list-card">
          <MDBox p={2}>
            <MDBox display="flex" alignItems="center" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium">
                Coupons
              </MDTypography>
              {(!showCouponEditComp || !newPreloaded) && (
                <MDBox display="flex" alignItems="center">
                  <MDButton
                    variant="heading"
                    color="error"
                    onClick={() => {
                      setNewPreloaded(true);
                    }}
                  >
                    <Icon>add</Icon>&nbsp;Add New
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
            {!showCouponEditComp && !newPreloaded && (
              <MDBox my={2}>
                <DataTable table={dataTable} entriesPerPage={pageSize} canSearch />
              </MDBox>
            )}
          </MDBox>
        </Card>
      )}

      {newPreloaded && (
        <MDBox my={2}>
          <Coupon
            onRefresh={(val) => setRefresh(val)}
            editedCoupon={null}
            onClose={changeNewPreloaded}
          />
        </MDBox>
      )}
      {showCouponEditComp && (
        <MDBox my={2}>
          <Coupon
            key={editedCoupon?.id}
            onRefresh={(val) => setRefresh(val)}
            editedCoupon={editedCoupon}
            onClose={changeIsEditing}
          />
        </MDBox>
      )}
    </>
  );
}

export default StripeCoupons;
