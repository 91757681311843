import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import MDButton from "components/MDButton";
import { Link } from "@mui/material";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import AuthContext from "context/Authcontext";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import MDLoader from "components/MDLoader";
function AuthCallbacks() {
  const { handleAuthCode,  handleCalendarAuthCode, handleCalendarSyncAuthCode } = useContext(AuthContext);
  useEffect(() => {
    const url = new URL(window.location.href);
    let activeAction = localStorage.getItem("activeAction");

    const isGoogle = url.href.includes("google");
    const isLinkedIn = url.href.includes("linkedin");
    const isMicrosoft = url.href.includes("microsoft");

    const code = url.searchParams.get("code");

    if (code) {
      if (isGoogle) {
        if(activeAction === "sync calendar") {
          handleCalendarAuthCode(code, "google");
        }
        else if(activeAction === "sync google calendar") {
          handleCalendarSyncAuthCode(code, "google");
        }
        else{
          handleAuthCode(code, 2);
        }
      } else if (isLinkedIn) {
        handleAuthCode(code, 1);
      }
      else if (isMicrosoft) {
        if(activeAction === "sync calendar") {
          handleCalendarAuthCode(code, "microsoft");
        }
        else if(activeAction === "sync microsoft calendar") {
          handleCalendarSyncAuthCode(code, "microsoft");
        }
      }
    }

    localStorage.removeItem("activeAction");
  }, []);

  return (
    <MDBox
      display="flex" // Use Flexbox
      flexDirection="column" // Stack children vertically
      justifyContent="center" // Center children vertically in the container
      alignItems="center"
      className="page-container"
    >
      <MDLoader />
      <Footer />
    </MDBox>
  );
}

export default AuthCallbacks;
