import { useEffect, useState, useContext } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds
import validations from "../schemas/validations";
import form from "../schemas/form";
// import initialValues from "../schemas/initialValues";
import { useMaterialUIController } from "context";


// page component
import UserForm from "../UserForm";

function NewUser({ editedUser, onRefresh, onClose }) {
    const { formId, formField } = form;
    const [detail, setDetail] = useState("");
    const currentValidation = validations[0];
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const sleep = (ms) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        subscription:"trial",
        password:"",
        affiliate_onboarding_status: "",
        stripe_coupon_code: "",
        apiUser: "",
        apiKey: "",
        licenceCount: "",
    };

    useEffect(() => {
        if (editedUser !== null) {
            const [firstName, lastName] = editedUser.full_name.split(" ");
            initialValues.firstName = firstName;
            initialValues.lastName = lastName;
            initialValues.email = editedUser.email;
            initialValues.role = editedUser.role_id;
            initialValues.subscription = editedUser.subscription_status==null?"trial":editedUser.subscription_status;
            initialValues.password = "";
            initialValues.affiliate_onboarding_status = editedUser.affiliate_onboarding_status?(editedUser.affiliate_onboarding_status==='restricted'? 'Not Onboarded': 'Onboarded'): '';
            initialValues.stripe_coupon_code = editedUser.stripe_coupon_code;
            initialValues.apiUser = editedUser?.api_user;
            initialValues.apiKey = editedUser?.api_key;
            initialValues.licenceCount = editedUser?.licence_count;
            
            setDetail("Update User");
        } else {
            setDetail("Add User");
        }
    }, [editedUser]);

    const handleRestPassword = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/users/${editedUser?.id}/reset-password`,
            {
              method: "POST",
              headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
                "Content-type": "application/json; charset=UTF-8",
              },
            }
          );
    
          if (response.ok) {
            toast.success("Password reset successfully");
          } else {
            const jsonData = await response.json();
            throw new Error(jsonData.message || "Failed to reset password");
          }
        } catch (error) {
          toast.error(error.message);
        }
      };

    const AddUser = async (payload, actions) => {
        try {
            onRefresh(false);
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user`, {
                method: "POST",
                body: payload,
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            });

            if (response.ok) {
                toast.success("User created successfully");
                onClose();
                actions.resetForm();
                onRefresh(true);
            } else {
                const responseData = await response.json();
                throw new Error(responseData.message);
            }
        } catch (error) {
            console.log('error ', error);
            toast.error(error.message || error);
        } finally {
            actions.setSubmitting(false);
        }
    }

    const EditUser = async (payload, actions) => {
        try {
            onRefresh(false);

            let id = parseInt(editedUser.id);
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${id}`, {
                method: "PUT",
                body: payload,
                headers: {
                    authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            });

            if (response.ok) {
                toast.success("User updated successfully");
                onClose();
                actions.resetForm();
                onRefresh(true);
            } else {
                const responseData = await response.json();
                throw new Error(responseData.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            actions.setSubmitting(false);
        }
    }

    const submitForm = async (values, actions) => {
        await sleep(1000);
      
        const lastName = values.lastName?.trim() || "";
        const fullName = `${values.firstName?.trim()} ${lastName}`.trim();
        const payload = JSON.stringify({
            full_name: fullName,
            email: values.email?.trim(),
            role_id: values.role,
            subscription_status: values.subscription,
            password: values.password,
            apiUser: values?.apiUser,
            apiKey: values?.apiKey,
            licenceCount: values?.licenceCount
        });

        if(editedUser === null) {
            AddUser( payload, actions );
        } else {
            EditUser( payload, actions );
        }
        
        actions.setSubmitting(true);
    };

    const handleSubmit = (values, actions, errors) => {
        submitForm(values, actions);

        actions.setTouched({});
        actions.setSubmitting(false);
    };

    return (
        <MDBox py={3} mb={20} height="auto">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{ height: "100%" }}>
                                    <MDBox p={3}>
                                        <MDBox
                                            lineHeight={0}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MDTypography variant="h5">{detail}</MDTypography>
                                            <MDButton
                                                color={darkMode ? "white" : "dark"}
                                                variant="text"
                                                onClick={() => onClose()}
                                            >
                                                <Icon>arrow_back</Icon>&nbsp;Back
                                            </MDButton>
                                        </MDBox>
                                        <UserForm
                                            formData={{ values, touched, formField, errors }}
                                            detail={detail}
                                            setFieldValue={setFieldValue}
                                            userId={editedUser?.id}
                                        />
                                        <MDBox display="flex" justifyContent={detail === "Update User" ? "space-between" : "flex-end"} alignItems="end">
                                            {detail === "Update User" && (<MDBox display="flex" flexDirection="column" alignItems="start" justifyContent="flex-start" textAlign="left">
                                                <MDBox display="flex" justifyContent="flex-start">
                                                <MDTypography
                                                    color="info"
                                                    variant="button" fontWeight="regular" textTransform="capitalize"
                                                    sx={{ cursor: "pointer" }}
                                                    mb={1}
                                                >
                                                    <span className="cursor-pointer" onClick={handleRestPassword}>Click here to reset password</span>
                                                </MDTypography>
                                                </MDBox>
                                            </MDBox>)}
                                            <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <MDButton
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="gradient"
                                                    color="dark"
                                                >
                                                    Save
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </MDBox>
    );
}

NewUser.propTypes = {
    editedUser: PropTypes.object,
    onRefresh: PropTypes.func,
    onClose: PropTypes.func,
};

export default NewUser;
