const form = {
    formId: "new-coupon-form",
    formField: {
        name: {
            name: "name",
            label: "Name",
            type: "text",
        },
        status: {
            name: "status",
            label: "Status",
            type: "text",
            options: [
                {
                    value: "active",
                    label: "Active",
                },
                {
                    value: "inactive",
                    label: "Inactive",
                },
                {
                    value: "trial",
                    label: "Trial",
                },
                {
                    value: "pending",
                    label: "Pending",
                },
                {
                    value: "trial_finished",
                    label: "Trial Finished",
                },
                {
                    value: "cancelled",
                    label: "Cancelled",
                }
            ],
            visisbleOptions: [
                {
                    value: "active",
                    label: "Active",
                },
                {
                    value: "trial",
                    label: "Trial",
                },
                {
                    value: "trial_finished",
                    label: "Trial Finished",
                },
            ],
            errorMsg: "Status is required.",
        },
    },
};

export default form;
