/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import { useContext, useState, useEffect } from "react";
import AuthContext from "context/Authcontext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import API_ENDPOINTS from "apiConfig";
import toast from "react-hot-toast";
const moment = require("moment");

function BasicInfo() {
  const { profileDetails, updateUserDetails, updateProfilePicture, getProfileDetails } =
    useContext(AuthContext);

  const maxDate = moment().format("YYYY-MM-DD");

  const [refresh, setRefresh] = useState(true);
  const [updateUserData, setUpdateUserData] = useState({
    full_name: "",
    title: "",
    phone: "",
    birthdate: "",
    gender: "",
    location: "",
    address: "",
    description: "",
    facebook_link: "",
    instagram_link: "",
    twitter_link: "",
    linkedin_link: "",
  });

  const onUserUpdateChange = (event) => {
    const { name, value } = event.target;
    if (name == "birthdate") {
      setUpdateUserData({
        ...updateUserData,
        [name]: moment(value).format("YYYY-MM-DD"),
      });
    } else {
      setUpdateUserData({
        ...updateUserData,
        [name]: value,
      });
    }
  };

  const onUpdateProfile = async () => {
    try{
      setRefresh(false);
      let message;
      if(!(updateUserData.full_name && updateUserData.title && updateUserData.phone && updateUserData.birthdate &&
         updateUserData.gender && updateUserData.address && updateUserData.description)) {
         if(!updateUserData.full_name) {
           message = "Full name is required";
         }
         else if(!updateUserData.title) {
           message = "Title is required";
         }
         else if(!updateUserData.phone) {
           message = "Phone number is required";
         }
         else if(!updateUserData.birthdate) {
           message = "Birthdate is required";
         }
         else if(!updateUserData.gender) {
           message = "Gender is required";
         }
         else if(!updateUserData.address) {
           message = "Address is required";
         }
         else if(!updateUserData.description) {
           message = "Description is required";
         }
  
         throw new Error(message);
      }

      if(updateUserData.phone) {
        if(updateUserData.phone.toString().length != 10) {
          throw new Error("Phone number should be 10 digits");
        }
        else if(updateUserData.phone.toString().startsWith("0")) {
          throw new Error("Phone number should not start with 0");
        }
      }
  
      if(updateUserData.facebook_link) {
        if(!updateUserData.facebook_link.includes("facebook")) {
          throw new Error("Facebook link is not valid");
        }
      }
      if(updateUserData.instagram_link) {
        if(!updateUserData.instagram_link.includes("instagram")) {
          throw new Error("Instagram link is not valid");
        }
      }
      if(updateUserData.twitter_link) {
        if(!updateUserData.twitter_link.includes("twitter")) {
          throw new Error("Twitter link is not valid");
        }
      }
      if(updateUserData.linkedin_link) {
        if(!updateUserData.linkedin_link.includes("linkedin")) {
          throw new Error("Linkedin link is not valid");
        }
      }
  
      const response = await updateUserDetails(updateUserData);
      if(response?.ok) {
        setRefresh(true);
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  };

  const initializeValues = () => {
      setUpdateUserData(
        {
          full_name: profileDetails.full_name ? profileDetails.full_name: "",
          title: profileDetails.title ? profileDetails.title: "",
          phone: profileDetails.phone ? profileDetails.phone: "",
          birthdate: profileDetails.birthdate ? moment(profileDetails.birthdate).format("YYYY-MM-DD"): "",
          gender: profileDetails.gender ? profileDetails.gender: "",
          location: profileDetails.location ? profileDetails.location: "",
          address: profileDetails.address ? profileDetails.address: "",
          description: profileDetails.description ? profileDetails.description: "",
          facebook_link: profileDetails.facebook_link ? profileDetails.facebook_link: "",
          instagram_link: profileDetails.instagram_link ? profileDetails.instagram_link: "",
          twitter_link: profileDetails.twitter_link ? profileDetails.twitter_link: "",
          linkedin_link: profileDetails.linkedin_link ? profileDetails.linkedin_link: "",
        }
      );
  };

  useEffect(() => {
    if(refresh) { 
      getProfileDetails();
    }
  }, [refresh]);

  useEffect(() => {
    if(profileDetails) {
      initializeValues();
    }
  }, [profileDetails]);

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.full_name}
              name="full_name"
              label="Name"
              placeholder="Enter Full Name"
              onChange={onUserUpdateChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.title}
              name="title"
              label="Title"
              placeholder="Enter title"
              onChange={onUserUpdateChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.phone}
              name="phone"
              label="Phone Number"
              placeholder="Enter phone number"
              inputProps={{ type: "number" }}
              onChange={onUserUpdateChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={moment(updateUserData.birthdate).format("YYYY-MM-DD")}
              name="birthdate"
              label="DOB"
              placeholder="Enter DOB"
              inputProps={{ type: "date", max: maxDate }}
              onChange={onUserUpdateChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.location}
              name="location"
              label="Your location"
              placeholder="Enter your location"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ color: "#7b809a", fontSize: "12px", fontWeight: "normal" }}
                required
              >
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender"
                value={updateUserData.gender}
                onChange={onUserUpdateChange}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={<span style={{ color: "#000", fontWeight: "normal" }}>Male</span>}
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={<span style={{ color: "#000", fontWeight: "normal" }}>Female</span>}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={<span style={{ color: "#000", fontWeight: "normal" }}>Other</span>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.address}
              name="address"
              label="Address"
              placeholder="Enter your address"
              required
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.description}
              name="description"
              label="Description"
              placeholder="Enter description"
              required
              onChange={onUserUpdateChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.facebook_link}
              name="facebook_link"
              label="Facebook"
              placeholder="Facebook Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.instagram_link}
              name="instagram_link"
              label="Instagram"
              placeholder="Instagram Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.twitter_link}
              name="twitter_link"
              label="Twitter"
              placeholder="Twitter Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={updateUserData.linkedin_link}
              name="linkedin_link"
              label="LinkedIn"
              placeholder="LinkedIn Link"
              onChange={onUserUpdateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="profiePic"
              id="fileInput"
              label="Profile Picture"
              inputProps={{ type: "file" }}
              onChange={updateProfilePicture}
            />
          </Grid>
        </Grid>
        <Grid display="flex" justifyContent="end" mt={4}>
          <MDBox ml="auto" mt={2}>
            <MDButton variant="gradient" color="dark" size="small" onClick={onUpdateProfile}>
              Update Profile
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
