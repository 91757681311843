import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import creditCard from 'assets/crmimages/logos/credit-card.png';
import AuthContext from "context/Authcontext";

const Payment = () => {

  const { verifyToken, isAutheticated, isPro } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await verifyToken(false);
      if (!res.ok) {
        navigate("/authentication/sign-in");
      }
      
    })();
  }, [isAutheticated, isPro]);

  return (
    <PageLayout>
      <MDBox py={3} sx={{ overflow: "hidden", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <img src={creditCard} className='payment-card' />
        <MDBox sx={{ width: "100%"}} mt={5}>
          <MDTypography variant="h5" gutterBottom align="center">
            Please complete Payment!
          </MDTypography>

        </MDBox>
        <MDBox>
          <MDTypography variant="subtitle2" fontWeight="regular" gutterBottom align="center" mx={20}>
            Please add you card details to sucessfully complete the free trial process of skoop application.
          </MDTypography>
        </MDBox>
      </MDBox>
    </PageLayout>
  )
}

export default Payment