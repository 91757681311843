import { useContext, useEffect } from "react";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import List from "./components/list";
import AuthContext from "context/Authcontext";

function Coupons() {
  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);

  return (
    <BaseLayout stickyNavbar>
      <List />
    </BaseLayout>
  );
}


export default Coupons;

