import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";
import UserRegistration from "components/UserRegistration"
import { useLocation, useNavigate ,useSearchParams } from "react-router-dom";
import MDLoader from "components/MDLoader";
function Register() {
  const { handleRegister, isAutheticated, verifyToken, isLoading, isPro} = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("skoopCrmAccessToken");
    if (accessToken != null && !isAutheticated) {
      verifyToken(false);
    }
    if(isAutheticated && !isPro) {
      if (!location.pathname.includes("/subscription")) {
        navigate("/subscription");
      }
    }
    if(isAutheticated) {
        navigate("/pages/profile/profile-overview");
    }
  }, [isAutheticated]);

  return (
    <>
    {isLoading && <MDLoader/>}
    <UserRegistration handleRegistration={handleRegister}
      cardTitle="Create Your Account"
      submitButtonTitle="Or Sign up with Email"
      googleButtonTitle="Sign up with Google"
      linkedInButtonTitle="Sign up with LinkedIn"
    /> 
    </>
  );
}

export default Register;