
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import CalendarView from "./components/CalendarView";


function Calendar() {
  return (
    <BaseLayout stickyNavbar>
      <CalendarView />
    </BaseLayout>
  );
}

export default Calendar;
