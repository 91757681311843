import React, { useEffect, useState, useContext } from "react";
/* eslint-disable */
import PropTypes from "prop-types";

import { changeDateFormatWithTimeZone } from "lib/helper";
import AuthContext from "context/Authcontext";
import TableContext from "context/Tablecontext";
import Tooltip from "@mui/material/Tooltip";
import MDTable from "components/MDTables";

import Icon from "@mui/material/Icon";
import { ImCross } from "react-icons/im";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import API_ENDPOINTS from "apiConfig";
import DefaultCell from "components/Cell/DefaultCell";
import NewUser from "../NewUser";
import toast from "react-hot-toast";
import ConfirmDelete from "components/ConfirmDelete";
import FilterMenu from "../FiterMenu";
import Avatar from 'react-avatar';

import { FaUsers } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";

// function
import { capitalizeFirstLetter } from "lib/helper";
import {roles, subscriptionStatus } from "constants/users";

function UserInformation() {
  const [refresh, setRefresh] = useState(true);
  const [userList, setUserList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [newPreloaded, setNewPreloaded] = useState(false);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [editedUser, setEditedUser] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
  } = useContext(TableContext);
  const [canSearch, setCanSearch] = useState(true);
  const [filteringParams, setFilteringParams] = useState([]);
  const [roleOptions, setRoleOptions] = useState(roles);
  const [subscriptionStatusOptions, setSubscriptionStatusOptions] = useState(subscriptionStatus);
  const { getProfileDetails, profileDetails, getRoles, roleData  } = useContext(AuthContext);
  const [ licenceDetails, setLicenceDetails ] = useState({});

  function formatSubscriptionStatus(str) {
    let words = str.match(/[A-Z]?[a-z]+/g);
    
    if (!words) return null;
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const deleteUser = async (userId) => {
    if (userId === profileDetails.id) {
      toast.error("You cannot delete your own account");
      return;
    }
    setRefresh(false);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}?type=soft`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.ok) {
        setRefresh(true);
        toast.success("User Deleted Successfully");
      }
    } catch (err) {
      toast.error("Some error occoured while deleting user");
    }
  };

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm) {
      await deleteUser(selectedUserId);
    }
    setShowConfirmDelete(false);
    setSelectedUserId("");
  };

  const deleteUserById = async (userId) => {
    setSelectedUserId(userId);
    setShowConfirmDelete(true);
  };

  const editUser = (user) => {
    setIsEditing(true);
    setEditedUser(user);
  };

  const changeNewPreloaded = () => {
    setNewPreloaded(!newPreloaded);
    setEditedUser({});
  };

  const changeIsEditing = () => {
    setIsEditing(!isEditing);
    setEditedUser({});
  };

  function AvatarCell({ image, name}) {
    return (
        <MDBox display="flex" alignItems="center">
            <MDBox mr={1}>
                <Avatar
        name={name}
        src={image}  
        round={true}
        size="28"  
      />
            </MDBox>
            <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                {name}
            </MDTypography>
        </MDBox>
    );
}

  const PlanCell = ({ subscriptionPlan, subscriptionStatus }) => {
    return (
      <MDBox sx={{ display: "flex", alignItems: "center" }} className="plan-cell">
        {subscriptionPlan?.length > 0 ? (
          <>
            <MDTypography variant="caption" fontWeight="medium" color="secondary">
              {subscriptionPlan}
            </MDTypography>
          </>
        ) : (
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            N/A
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const StatusCell = ({ subscriptionStatus }) => {
    return (
      <MDBox sx={{ display: "flex", alignItems: "center" }}>
        {subscriptionStatus?.length > 0 ? (
          <>
            <MDBox className={`subscription-status ${subscriptionStatus?.toLowerCase()}`}>
              <MDTypography variant="caption" fontWeight="medium" className="subscription-status-text">
                    {subscriptionStatus}
            </MDTypography>
            </MDBox>
          </>
        ) : (
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            N/A
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const ButtonCell = ({ user }) => {
    return (
      <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MDBox mr={2}>
          <MDBox
            variant=""
            sx={{ display: "flex", alignItems: "center" }}
            color="error"
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              deleteUserById(user.id);
            }}
          >
            <Icon>delete</Icon>&nbsp;
          </MDBox>
        </MDBox>
        <MDBox
          variant="caption"
          sx={{ display: "flex", alignItems: "center" }}
          color="info"
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            editUser(user);
          }}
        >
          <Icon>edit</Icon>&nbsp;
        </MDBox>
      </MDBox>
    );
  };
  const RoleCell = ({ role }) => {
    let roleIcon = "";
    if (role === "admin") {
      roleIcon = <FaUsers size={20} className={role} />;
    }

    if (role === "affiliate") {
      roleIcon = <FaDollarSign className={role} />;
    }

    if (role === "user") {
      roleIcon = <FaUser className={role} />;
    }
    return (
      <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MDBox mr={1}>
          <MDBox
            variant="caption"
            sx={{ display: "flex", alignItems: "center" }}
            color="secondary"
            className="cursor-pointer"
          >
            {roleIcon}&nbsp;
          </MDBox>
        </MDBox>
        <MDBox
          variant="caption"
          sx={{ display: "flex", alignItems: "center" }}
          className="cursor-pointer"
        >
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {role[0].toUpperCase() + role.slice(1)}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };

  const loadData = (userData) => {
    if (userData && userData.length > 0) {
      let columns = [
        {
          Header: "id",
          accessor: "id",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "user",
          accessor: "full_name",
          Cell: ({ value: [name, image] }) => (
            <AvatarCell image={image} name={name} />
          ),
        },
        { Header: "email", accessor: "email", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "role", accessor: "role_name", Cell: ({ value }) => <RoleCell role={value} /> },
        {
          Header: "plan",
          accessor: "subscription_plan",
          Cell: ({ value }) => (
            <PlanCell subscriptionPlan={value} />
          ),
        },
        {
          Header: "status",
          accessor: "subscription_status",
          Cell: ({ value }) => (
            <StatusCell subscriptionStatus={value} />
          ),
        },
        {
          Header: "created at",
          accessor: "created_at",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "last activity",
          accessor: "last_activity",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "action",
          accessor: "action",
          isSorted: false,
          Cell: ({ value }) => <ButtonCell user={value} />,
        },
      ];

      let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      let rows = userData.map((user) => {
        const status = user?.subscription_status
          ? capitalizeFirstLetter(user?.subscription_status)
          : null;
        const created_at = user?.created_at
          ? changeDateFormatWithTimeZone(user?.created_at, timeZone)
          : "N/A";
        const last_activity = user?.last_activity
          ? changeDateFormatWithTimeZone(user?.last_activity, timeZone)
          : "N/A";

        const plan = user?.subscription_plan ? formatSubscriptionStatus(user?.subscription_plan) : null;
        let imagePath = null;
        if(user?.image_path?.startsWith("http")){
          imagePath = user.image_path;
        }
        else if (!user.image_path?.startsWith("publics")) {
          imagePath = process.env.REACT_APP_BACKEND_URL + "/" + user.image_path;
        }

        const fullName = user?.full_name ? capitalizeFirstLetter(user?.full_name) : null;
        return {
          id: user.id.toString(),
          full_name: [fullName, imagePath],
          email: user.email,
          role_name: user.role_name,
          subscription_plan: plan,
          subscription_status: status,
          created_at: created_at,
          last_activity: last_activity,
          action: user,
        };
      });

      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const getUserList = async () => {
    let query = "";
    query += `page=${currentPage}&limit=${pageSize}`;

    if (filteringParams?.length > 0) {
      const filterText = filteringParams
        .filter((params) => params?.value?.length > 0)
        .map((params) => `${params.type}=${params.value}`);
      query += `&${filterText.join("&")}`;
    }

    if (search) {
      query += `&search=${search}`;
    }

    if (orderBy && order) {
      let sortOrder = order === "asc" ? "ASC" : "DESC";
      query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
    }

    let response = await fetch(`${API_ENDPOINTS.getUserList}` + `?${query}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      const { data } = jsonResponse;
      setUserList(data.items);
      loadData(data.items);
      const totalPage = Math.ceil(data.totalItems / pageSize);
      const startIndex = (currentPage - 1) * pageSize + 1;
      const endIndex = Math.min(currentPage * pageSize, data.totalItems);
      setEnteries({
        ...enteries,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
        totalMediaCount: totalPage,
        enteriesStart: startIndex,
        enteriesEnd: endIndex,
      });
    }
  };
 
  const getLicenceInfo = async () => {
    
    let response = await fetch(`${API_ENDPOINTS.getLicenceDetails}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
   
    if (response.ok) {
      const result = await response.json();
      const data = result.data;
      setLicenceDetails({
        ...licenceDetails,
        remainingLicenceCount: data?.remainingLicenceCount,
        usedLicenceCount: data?.usedLicenceCount,
        usersCount: data?.usersCount['COUNT(id)'],
        allowed: true
      })

    } else {
      setLicenceDetails({
        allowed: false
      })
    }
  }

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(","); 
    const rows = data.map((row) => Object.values(row).join(",")); 
    return [headers, ...rows].join("\n");
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(userList);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    
    const fileName = `user_details_${new Date().toLocaleDateString()}.csv`;
    
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addDataInFilteringParams = () => {
    let filterData = localStorage.getItem("userFilteringParams");
    if( filterData === null) {
      setFilteringParams([
        {
          type: "subscription_status",
          value: "active",
        },
      ]);
    }
    else {
      filterData = JSON.parse(filterData);
      if (typeof filterData === "object" && filterData.length > 0) {
      setFilteringParams(filterData);
    }
  }
  };

  const handleRemoveFilter = (type) => {
    let index = filteringParams?.findIndex((params) => params.type === type);
    if (index !== -1) {
      setFilteringParams((filteringParams) => [
        ...filteringParams.slice(0, index),
        ...filteringParams.slice(index + 1),
      ]);
    }
  };

  const addFiteringParamsToStorage = () => {
    if (filteringParams?.length > 0) {
      localStorage.setItem("userFilteringParams", JSON.stringify(filteringParams));
    }
    else {
      localStorage.setItem("userFilteringParams", JSON.stringify([]));
    }
  };

  const handleStopRefresh = () => {
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      initializeTable();
      addDataInFilteringParams();
      getUserList();
      handleStopRefresh();
      getRoles();
      getLicenceInfo();
    }
  }, [refresh]);

  useEffect(() => {
    if (!refresh) {
      getUserList();
    }
  }, [pageSize, currentPage, orderBy, order]);

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }

    const timeoutId = setTimeout(() => {
      if (!refresh) {
        getUserList();
      }
    }, 500);
    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (!refresh && filteringParams) {
      addFiteringParamsToStorage();
      getUserList();
    }
  }, [filteringParams]);

  return (
    <>
      {!isEditing && !newPreloaded && (
        <Card id="user-list-card">
          <MDBox p={2}>
            <MDBox display="flex" alignItems="center" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium">
                Users
              </MDTypography>
              {(!isEditing || !newPreloaded) && (
                <MDBox display="flex" alignItems="center">
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={"Download CSV"}
                    placement="top"
                  >
                    <MDBox
                      mr={1}
                      display="flex"
                      alignItems="center"
                      onClick={downloadCSV}
                      className="cursor-pointer"
                    >
                      <IoMdDownload />
                    </MDBox>
                  </Tooltip>
                  <MDButton
                    variant="heading"
                    color="error"
                    onClick={() => {
                      setNewPreloaded(true);
                    }}
                  >
                    <Icon>add</Icon>&nbsp;Add New
                  </MDButton>
                </MDBox>
              )}
            </MDBox>

            {!isEditing && !newPreloaded && (
              <MDBox my={2}>
                <MDTable table={dataTable} canSearch={canSearch} canFilter={true} licenceDetails={licenceDetails}>
                  <MDBox p={2}>
                    <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: { xs: 2.8, sm: 0, md: 0 },
                          width: {
                            xs: "100%",
                            sm: "auto",
                            md: "auto",
                          },
                          justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                        }}
                      >
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                            mr: { xs: 0, sm: 0.2, md: 0 },
                          }}
                        >
                          {filteringParams?.length > 0 && (
                            <MDBox display="flex" alignItems="center">
                              {filteringParams.map((params, index) => {
                                const { type, value } = params;
                                const filteredtitle = type
                                  ?.split("_")
                                  .map((word) => word[0].toUpperCase() + word.slice(1))
                                  .join(" ");
                                const filteredValue = value
                                  ?.split("_")
                                  .map((word) => word[0].toUpperCase() + word.slice(1))
                                  .join(" ");
                                return (
                                  <>
                                    <MDButton
                                      variant="outlined"
                                      color="info"
                                      size="small"
                                      sx={{ ml: index !== 0 ? 1 : 0 }}
                                    >
                                      <MDBox
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        mx={0}
                                        px={0}
                                      >
                                        <Tooltip
                                          disableFocusListener
                                          disableTouchListener
                                          title="Clear Filter"
                                          placement="top"
                                        >
                                          <MDBox
                                            sx={{ display: "flex", alignItems: "center", pr: 1.5 }}
                                          >
                                            <ImCross
                                              size={12}
                                              color="info"
                                              className="icon-color cursor-pointer"
                                              onClick={() => handleRemoveFilter(type)}
                                            />
                                          </MDBox>
                                        </Tooltip>
                                        <MDBox
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                          }}
                                        >
                                          <MDTypography
                                            variant="button"
                                            className="icon-color"
                                            fontWeight="bold"
                                            noWrap
                                          >
                                            {filteredtitle}: {filteredValue}
                                          </MDTypography>
                                          <MDBox className="line-over-text" />
                                        </MDBox>
                                      </MDBox>
                                    </MDButton>
                                  </>
                                );
                              })}
                            </MDBox>
                          )}
                        </MDBox>
                        <MDBox
                          sx={{
                            ml: 1.5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                          }}
                        >
                          <FilterMenu
                            filteringParams={filteringParams}
                            setFilteringParams={setFilteringParams}
                            roleOptions={roleOptions}
                            subscriptionStatusOptions={subscriptionStatusOptions}
                          />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDTable>
              </MDBox>
            )}
          </MDBox>
        </Card>
      )}
      {newPreloaded && (
        <MDBox my={2}>
          <NewUser
            onRefresh={(val) => setRefresh(val)}
            editedUser={null}
            onClose={changeNewPreloaded}
          />
        </MDBox>
      )}
      {isEditing && (
        <MDBox my={2}>
          <NewUser
            key={editedUser.id}
            onRefresh={(val) => setRefresh(val)}
            editedUser={editedUser}
            onClose={changeIsEditing}
          />
        </MDBox>
      )}
      <ConfirmDelete
        title="Delete User?"
        message="Are you sure you want to delete this user?"
        confirm={handleDeleteConfirmation}
        showDialog={showConfirmDelete}
      />
    </>
  );
}

export default UserInformation;
