import { useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds
import validations from "../Schemas/validation";
import form from "../Schemas/form";
// import initialValues from "../schemas/initialValues";
import { useMaterialUIController } from "context";

// page component
import ContactFields from "../ContactFields";

function ContactForm({ userDetail, onClose }) {
    const { formId, formField } = form;
    const currentValidation = validations[0];
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const sleep = (ms) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    const initialValues = {
        fullName: "",
        email: "",
        phoneNumber:"",
        websiteUrl: "",
        linkedinUrl: "",
        address: "",
        profileDescription:"",
        notes:"",
    };

    useEffect(() => {
        if (userDetail !== null) {
            initialValues.fullName = userDetail.name;
            initialValues.email = userDetail.personal_email;
            initialValues.phoneNumber = userDetail.phone_number;
            initialValues.websiteUrl =  userDetail.website_url;
            initialValues.linkedinUrl = userDetail.linkedIn_url;
            initialValues.address = userDetail.address;
            initialValues.profileDescription = userDetail.profile_desc;
            initialValues.notes = userDetail.notes;
        }
    }, [userDetail]);

    const handleSave = async (values) => {
        try{
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/CrmAddContactInfo/${userDetail.id}`,{
            method: "PUT",
            headers: {
              "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
              "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
              id: userDetail.id,
              name: values.fullName,
              personal_email: values.email,
              phone_number: values.phoneNumber,
              website_url: values.websiteUrl,
              linkedIn_url: values.linkedinUrl,
              address: values.address,
              profile_desc: values.profileDescription,
              notes: values.notes,
            })
          })
          const jsonResponse = await response.json();
          if(response.ok){
            toast.success(jsonResponse.message);
            onClose();
          }
          else{
            throw new Error(jsonResponse.message);
          }
        }catch(err){
          toast.error(err.message);
        }
    };

    const submitForm = async (values, actions) => {
        await sleep(1000);
        handleSave(values);
        actions.setSubmitting(true);
    };

    const handleSubmit = (values, actions, errors) => {
        submitForm(values, actions);
        actions.setTouched({});
        actions.setSubmitting(false);
    };

    return (
        <MDBox py={3} mb={20} height="auto">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{ height: "100%" }}>
                                    <MDBox p={3}>
                                        <MDBox
                                            lineHeight={0}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MDTypography variant="h5">Edit Contact Info</MDTypography>
                                            <MDButton
                                                color={darkMode ? "white" : "dark"}
                                                variant="text"
                                                onClick={() => onClose()}
                                            >
                                                <Icon>arrow_back</Icon>&nbsp;Back
                                            </MDButton>
                                        </MDBox>
                                        <ContactFields
                                            formData={{ values, touched, formField, errors }}
                                            setFieldValue={setFieldValue}
                                            userId={userDetail?.id}
                                        />
                                        <MDBox>
                                            <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <MDButton
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="gradient"
                                                    color="dark"
                                                >
                                                    Save
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </MDBox>
    );
}

ContactForm.propTypes = {
    userDetail: PropTypes.object,
    onRefresh: PropTypes.func,
    onClose: PropTypes.func,
};

export default ContactForm;
