import React from "react";
import { Box, Typography, Grid, Link, Button, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate } from "react-router-dom";

function Success({ data, appointmentDate, appointmentTime, serviceProvider, onBackButtonClick, timeZone }) {
  const navigate = useNavigate();
  return (
    <Box p={2}>
      <Typography variant="h3"  sx={{ display: "flex", alignItems: "center", mb: 1 }} fontWeight="bold" mb={3}>
        <EventAvailableIcon fontSize="large" sx={{ marginRight: "4px" }} />
        Appointment Confirmation
      </Typography>
      <Box border={0} p={2} mb={3}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="body1" mb={2}>
              Your appointment has been scheduled successfully. Below are the details:
            </Typography>
            <Typography variant="body1">
              <strong>Date:</strong> {appointmentDate}
            </Typography>
            <Typography variant="body1">
              <strong>Time:</strong> {appointmentTime?.startTime}
            </Typography>
            <Typography variant="body1">
              <strong>Time Zone:</strong> {timeZone}
            </Typography>
            <Typography variant="body1" >
              <strong>Service Provider:</strong> {serviceProvider}
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
           {data?.calendarLink && <Button
              variant="contained"
              color="primary"
              component={Link}
              target="_blank"
              href={data?.calendarLink}
              fullWidth
              sx={{ mt:2, mr: 2, color:"#ffffff" }}
            >
              Add to Google Calendar
            </Button>}
            {data?.meetLink && <Button
              variant="contained"
              color="success"
              component={Link}
              target="_blank"
              href={data?.meetLink}
              fullWidth
              sx={{ mt:2, color:"#000000" }}
            >
              Join Meeting
            </Button>}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ border:1, mt: 3, mb: 3 }} />
      <Typography variant="body1" sx={{ mt: 3, mb: 2 }}>
        Schedule your own meetings with Skoop
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Button variant="contained" color="primary" fullWidth sx={{ color:"#ffffff" }} onClick={()=>navigate("/authentication/register")}>
            Register
        </Button>
      </Box>
    </Box>
  );
}

export default Success;
