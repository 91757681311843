const roles = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Affiliate",
      value: "affiliate",
    },
    {
      label: "User",
      value: "user",
    },
    {
      label: "Distributor",
      value: "distributor",
    },
  ];

  const subscriptionStatus = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
    {
      label: "Trial",
      value: "trial",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Trial finished",
      value: "trial_finished",
    },
  ];

  export {
    roles,
    subscriptionStatus
  }