import React, { useState, Fragment, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa";
import MDBadge from "components/MDBadge";
import AuthContext from "context/Authcontext";

export default function SyncingModal({ handleGoogleSync, handleMicrosoftSync, showDialog, setShowDialog }) {
    const [open, setOpen] = useState(false);
    const {profileDetails} = useContext(AuthContext);

    const handleClose = () => {
        setOpen(false);
        setShowDialog(false);
    };

    const syncCalendar = (syncType) => {
        if(syncType == "google") {
            handleGoogleSync();
        } else if(syncType == "microsoft") {
            handleMicrosoftSync();
        }
        handleClose();
    }

    useEffect(() => {
        setOpen(showDialog);
    }, [showDialog]);

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '80%', md: '70%', lg: '40%', xl: '30%' },
                    height: "auto",
                    },
            }}
            >
                <MDBox>
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ textAlign: "center" }}
                    >
                        <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                            <MDTypography variant="h4" fontWeight="medium">
                                Sync Your Calendar
                            </MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} mt={3}>
                            {profileDetails && !profileDetails?.calendar_info ? (<MDTypography variant="body1" >
                               Connect your calendar to auto-check for busy times and add new events as they are scheduled.
                            </MDTypography>):(<MDTypography variant="body1" >
                                Do you want to delete the events from the calendar that is already synced and sync them with a different calendar instead?
                            </MDTypography>)}
                        </MDBox>
                    </DialogTitle>
                    <DialogActions
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <MDButton
                            color="info"
                            variant="outlined"
                            onClick={()=>syncCalendar('google')}
                            fullWidth
                            sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                        <FcGoogle size={16} className="mr-5"/>
                            Google Calendar
                        </MDButton>
                        <MDButton
                            color="info"
                            variant="outlined"
                            onClick={()=>syncCalendar('microsoft')}
                            fullWidth
                            sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                        <FaMicrosoft size={16} className="mr-5"/>
                            Outlook Calendar
                        </MDButton>
                    </DialogActions>
                </MDBox>
            </Dialog>
        </Fragment>
    );
}
