/* eslint-disable */
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import API_ENDPOINTS from "apiConfig";
import DefaultCell from "components/Cell/DefaultCell";
import DataTable from "components/Tables/DataTable";
import {paginationConstants} from "constants/pagination"

function UserInformation() {

    const [refresh, setRefresh] = useState(true);
    const [couponList, setCouponList] = useState([]);
    const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
    const [pageSize, setPageSize] = useState(paginationConstants.perPage);

    const StatusBadge = ({status}) => {
        return (
            <MDBadge
              variant="contained"
              size="xs"
              badgeContent={status}
              color={status === "Redeemed" ? "success" : "error"}
              container
            />
        );
    }

    const loadData = (couponData) => {
        if(couponData && couponData.length > 0) {
            let columns = [
                { Header: "id", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "code", accessor: "code", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "username", accessor: "username", Cell: ({ value }) => <DefaultCell value={value} /> },
                { Header: "status", accessor: "status", Cell: ({ value }) => <StatusBadge status={value} /> },
            ];

            let rows = couponData.map((coupon) => {
                let name = coupon.username?.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ');
                return {
                    id:coupon.id.toString(),
                    code:coupon.code,
                    username:name||'N/A',
                    status: coupon.is_redeemed?"Redeemed":"Not Redeemed",
                    
                }
            })

            setDataTable({ columns: columns, rows: rows });
        }
        else{
            setDataTable({ columns: [], rows: [] });
        }
    }

    const getCouponList = async () => {
        let response = await fetch(
            `${API_ENDPOINTS.getAppSumoCoupons}`
            , {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                    "Content-type": "application/json; charset=UTF-8"
                }
            });

        if (response.ok) {
            const jsonResponse = await response.json();
            setCouponList(jsonResponse);
            loadData(jsonResponse);
        }
    }

    useEffect(() => {
        if (refresh) {
            getCouponList();
        }

    }, [refresh]);

    return (
        <>
            <Card id="app-sumo-card">
                <MDBox p={2}>
                    <MDBox display="flex" alignItems="center" justifyContent="space-between">
                        <MDTypography variant="h6" fontWeight="medium">
                            AppSumo
                        </MDTypography>
                    </MDBox>
                    <MDBox my={2}>
                        <DataTable table={dataTable} entriesPerPage={pageSize} canSearch  />
                    </MDBox>
                </MDBox>
            </Card>
        </>
    );
}

export default UserInformation;