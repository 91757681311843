import { useContext, useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// NewUser page components
import FormField from "components/FormField";

function ContactFields({ formData, setFieldValue, userId }) {
  const { formField, values, errors, touched } = formData;
  const { fullName, email, phoneNumber, websiteUrl, linkedinUrl, address, profileDescription, notes } = formField;
  const {
    fullName: fullNameV,
    email: emailV,
    phoneNumber: phoneNumberV,
    websiteUrl: websiteUrlV,
    linkedinUrl: linkedinUrlV,
    address: addressV,
    profileDescription: profileDescriptionV,
    notes: notesV
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={fullName.type}
              label={fullName.label}
              name={fullName.name}
              value={fullNameV}
              error={errors.fullName && touched.fullName}
              success={fullNameV?.length > 0 && !errors.fullName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV?.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phoneNumber.type}
              label={phoneNumber.label}
              name={phoneNumber.name}
              value={phoneNumberV}
              error={errors.phoneNumber && touched.phoneNumber}
              success={phoneNumberV?.length > 0 && !errors.phoneNumber}
            />
          </Grid>
          {console.log("errors", errors, touched)}
          <Grid item xs={12} sm={6}>
            <FormField
              type={websiteUrl.type}
              label={websiteUrl.label}
              name={websiteUrl.name}
              value={websiteUrlV}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={linkedinUrl.type}
              label={linkedinUrl.label}
              name={linkedinUrl.name}
              value={linkedinUrlV}
              multiline
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={address.type}
              label={address.label}
              name={address.name}
              value={addressV}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={profileDescription.type}
              label={profileDescription.label}
              name={profileDescription.name}
              value={profileDescriptionV}
              multiline
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={notes.type}
              label={notes.label}
              name={notes.name}
              value={notesV}
              multiline
              maxRows={4}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for ContactFields
ContactFields.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ContactFields
