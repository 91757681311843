import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import MDButton from "components/MDButton";
import { Link } from "@mui/material";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import AuthContext from "context/Authcontext";
import { useNavigate } from "react-router-dom";

function AffialiateVideo() {
  const {verifyToken ,isAutheticated} = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = localStorage.getItem("skoopCrmAccessToken");
    if (accessToken != null && !isAutheticated) {
      verifyToken(accessToken);
    }
    if(isAutheticated){
      return;
    }
  }, [isAutheticated]);
  return (
    <MDBox py={3} mb={20} height="65vh">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <MDTypography variant="h4" gutterBottom align="center" sx={{ mt: 1, mb: 1 }}>
            Join Our Affiliate Program
          </MDTypography>
        </Grid>

        <Grid item xs={12} lg={7}>
          <MDTypography variant="body2" align="center" sx={{ mb: 1 }}>
            Discover the perks of partnering with us! Watch the video to learn how you can benefit
            as an affiliate, then easily download our extension and get started.
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card sx={{ height: "100%" }}>
            <MDBox p={3}>
              <MDBox>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: 0,
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    src="https://play.vidyard.com/eWtJcmCpnHurtbBfhu8YJ4"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt={2}>
        <Grid item>
          <Link
            target="_blank"
            href="https://chromewebstore.google.com/detail/skoop-instant-video-for-l/gplimcomjkejccjoafekbjedgmlclpag"
          >
            <MDButton size={"large"} variant={"contained"} color="primary">
              <DownloadForOfflineOutlinedIcon
                style={{
                  marginRight: "5px",
                }}
              />
              Get the Extension Now{" "}
            </MDButton>
          </Link>
        </Grid>
      </Grid>
      <Footer />
    </MDBox>
  );
}

export default AffialiateVideo;
