import { invalid } from "moment";

const form = {
    formId: "new-contact-form",
    formField: {
        fullName: {
            name: "fullName",
            label: "Full Name",
            type: "text",
            errorMsg: "Full Name is required.",
            invalidMsg: "Full Name must be at least 1 character",
        },
        email: {
            name: "email",
            label: "Email",
            type: "email",
            errorMsg: "Email is required.",
            invalidMsg: "Your Email address is invalid",
        },
        phoneNumber: {
            name: "phoneNumber",
            label: "Phone Number",
            type: "text",
            errorMsg: "Phone number is not valid",
            invalidMsg: "Phone number must be 8-16 characters long",
        },
        websiteUrl: {
            name: "websiteUrl",
            label: "Website Url",
            type: "text",
        },
        linkedinUrl: {
            name: "linkedInUrl",
            label: "LinkedIn Url",
            type: "text",
        },
        address: {
            name: "address",
            label: "Address",
            type: "text",
        },
        profileDescription: {
            name: "profileDescription",
            label: "Profile Description",
            type: "text",
        },
        notes: {
            name: "notes",
            label: "Notes",
            type: "text"
        }
    },
};

export default form;
