import React, { useState } from 'react';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Grid from "@mui/material/Grid"; // Importing Grid for layout
import MDButton from 'components/MDButton';
import API_ENDPOINTS from 'apiConfig';
function GeneRateNewCoupons() {
  const [number, setNumber] = useState('');

  const handleInputChange = (event) => {
    setNumber(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const apiEndpoint = API_ENDPOINTS.generateAppSumoCoupons;

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        body: JSON.stringify({ number }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'coupons.csv'); 
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('There was an issue generating the coupons:', error);
    }
  };

  return (
    <BaseLayout>
       <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="70vh" sx={{ padding: '0 !important' }}>

      <Card>
        <MDBox p={2}>
          <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={8}>
                <TextField
                  label="Number of coupons"
                  variant="outlined"
                  value={number}
                  onChange={handleInputChange}
                  type="number"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <MDButton  color="primary" type="submit" >
                  Generate Coupons
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </MDBox>
      </Card>
              </MDBox>
    </BaseLayout>
  );
}

export default GeneRateNewCoupons;