import React, { useState } from 'react';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Grid from "@mui/material/Grid"; // Importing Grid for layout
import MDButton from 'components/MDButton';
import API_ENDPOINTS from 'apiConfig';
import MDTypography from 'components/MDTypography';
import { useNavigate } from 'react-router-dom';
const extensionURL = process.env.REACT_APP_EXTENSION_URL;
import toast from 'react-hot-toast';
function ActiveLifetimeAccess() {
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    setCode(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const apiEndpoint = API_ENDPOINTS.redeemCoupon;

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        body: JSON.stringify({ code }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      const responsejson=await response.json()

    if(response.status==200){
      navigate("/pages/profile")
      toast(`Your coupon is redeemed Please download extension`)
    } window.open(extensionURL, '_blank');
    } catch (error) {
      console.error('There was an issue generating the coupons:', error);
    }
  };

  return (

      <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="70vh" sx={{ padding: '0 !important' }}>
        <Card>
          <MDBox p={2}>
          <Grid item xs={12}>
            <MDTypography variant="h4" gutterBottom align="center" sx={{ mt: 1, mb: 1 }}>
              Redeem your AppSumo Coupon
            </MDTypography>
          </Grid>
            <form onSubmit={handleFormSubmit}>
            <Grid container spacing={2} alignItems="center" p={5}>
                <Grid item xs={8}>
                  <TextField
                    label="Enter your coupon code"
                    variant="outlined"
                    value={code}
                    onChange={handleInputChange}
                    type="code"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDButton  color="primary" type="submit" >
                    Activate
                  </MDButton>
                </Grid>
              </Grid>
            </form>
          </MDBox>
        </Card>
    </MDBox>
  
  );
}

export default ActiveLifetimeAccess;