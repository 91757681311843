import { useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds
import validations from "../schemas/validations";
import form from "../schemas/form";
import { useMaterialUIController } from "context";
import SubscriptionForm from "../SubscriptionForm";
import { capitalizeFirstLetter } from "lib/helper";

const NewSubscription = ({ editedSubscription, onRefresh, onClose }) => {
    const { formId, formField } = form;
    var currentValidation = validations[0];
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const initialValues = {
        status: "",
        name: "",
    };

    const sleep = (ms) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    useEffect(() => {
        if (editedSubscription !== null) {
            initialValues.status = editedSubscription.status;
            initialValues.name = capitalizeFirstLetter(
                editedSubscription.full_name
            );
        }
    }, [editedSubscription]);

    const editSubscription = async (values, actions) => {
        try {
            onRefresh(false);

            const payload = JSON.stringify({
                status: values.status,
                user_id: editedSubscription.user_id,
            });

            let id = parseInt(editedSubscription.id);
            let response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/subscription/${id}`,
                {
                    method: "PUT",
                    body: payload,
                    headers: {
                        authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("skoopCrmAccessToken")
                        )}`,
                        "Content-type": "application/json; charset=UTF-8",
                    },
                }
            );

            if (response.ok) {
                toast.success("Subscription updated successfully");
                onClose();
                actions.resetForm();
                onRefresh(true);
            } else {
                const responseData = await response.json();
                throw new Error(responseData.error);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            actions.setSubmitting(false);
        }
    };

    const submitForm = async (values, actions) => {
        await sleep(1000);
        editSubscription(values, actions);
        actions.setSubmitting(false);
    };

    const handleSubmit = (values, actions, errors) => {
        submitForm(values, actions);
        actions.setSubmitting(true);
    };

    return (
        <>
            <MDBox py={3} mb={20} height="auto">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form id={formId} autoComplete="off">
                                    <Card sx={{ height: "100%" }}>
                                        <MDBox p={3}>
                                            <MDBox
                                                lineHeight={0}
                                                className="flex-space-between"
                                            >
                                                <MDTypography variant="h5">
                                                    Update Subscription
                                                </MDTypography>
                                                <MDButton
                                                    color={
                                                        darkMode
                                                            ? "white"
                                                            : "dark"
                                                    }
                                                    variant="text"
                                                    onClick={() => onClose()}
                                                >
                                                    <Icon>arrow_back</Icon>
                                                    &nbsp;Back
                                                </MDButton>
                                            </MDBox>
                                            <SubscriptionForm
                                                formData={{
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                }}
                                                setFieldValue={setFieldValue}
                                            />
                                            <MDBox>
                                                <MDBox
                                                    mt={2}
                                                    className="flex-end"
                                                >
                                                    <MDButton
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        variant="gradient"
                                                        color="dark"
                                                    >
                                                        Save
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </MDBox>
        </>
    );
};

NewSubscription.propTypes = {
    editedSubscription: PropTypes.object,
    onRefresh: PropTypes.func,
    onClose: PropTypes.func,
};

export default NewSubscription;
