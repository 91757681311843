import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import CouponList from "layouts/pages/appsumo/coupons/components/list";

function UserList(){

    const { verifyToken } = useContext(AuthContext);
    useEffect(() => {
        verifyToken();
    }, []);
    
    return (
        <BaseLayout>
            <CouponList />
        </BaseLayout>
    );
}

export default UserList;