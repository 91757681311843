import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import UserInformation from "layouts/pages/users/user-list/components/UsersInformation";

function UserList(){

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);
    
    return (
        <BaseLayout>
            <UserInformation />
        </BaseLayout>
    );
}

export default UserList;