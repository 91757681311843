import { useContext, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// NewUser page components
import FormField from "components/FormField";

const CouponForm = ({ formData, detail, setFieldValue, isEditing }) => {
    const { formField, values, errors, touched } = formData;
    const { name, description, active, price, interval } = formField;
    const {
        name: nameV,
        description: descriptionV,
        active: activeV,
        price: priceV,
        interval: intervalV
    } = values;

    return (
        <MDBox>
            <MDBox mt={1.625}>
                <Grid container rowSpacing={1} columnSpacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV?.trim()?.length > 0 && !errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={description.type}
                            label={description.label}
                            name={description.name}
                            value={descriptionV}
                            placeholder={description.placeholder}
                            success={descriptionV?.trim()?.length > 0 && !errors.description}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={price.type}
                            label={price.label}
                            name={price.name}
                            value={priceV}
                            placeholder={price.placeholder}
                            error={errors.price && touched.price}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={
                                <span
                                    style={{
                                        color: "#000",
                                        fontWeight: "normal",
                                    }}
                                >
                                    {active.label}
                                </span>
                            }
                            checked={activeV}
                            onChange={() => setFieldValue("active", !activeV)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <FormControl>
                        <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            style={{
                                color: "#7b809a",
                                fontSize: "12px",
                                fontWeight: "normal",
                            }}
                        >
                            {interval.label}
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={interval.name}
                            value={intervalV}
                            onChange={(event, value) => {
                                setFieldValue(
                                    "interval",
                                    value
                                );
                            }}
                        >
                            <FormControlLabel
                                value="month"
                                control={<Radio />}
                                label={
                                    <span
                                        style={{
                                            color: "#000",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        Month
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="year"
                                control={<Radio />}
                                label={
                                    <span
                                        style={{
                                            color: "#000",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        Year
                                    </span>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
};

export default CouponForm;
