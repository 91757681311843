import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_ENDPOINTS from "apiConfig";
import BaseLayout from "layouts/pages/account/components/BaseLayout";

import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  capitalizeFirstLetter,
  splitUnderscoreWithCaps,
  changeDateFormatWithTimeZone,
} from "lib/helper";
import AuthContext from "context/Authcontext";
import UserList from "../UserList";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import GlobalStateContext from "context/GlobalStateContext";

const Details = () => {
  const [coupon, setCoupon] = useState(null);
  const [modifiedCouponData, setModifiedCouponData] = useState(null);
  const { profileDetails, breadCrumbsText, setBreadCrumbsText } = useContext(AuthContext);
  const {showCouponEditComp, setShowCouponEditComp, editedCoupon, setEditedCoupon} = useContext(GlobalStateContext);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigation = useNavigate();

  const handleModifyCouponData = (data) => {
    let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (Object.keys(data).length > 0) {
      let modifiedData = Object.entries(data).map(([key, value]) => {
        switch (key) {
          case "duration":
            value = capitalizeFirstLetter(value);
            break;

          case "discount_type":
            value = splitUnderscoreWithCaps(value);
            break;
          case "redemptions_count":
            if (!value) {
              value = 0;
            }
            break;
          case "valid_till":
            value = changeDateFormatWithTimeZone(value, timeZone);
            break;
          case "created_at":
            value = changeDateFormatWithTimeZone(value, timeZone);
            break;
          case "updated_at":
            value = changeDateFormatWithTimeZone(value, timeZone);
            break;

          default:
            break;
        }

        return {
          key: splitUnderscoreWithCaps(key),
          value,
        };
      });

      setModifiedCouponData(modifiedData);
    }
  };

  const getCoupon = async (id) => {
    try {
      let response = await fetch(API_ENDPOINTS.getCouponById + `${id}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        setCoupon(jsonResponse);
        handleModifyCouponData(jsonResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClicked = () => {
    setShowCouponEditComp(true);
    setEditedCoupon(coupon);
    navigation("/pages/stripe/coupons");
  };

  const { id } = useParams();
  useEffect(() => {
    getCoupon(id);
  }, [id]);

  useEffect(() => {
    if (coupon) {
      setBreadCrumbsText(coupon?.name);
    }

    return () => setBreadCrumbsText("");
  }, [coupon]);

  return (
    <BaseLayout>
      <Card id="coupons-list-card">
        <MDBox p={3}>
          <MDBox display="flex" alignItems="center" justifyContent="space-between">
              <MDButton
                color={darkMode ? "white" : "dark"}
                variant="outlined"
                onClick={() => navigation("/pages/stripe/coupons")}
              >
                <Icon>arrow_back</Icon>&nbsp;Back
              </MDButton>
              <MDButton
                color={darkMode ? "white" : "dark"}
                variant="outlined"

                onClick={handleEditClicked}
              >
                <Icon>edit</Icon>&nbsp;edit
              </MDButton>
          </MDBox>
          <MDBox mt={2}>
          <MDTypography variant="h6" fontWeight="medium" mb={2}>
              Coupon Details
            </MDTypography>
          </MDBox>
          {modifiedCouponData && (
            <Grid container columnSpacing={4}>
              {modifiedCouponData.map(({ key, value }) => (
                <Grid item sm={12} md={6}>
                  <MDBox key={key} display="flex" py={0.8} alignItems="center">
                    <MDBox display="flex" alignItems="center">
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                        sx={{ width: "160px" }}
                      >
                        {key}&nbsp;
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        : &nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{value || "N/A"}
                    </MDTypography>
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          )}
        </MDBox>

        {coupon && (
          <MDBox mt={-1}>
            <UserList couponId={coupon.id} />
          </MDBox>
        )}
      </Card>
    </BaseLayout>
  );
};

export default Details;
