const form = {
  formId: "new-coupon-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
      invalidMsg: "Name must be at least 3 character",
      required: true,
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      required: false,
    },
    duration: {
      name: "duration",
      label: "Discount duration",
      type: "text",
      options: [
        {
          value: "forever",
          label: "Forever (Discount for every months or years).",
        },
        {
          value: "once",
          label: "Once (Discount for first month or year).",
        },
        {
          value: "repeating",
          label: "Repeating (Discount for limited months or years).",
        },
      ],
      errorMsg: "Duration is required.",
      required: true,
    },
    code: {
      name: "code",
      label: "Code (Minimum 6 alphanumeric characters.)",
      type: "text",
      errorMsg: "Code is required.",
      invalidMsg: "Code must be at least 6 characters.",
      required: true,
    },
    discount_type: {
      name: "discount_type",
      label: "Discount Type",
      type: "text",
      errorMsg: "Discount Type is required.",
      required: true,
    },
    percent_off: {
      name: "percent_off",
      label: "Percent Off (Numbers only [0-100].)",
      type: "number",
      errorMsg: "Percent Off is required.",
      invalidMsg: "Percent Off must be in range of 0 to 100",
      required: true,
    },
    amount_off: {
      name: "amount_off",
      label: "Amount Off (Numbers only.)",
      type: "number",
      errorMsg: "Amount Off is required.",
      invalidMsg: "Amount Off must be in greater then 0",
      required: true,
    },
    duration_in_months: {
      name: "duration_in_months",
      label: "Duration In Months",
      type: "number",
      errorMsg: "Duration In Months is required.",
      invalidMsg: "Duration In Months must be in greater then 0",
      required: true,
    },
    max_redemptions: {
      name: "max_redemptions",
      label: "Max Redemptions",
      type: "number",
      errorMsg: "Max Redemptions is required.",
      invalidMsg: "Max Redemptions must be in range of 0 to 100",
      required: false,
    },
    redeem_by: {
      name: "redeem_by",
      label: "Redeem By",
      type: "datetime-local",
      errorMsg: "Redeem By is required.",
      required: true,
    },
    trial_period: {
      name: "trial_period",
      label: "Add Trial (Number of Days)",
      type: "number",
      errorMsg: "Free trial  should be more than 0.",
      required: false,
    },

    lifetime_access_coupon: {
      name: "lifetime_access_coupon",
      label: "Lifetime Access",
      type: "checkbox",
      required: false,
    },
  },
};

export default form;
