import React, { useEffect, useState } from "react";
import MDMenu from "components/MDMenu";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { FaSort } from "react-icons/fa";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

const SortMenu = ({ sortingParams, setSortingParams, fetchVideos }) => {
  const [showSortMenu, setShowSortMenu] = useState(false);
  const [sortTitle, setSortTitle] = useState("");
  const [sortingOptions, setSortingOptions] = useState([
    { name: "Sort by Name A-Z", value: "video_title asc" },
    { name: "Sort by Name Z-A", value: "video_title desc" },
    { name: "Latest First", value: "created_at desc" },
    { name: "Oldest First", value: "created_at asc" },
  ]);

  const handleTypeChange = (option) => {
    setSortingParams({ ...sortingParams, type: option.value });
    localStorage.setItem("sortingParams", option.value);
  };

  useEffect(() => {
    if (sortingParams) {
      const option = sortingOptions.find((option) => option.value === sortingParams.type);
      setSortTitle(option?.name);
    }
  }, [sortingParams]);

  return (
    <MDBox>
      <MDMenu
        openMenu={showSortMenu}
        setOpenMenu={setShowSortMenu}
        menuWidth={175}
        triggerElement={
          <>
            <MDBox ml={0}>
              <MDButton
                className="filter_sort_button margin-l-0"
                ml={0}
                variant="gradient"
                color="info"
                size="small"
                onClick={() => setShowSortMenu(!showSortMenu)}
              >
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                  {sortTitle && (
                    <MDTypography variant="button" color="white" fontWeight="regular">
                      {" "}
                      {sortTitle}{" "}
                    </MDTypography>
                  )}
                  <MDBox ml={1} sx={{ display: "flex", alignItems: "center" }}>
                    <FaSort color="white" />
                  </MDBox>
                </MDBox>
              </MDButton>
            </MDBox>
          </>
        }
      >
        <MDBox>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12}>
              {sortingOptions.map((option) => (
                <MenuItem
                  className={`dropdown-menu-item ${
                    option.value === sortingParams?.type ? "active" : ""
                  }`}
                  onClick={() => handleTypeChange(option)}
                  key={option.value}
                  value={option.value}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Grid>
          </Grid>
        </MDBox>
      </MDMenu>
    </MDBox>
  );
};

export default SortMenu;
