import React, { useState, Fragment, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import API_ENDPOINTS from "apiConfig";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import toast from "react-hot-toast";

export default function MoveVideo({
  video,
  showMoveVideo,
  setShowMoveVideo,
  setRefresh,
  closeMenu,
  folderList,
  currentFolder,
}) {
  const [open, setOpen] = useState(false);
  const [updatedFolderList, setUpdatedFolderList] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(currentFolder || "");

  const handleClose = () => {
    setOpen(false);
    setShowMoveVideo(false);
    closeMenu();
  };

  const handleRenameSave = async () => {
    try {
      setRefresh(false);
      const id = parseInt(video?.id);
      const response = await fetch(API_ENDPOINTS.renameVideo + `/${id}`, {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (response.ok) {
        toast.success("Video renamed successfully");
        handleClose();
        setRefresh(true);
      } else {
        throw new Error("Failed to rename video");
      }
    } catch (error) {
      toast.error(error?.message || "Failed to rename video.");
    }
  };

  const handleMove = async (directory) => {
    try {
      setSelectedFolder(directory);
      if (currentFolder === directory) {
        toast.error("Cannot move to same folder");
        return;
      }
      setRefresh(false);
      const response = await fetch(API_ENDPOINTS.moveVideos, {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          videoIds: [video.id],
          to: directory,
        }),
      });

      if (response.ok) {
        handleClose();
        setRefresh(true);
        toast.success("Video moved successfully");
      } else {
        throw new Error("Failed to move video");
      }
    } catch (error) {
      console.error("Error moving video:", error?.message);
    }
  };

  useEffect(() => {
    if (folderList && folderList?.length > 0) {
      const newFolderList = folderList.filter((item, index) => {
        if (item.directory_name !== "All" && item.directory_name !== "Favourites") {
          return item;
        }
      });

      setUpdatedFolderList(newFolderList);
    }
  }, [folderList]);

  useEffect(() => {
    setOpen(showMoveVideo);
  }, [showMoveVideo]);

  return (
    <Fragment>
      <Dialog
        className="dialog-box"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: { xs: "100%", sm: "80%", md: "70%", lg: "40%", xl: "30%" },
            height: "auto",
            py: 0,
            my: 0,
          },
        }}
      >
        <MDBox p={2}>
          <MDBox
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              py: 0,
              my: 0,
            }}
          >
            <MDBox sx={{ display: "flex", alignItems: "center", width: "94%", px: 0.5 }}>
              <MDTypography variant="h5" fontWeight="medium">
                Select the folder where you want to move the video to
              </MDTypography>
            </MDBox>
            <MDBox
              mt={0.7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "5%",
              }}
            >
              <CloseIcon sx={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={handleClose} />
            </MDBox>
          </MDBox>
          <Divider
            className="divider-color"
            color="info"
            sx={{ bgcolor: "#344767", mb: 2, mt: 1 }}
          />
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2} className="folder-list">
                {updatedFolderList && updatedFolderList?.length > 0 ? (
                  updatedFolderList.map((item, index) => (
                    <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
                      <MDButton
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          overflowX: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        variant={selectedFolder === item?.directory_name ? "contained" : "outlined"}
                        color="info"
                        fullWidth
                        className={`flex-ellipsis border-1 cursor-pointer folder-box ${
                          selectedFolder === item?.directory_name ? "active" : ""
                        } `}
                        p={1}
                        onClick={() => handleMove(item?.directory_name)}
                      >
                        {item?.directory_name}
                      </MDButton>
                    </Grid>
                  ))
                ) : (
                  <Grid>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        No Folders Found
                      </MDTypography>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
    </Fragment>
  );
}
