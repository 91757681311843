import { createContext, useState } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [showCouponEditComp, setShowCouponEditComp] = useState(false);
    const [editedCoupon, setEditedCoupon] = useState({});
    return (
        <GlobalStateContext.Provider value={{
            showCouponEditComp, 
            setShowCouponEditComp,
            editedCoupon, 
            setEditedCoupon
        }}>
        {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateContext