import React, { useEffect, useState } from "react";
import { Button, List, ListItem, ListItemText, Slide, Box } from "@mui/material";
import MDButton from "components/MDButton";

const TimeSlotSelector = ({slots,onChange,nextPage}) => {
  const [selectedTime, setSelectedTime] = useState(null);
useEffect(()=>{},[slots])
  const times = slots ? slots:[]

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  return (
    <List sx={{ padding: 0, width: {xs: "95%", md: "210px"}, ml:{xs:0, md:6},overflow: 'auto' , height: '400px', backgroundColor: "#ffffff"}}>
      {times.map((time, index) => (
        <ListItem
        onClick={() => { handleTimeClick(time); onChange(time); }}
        key={time.start}
        sx={{
          py: 0.5,
          my: 0,
          borderBottom: 0,
          borderColor: "divider",
          backgroundColor: '#ffffff',
        }}
      >
        <Button
         disableRipple
         variant={selectedTime === time ? 'contained' : 'outlined'}
         color="primary"
          sx={{ width: "100%",
          color: selectedTime === time ? '#ffffff' : '#0096FF',
          "&:hover": {
            borderColor: "#0096FF", 
          }, 
        }}
          
        >
          {time.updatedStart}
        </Button>
        {selectedTime === time && (
          <Slide direction="left" in={selectedTime === time} mountOnEnter unmountOnExit>
            <Box sx={{ marginLeft: "10px", color:"white" }}>
              <MDButton color="info" onClick={() => { nextPage(); }}>
                Next
              </MDButton>
            </Box>
          </Slide>
        )}
      </ListItem>
      ))}
    </List>
  );
};

export default TimeSlotSelector;
