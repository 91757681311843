import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import LockIcon from '@mui/icons-material/Lock';
import stripeImage from "assets/crmimages/stripe.png";
const StripeSecure = () => {
  return (
    <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <MDTypography variant="subtitle2" fontWeight="regular" gutterBottom align="center" sx={{ mt: 1 }}>
                                        Credit Card/Debit Card information is required.
                                    </MDTypography>
                                    <MDTypography variant="subtitle2" fontWeight="regular" gutterBottom align="center" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <LockIcon style={{ marginRight: "4px" }}  />
                                        Guaranteed safe & secure checkout  <img src={stripeImage} style={{ marginLeft: "8px" }}  />
                                    </MDTypography>
                                </MDBox>
  )
}

export default StripeSecure