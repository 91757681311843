import React, { useState, Fragment, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import API_ENDPOINTS from "apiConfig";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import Divider from "@mui/material/Divider";

export default function DeleteVideo({
  video,
  showDeleteVideo,
  setShowDeleteVideo,
  setRefresh,
  closeMenu,
}) {
  const [open, setOpen] = useState(false);
  const [VideoName, setVideoName] = useState("");

  const handleClose = () => {
    setOpen(false);
    setShowDeleteVideo(false);
    setVideoName("");
    closeMenu();
  };

  const handleDeleteVideo = async () => {
    try {
      setRefresh(false);
      const id = parseInt(video?.id);
      const response = await fetch(`${API_ENDPOINTS.deleteVideo}${id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (response.status === 204) {
        toast.success("Video deleted successfully");
        handleClose();
        setRefresh(true);
      } else {
        throw new Error("Failed to delete video");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Failed to rename video.");
    }
  };

  useEffect(() => {
    setVideoName(video?.video_title);
  }, [video]);
  useEffect(() => {
    setOpen(showDeleteVideo);
  }, [showDeleteVideo]);

  return (
    <Fragment>
      <Dialog
        className="dialog-box"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: { xs: "100%", sm: "80%", md: "70%", lg: "40%", xl: "30%" },
            height: "auto",
          },
        }}
      >
        <MDBox p={2}>
          <MDBox sx={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
            <MDTypography variant="h5" fontWeight="medium">
              Are you sure you want to delete this video?
            </MDTypography>
            <MDBox>
              <CloseIcon sx={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={handleClose} />
            </MDBox>
          </MDBox>
          <Divider
            className="divider-color"
            color="info"
            sx={{ bgcolor: "#344767", mb: 1, mt: 1 }}
          />
          <MDBox sx={{ display: "flex", overflowX: "hidden", textOverflow: "ellipsis" }}>
            <MDTypography variant="body1" fontWeight="regular" noWrap fullWidth>
              {VideoName}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 1 }}>
            <MDBox sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <MDButton variant="contained" color="dark" onClick={handleClose}>
                Cancel
              </MDButton>
              <MDButton variant="contained" color="dark" sx={{ ml: 2 }} onClick={handleDeleteVideo}>
                Delete
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Dialog>
    </Fragment>
  );
}
