import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Person2Icon from '@mui/icons-material/Person2';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AuthContext from "context/Authcontext";
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa";

function UserInfo({ onNameChange, onEmailChange, onMettingDetailsChange, onSubmit, syncGoogleCalendar, setSyncGoogleCalendar, syncMicrosoftCalendar, setSyncMicrosoftCalendar }) {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const {profileDetails, getProfileDetails} = useContext(AuthContext);

  const validateAndSubmit = (event) => {
    event.preventDefault();

    if (!validateName() || !validateEmail()) {
      return;
    }
    onSubmit(event);
  };

  const validateName = () => {
    const name = document.forms['userInfoForm']['name'].value;
    const isValid = name.trim() !== '';
    setNameError(!isValid);
    return isValid;
  };

  const validateEmail = () => {
    const email = document.forms['userInfoForm']['email'].value;
    const isValid = email.trim() !== '' && /\S+@\S+\.\S+/.test(email);
    setEmailError(!isValid);
    return isValid;
  };

  const handleGoogleCalendarChange = () => {
     setSyncGoogleCalendar(!syncGoogleCalendar);
     if(!syncGoogleCalendar) {
      setSyncMicrosoftCalendar(false)
     }
  } 

  const handleMicrosoftCalendarChange = () => {
    setSyncMicrosoftCalendar(!syncMicrosoftCalendar)
    if(!syncMicrosoftCalendar) {
      setSyncGoogleCalendar(false);
    }
 } 

  useEffect(() => {
    async function fetchProfileDetails() {
      if (!profileDetails || Object.keys(profileDetails).length == 0) {
         await getProfileDetails();
      }
    }
  
    fetchProfileDetails();
  
  }, []);

  return (
    <MDBox p={3} sx={{ minWidth: "300px", height: "100%" }}>
      <MDBox mb={2} lineHeight={0}>
        <MDTypography variant="h3" sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Person2Icon fontSize="medium" sx={{ marginRight: "4px" }} />
          User Information
        </MDTypography>
      </MDBox>
      <MDBox component="form" noValidate onSubmit={validateAndSubmit} name="userInfoForm">
        <Grid container xs={12} sx={{ gap: 3 }} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <TextField
              type="text"
              label="Name"
              name="name"
              fullWidth
              onChange={(e) => {
                onNameChange(e.target.value);
                validateName();
              }}
              error={nameError}
              helperText={nameError ? "Name cannot be blank" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="email"
              label="Email"
              name="email"
              fullWidth
              onChange={(e) => {
                onEmailChange(e.target.value);
                validateEmail();
              }}
              error={emailError}
              helperText={emailError ? "Invalid email format" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              label="Meeting Details"
              name="meetingDetails"
              fullWidth
              multiline
              maxRows={4}
              rows={4}
              onChange={(e) => {
                onMettingDetailsChange(e.target.value);
              }}
            />
          </Grid>
          {!profileDetails?.calendar_info ? (<Grid item xs={12} sm={6} sx={{padding:"0px 0px", margin:"0px 0px"}}>
            <FormControlLabel sx={{margin:"0px 0px", padding:"0px 0px", display:"flex", alignItems:"center"}}
              control={<Checkbox sx={{margin:"0px 0px", padding:"0px 0px"}} />}
              label={
                <MDBox sx={{display:"flex", alignItems:"center", marginTop:"-2px"}}>
                <FcGoogle size={16} className="mr-5"/>
                <span
                  style={{
                    color: "#000",
                    fontWeight: "normal",
                  }}
                >
                  Sync to google
                </span>
                </MDBox>
              }
              checked={syncGoogleCalendar}
              onChange={handleGoogleCalendarChange}
            />
          </Grid>):""}
          {!profileDetails?.calendar_info ? (<Grid item xs={12} sm={5} sx={{padding:"0px 0px", margin:"0px 0px", display:"flex", justifyContent:{xs:"flex-start", sm:"flex-end"}}}>
            <FormControlLabel sx={{margin:"0px 0px", padding:"0px 0px", display:"flex", alignItems:"center"}}
              control={<Checkbox sx={{margin:"0px 0px", padding:"0px 0px"}} />}
              label={
                <MDBox sx={{display:"flex", alignItems:"center", marginTop:"-2px"}}>
                <FaMicrosoft size={16} className="mr-5 ml-5"/>
                <span
                  style={{
                    color: "#000",
                    fontWeight: "normal",
                  }}
                >
                  Sync to microsoft
                </span>
                </MDBox>
              }
              checked={syncMicrosoftCalendar}
              onChange={handleMicrosoftCalendarChange}
            />
          </Grid>):""}
          <Grid item xs={12} sx={{ mb: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
            <MDButton type="submit" variant="contained" color="info" fullWidth>
              SCHEDULE NOW
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default UserInfo;
