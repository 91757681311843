import React, { useState, Fragment, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import API_ENDPOINTS from "apiConfig";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa";
import MDBadge from "components/MDBadge";
import AuthContext from "context/Authcontext";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import Divider from "@mui/material/Divider";
import toast from "react-hot-toast";

export default function CreateDirectory({
  showCreateDirectory,
  setShowCreateDirectory,
  fetchDirectories,
}) {
  const [open, setOpen] = useState(false);
  const [directoryName, setDirectoryName] = useState("");

  const handleClose = () => {
    setOpen(false);
    setShowCreateDirectory(false);
    setDirectoryName("");
  };

  const CreateNewDirectory = async () => {
    if (!directoryName) {
      toast.error("Directory name is required");
      return;
    }
    try {
      const res = await fetch(API_ENDPOINTS.addNewDirectory, {
        method: "POST",
        body: JSON.stringify({
          directory: directoryName,
        }),
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.ok) {
        toast.success("Directory created successfully");
        await fetchDirectories();
        handleClose();
      } else throw "error in the database";
    } catch (err) {
      toast.error("some error occured during update");
    }
  };

  const handleOnChangeValue = (event) => {
    setDirectoryName(event.target.value);
  };

  useEffect(() => {
    setOpen(showCreateDirectory);
  }, [showCreateDirectory]);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: { xs: "100%", sm: "80%", md: "70%", lg: "40%", xl: "30%" },
            height: "auto",
          },
        }}
      >
        <MDBox>
          <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center", pb: 0, mb: 0 }}>
            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Create New Folder
              </MDTypography>
              <MDBox>
                <CloseIcon sx={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={handleClose} />
              </MDBox>
            </MDBox>
            <Divider />
          </DialogTitle>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 2 }}
            >
              <Grid item xs={8}>
                <MDInput
                  fullWidth
                  label="Folder Name"
                  name="folderName"
                  value={directoryName}
                  onChange={handleOnChangeValue}
                  required
                />
              </Grid>

              <Grid item xs={4}>
                <MDButton variant="contained" color="dark" fullWidth onClick={CreateNewDirectory}>
                  Save
                </MDButton>
              </Grid>
            </Grid>
          </DialogActions>
        </MDBox>
      </Dialog>
    </Fragment>
  );
}
