import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import { paginationConstants } from "constants/pagination";
import AuthContext from "context/Authcontext";
import Details from "../Details";
import API_ENDPOINTS from "apiConfig";

import { changeDateFormatWithTimeZone } from "lib/helper";
import TableContext from "context/Tablecontext";
import Tooltip from "@mui/material/Tooltip";
import MDTable from "components/MDTables";
import FilterMenu from "components/FilterMenu";
import DropDownMenu from "../DropDownMenu";
import MDButton from "components/MDButton";
import { ImCross } from "react-icons/im";

// function
import { capitalizeFirstLetter } from "lib/helper";
import { options } from "constants/activityLog";

const List = () => {
  const [refresh, setRefresh] = useState(true);
  const [activityList, setActivityList] = useState([]);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [viewDetails, setViewDetails] = useState(false);
  const [activeActivity, setActiveActivity] = useState(null);
  const { getProfileDetails, profileDetails } = useContext(AuthContext);

  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
  } = useContext(TableContext);
  const [canSearch, setCanSearch] = useState(true);
  const [activeTable, setActiveTable] = useState("bug-reports");
  const [filteringParams, setFilteringParams] = useState([]);

  const handleViewDetails = (activityLog) => {
    setViewDetails(true);
    setActiveActivity(activityLog);
  };

  const ButtonCell = ({ activity, value, classNameProp }) => {
    return (
      <MDBox
        sx={{ display: "flex", width: "100%", maxWidth: "100%", cursor: "pointer" }}
        className="list-button-cell"
        onClick={(e) => {
          e.preventDefault();
          handleViewDetails(activity);
        }}
      >
        <MDTypography
          variant="caption"
          fontWeight="medium"
          color="text"
          className="list-button-cell"
        >
          {value}
        </MDTypography>
      </MDBox>
    );
  };

  const loadData = (activityData) => {
    if (activityData && activityData.length > 0) {
      let columns = [
        {
          Header: "id",
          accessor: "id",

          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "causer_id",
          accessor: "causer_id",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "causer_type",
          accessor: "causer_type",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "subject_id",
          accessor: "subject_id",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "subject_type",
          accessor: "subject_type",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "event",
          accessor: "event",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "log_name",
          accessor: "log_name",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "request_origin",
          accessor: "request_origin",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "endpoint",
          accessor: "endpoint",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "response_status",
          accessor: "response_status",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
        {
          Header: "created_at",
          accessor: "created_at",
          Cell: ({ value: [value, activity] }) => <ButtonCell value={value} activity={activity} />,
        },
      ];

      let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      let rows = activityData.map((activity) => {
        const created_at = activity?.created_at
          ? changeDateFormatWithTimeZone(activity?.created_at, timeZone)
          : "N/A";

        const logName = activity?.log_name?.length > 0 ? capitalizeFirstLetter(activity?.log_name) : "N/A";
        const subjectType = activity?.subject_type?.length > 0 ? activity.subject_type.split("_").map(word => word[0].toUpperCase() + word.slice(1)).join(" ")  : "N/A";

        return {
          id: [activity.id.toString(), activity],
          causer_id: [activity.causer_id?.toString() || "N/A", activity],
          causer_type: [activity.causer_type || "N/A", activity],
          subject_id: [activity.subject_id?.toString() || "N/A", activity],
          subject_type: [subjectType || "N/A", activity],
          event: [activity.event || "N/A", activity],
          log_name: [logName || "N/A", activity],
          request_origin: [activity.request_origin || "N/A", activity],
          endpoint: [activity.endpoint || "N/A", activity],
          response_status: [activity.response_status?.toString() || "N/A", activity],
          created_at: [created_at || "N/A", activity],
        };
      });

      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const getActivityList = async () => {
    let query = "";
    query += `page=${currentPage}&limit=${pageSize}`;

    if (filteringParams?.length > 0) {
      const filterText = filteringParams
        .filter((params) => params?.value?.length > 0)
        .map((params) => `${params.type}=${params.value}`);
      query += `&${filterText.join("&")}`;
    }

    if (search) {
      query += `&search=${search}`;
    }

    if (orderBy && order) {
      let sortOrder = order === "asc" ? "ASC" : "DESC";
      query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
    }

    try {
      let response = await fetch(API_ENDPOINTS.activityLog + `?${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      let jsonResponse = await response.json();
      if (response.ok) {
        const { data } = jsonResponse;
        setActivityList(data.items);
        loadData(data.items);
        const startIndex = (currentPage - 1) * pageSize + 1;
        const endIndex = Math.min(currentPage * pageSize, data.totalItems);
        setEnteries({
          ...enteries,
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          totalMediaCount: data.totalPages,
          enteriesStart: startIndex,
          enteriesEnd: endIndex,
        });
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (error) {
      console.error("Something went wrong", error);
    }
  };

  const handleRemoveFilter = (type) => {
    let index = filteringParams?.findIndex((params) => params.type === type);
    if (index !== -1) {
      setFilteringParams((filteringParams) => [
        ...filteringParams.slice(0, index),
        ...filteringParams.slice(index + 1),
      ]);
    }
  };

  const addDataInFilteringParams = () => {
    let filterData = localStorage.getItem("activityLogFilteringParams");
    if (filterData === null) {
      setFilteringParams([]);
    } else {
      filterData = JSON.parse(filterData);
      if (typeof filterData === "object" && filterData.length > 0) {
        setFilteringParams(filterData);
      }
    }
  };

  const addFiteringParamsToStorage = () => {
    if (filteringParams?.length > 0) {
      localStorage.setItem("activityLogFilteringParams", JSON.stringify(filteringParams));
    } else {
      localStorage.setItem("activityLogFilteringParams", JSON.stringify([]));
    }
  };

  const handleStopRefresh = () => {
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      initializeTable();
      addDataInFilteringParams();
      getActivityList();
      handleStopRefresh();
    }
  }, [refresh]);

  useEffect(() => {
    if (!refresh) {
      getActivityList();
    }
  }, [pageSize, currentPage, orderBy, order]);

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }

    const timeoutId = setTimeout(() => {
      if (!refresh) {
        getActivityList();
      }
    }, 500);
    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (!refresh && filteringParams) {
      addFiteringParamsToStorage();
      getActivityList();
    }
  }, [filteringParams]);

  return (
    <>
      {!viewDetails ? (
        <Card id="user-list-card">
          <MDBox p={2}>
            <MDBox display="flex" alignItems="center" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium">
                List Activities
              </MDTypography>
            </MDBox>
            <MDBox my={2}>
              <MDTable table={dataTable} canSearch={canSearch} canFilter={true}>
                <MDBox p={2}>
                  <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: { xs: 2.8, sm: 0, md: 0 },
                        width: {
                          xs: "100%",
                          sm: "auto",
                          md: "auto",
                        },
                        justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                      }}
                    >
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                          mr: { xs: 0, sm: 0.2, md: 0 },
                        }}
                      >
                        {filteringParams?.length > 0 && (
                          <MDBox
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            flexWrap="wrap"
                          >
                            {filteringParams.map((params, index) => {
                              const { type, value } = params;
                              const filteredtitle = type
                                ?.split("_")
                                .map((word) => word[0].toUpperCase() + word.slice(1))
                                .join(" ");
                              const filteredValue = value
                                ?.split("_")
                                .map((word) => word[0].toUpperCase() + word.slice(1))
                                .join(" ");
                              return (
                                <>
                                  <MDButton
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    sx={{ ml: index !== 0 ? 1 : 0, my: 0.5 }}
                                  >
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      mx={0}
                                      px={0}
                                    >
                                      <Tooltip
                                        disableFocusListener
                                        disableTouchListener
                                        title="Clear Filter"
                                        placement="top"
                                      >
                                        <MDBox
                                          sx={{ display: "flex", alignItems: "center", pr: 1.5 }}
                                        >
                                          <ImCross
                                            size={12}
                                            color="info"
                                            className="icon-color cursor-pointer"
                                            onClick={() => handleRemoveFilter(type)}
                                          />
                                        </MDBox>
                                      </Tooltip>
                                      <MDBox
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          position: "relative",
                                        }}
                                      >
                                        <MDTypography
                                          variant="button"
                                          className="icon-color"
                                          fontWeight="bold"
                                          noWrap
                                        >
                                          {filteredtitle}: {filteredValue}
                                        </MDTypography>
                                        <MDBox className="line-over-text" />
                                      </MDBox>
                                    </MDBox>
                                  </MDButton>
                                </>
                              );
                            })}
                          </MDBox>
                        )}
                      </MDBox>
                      <MDBox
                        sx={{
                          ml: 1.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                        }}
                      >
                        <FilterMenu menuWidth={192}>
                          <DropDownMenu
                            filteringParams={filteringParams}
                            setFilteringParams={setFilteringParams}
                            options={options}
                          />
                        </FilterMenu>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDTable>
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        <Details activeActivity={activeActivity} onClose={() => setViewDetails(false)} />
      )}
    </>
  );
};

export default List;
