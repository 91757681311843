import * as React from "react";
import { Box, Typography, Container, Link, Paper, List, ListItem } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { questions } from "constants/affiliate";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
const AffiliatePolicies = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="md">
        <Box m={7}>
          <Typography variant="h3" component="h1" paragraph>
            AFFILIATE PROGRAM TERMS
            <br />
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to make changes moving forward. <br />
          </Typography>
          Sign up to become an affiliate.{" "}
          <MDButton color="primary" variant="gradient" size="large" onClick={() => {navigate("/affiliate")}}>
            Register
          </MDButton>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Box m={7}>
          <Typography variant="h3" component="h1" gutterBottom>
            SIMPLE EXPLANATION
          </Typography>
          <Typography variant="body1" paragraph>
            Currently, we are offering affiliates for the Skoop application the following:
          </Typography>
          <div className="left-padding-40">
            <Typography variant="body1" paragraph>
              1. By signing up, you agree to the terms as described here.
            </Typography>
            <Typography variant="body1" paragraph>
              2. Affiliates must be a paid user of Skoop. If you are not, you must purchase a single
              license.
            </Typography>
            <Typography variant="body1" paragraph>
              3. For now, we require a STRIPE account but later we will add PayPal. You can sign up
              with STRIPE to get a free account as an individual or company.
            </Typography>
            <Typography variant="body1" paragraph>
              4. If you are at some point no longer a paid user, you will lose your affiliate
              commissions moving forward.
            </Typography>
            <Typography variant="body1" paragraph>
              5. 40% of all sales (minus merchant and/or transaction or other fees) per payment for
              the first 12 months.
            </Typography>
            <Typography variant="body1" paragraph>
              6. After 12 months, payments are reduced to 20% per payment moving forward.
            </Typography>
            <Typography variant="body1" paragraph>
              7. We provide you with a unique 6-digit code that you give to the person(s)/customers
              purchasing.
            </Typography>
            <Typography variant="body1" paragraph>
              8. You provide that 6-digit code and the Chrome Store web link to the customer.
            </Typography>
            <Typography variant="body1" paragraph>
              9. The 6-digit code, if used to purchase, provides a discount (currently at USD 7).
            </Typography>
            <Typography variant="body1" paragraph>
              10. Once purchased using the 6-digit code, that person's account will be associated
              with your account for affiliate purposes.
            </Typography>
            <Typography variant="body1" paragraph>
              11. Payouts are monthly with a 45-day wait period to verify customer payments or
              refunds.
            </Typography>
            <Typography variant="body1" paragraph>
              12. You will have access to the dashboard that provides estimated payouts, dates, and
              so forth.
            </Typography>
            <Typography variant="body1" paragraph>
              13. Affiliates cannot transfer or sell your affiliate membership.
            </Typography>
            <Typography variant="body1" paragraph>
              14. If there is a high volume sale and the customer wants a different deal, it is then
              a separate agreement based on what is approved by all parties.
            </Typography>
            <Typography variant="body1" paragraph>
              15. Concerns or questions can be directed to support@skoopapp.com or the upcoming
              Slack channel.
            </Typography>
          </div>
        </Box>
        <Box m={7}>
          <Typography variant="h3" component="h1" gutterBottom>
            LEGAL STUFF
          </Typography>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="body1" paragraph>
              BETWEEN: You (the “Owner”)
            </Typography>
            <Typography variant="body1" paragraph>
              AND: Troy Hipoilto of The Troy Agency (the ”Recipient”), a [corporation // limited
              liability company] organized and existing under the laws of the [Nevada], with its
              head office located at:
            </Typography>
            <Typography variant="body2" paragraph>
              10111 Eden Mountain St Las Vegas NV 89141
              <br />
              <Link href="mailto:troy@thetroyagency.com">troy@thetroyagency.com</Link>
            </Typography>
            <Typography variant="body1" paragraph>
              In consideration of the terms and covenants of this agreement, and other valuable
              consideration, the parties agree as follows:
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
              RECITALS
            </Typography>
            <div className="left-padding-40">
              <List>
                <ListItem>
                  <Typography variant="body1">
                    a. The name of this affiliate program is the Skoop Affiliate Program (the
                    “Affiliate Program”).
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    b. The Recipient represents and warrants to the Owner that the Recipient has
                    read and understands the Owner{"\u2019"}s Privacy Policies, which are located at
                    https://crm.skoopapp.com/privacypolicy, and agrees to the terms set forth
                    therein.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    c. For purposes of this Agreement, the term “the Recipient” refers to the
                    individual or legal entity who applies for and is accepted into the Affiliate
                    Program. The term “the Owner” refers to the sponsor of the Affiliate Program.
                    The term “the Owner{"\u2019"}s website” refers to the website that the Owner
                    maintains at www.createholidaymagic.com. The term “the Recipient{"\u2019"}s
                    website” refers to the website or websites, as well as the social media
                    accounts, email campaigns, and other marketing channels associated with the
                    website(s), on which the Recipient agrees to place a link to the Owner{"\u2019"}
                    s website as specified in the Exhibit B hereof. “Merchandise” means all
                    products, merchandise, and stock that is offered by the Owner for sale through
                    the Owner{"\u2019"}s website.
                  </Typography>
                </ListItem>
              </List>
            </div>
          </Paper>
        </Box>
        <Box m={7}>
          <Typography variant="body3" paragraph>
            Click on any of the links below to read a summary for that topic.
          </Typography>
          {questions.map((item) => (
            <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="accordion-summary-content"
              >
                <Typography variant="h5">
                  {" "}
                  {item.id}. {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  {item.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Typography variant="body2" paragraph>
            Last updated 04/24/2024
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default AffiliatePolicies;
