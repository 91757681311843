

// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import UsersView from "layouts/pages/affiliate/myUsers/components/UsersView";

function MyUsers() {
  return (
    <BaseLayout stickyNavbar>
      <UsersView />
    </BaseLayout>
  );
}

export default MyUsers;
