import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import Icon from "@mui/material/Icon";

import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import MDTableHeadCell from "./MDTableHeadCell";
import MDTableBodyCell from "./MDTableBodyCell";

import { Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import TableContext from "context/Tablecontext";
import { filterHash } from "@fullcalendar/core/internal";

import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

const MDTable = ({ table, canSearch, children, ...rest }) => {
  const {
    pageSize,
    setPageSize,
    search,
    setSearch,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    currentPage,
    setCurrentPage,
    enteries,
    entriesPerPage,
    canFilter,
  } = useContext(TableContext);

  return (
    <MDBox className="mui-table">
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entriesPerPage.entries}
              onChange={(event, newValue) => {
                setPageSize(parseInt(newValue, 10));
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary" className="text-no-wrap">
              &nbsp;&nbsp;Entries per page
            </MDTypography>
            
            {rest?.licenceDetails?.allowed && (
              <>
              &nbsp;&nbsp;
              <Stack spacing={2} direction="row">
                <Chip label={`Total Licences: ${rest?.licenceDetails?.remainingLicenceCount + rest?.licenceDetails?.usedLicenceCount}`} variant="outlined" color="success" size="small"/>
                <Chip label={`Remaining Licences: ${rest?.licenceDetails?.remainingLicenceCount}`} variant="outlined" color="warning" size="small"/>
                <Chip label={`Active Licences: ${rest?.licenceDetails?.usedLicenceCount}`} variant="outlined" color="info" size="small"/>
              </Stack>
            </>
            )}
            
          </MDBox>
        )}
        <MDBox display="flex" alignItems="center" justifyContent="flex-end">

        {true && (
           <MDBox>
            {children}
           </MDBox>
        )}
        
        {canSearch && (
          <MDBox width="12rem" ml="auto">
            <MDInput
              placeholder="Search..."
              value={search}
              size="small"
              fullWidth
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </MDBox>
        )}
        </MDBox>
      </MDBox>
      <MDBox>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2, overflow: "hidden" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={rest.dense ? "small" : "medium"}
              >
                <MDBox component="thead" sx={{ ml: 0, mt: 0, pl: 0, pt: 0 }}>
                  <TableRow className="className='table-cell">
                    {table &&
                      table.columns?.map((headCell) => (
                        <MDTableHeadCell
                          headCell={headCell}
                          order={order}
                          setOrder={setOrder}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          width={headCell.minWidth ? headCell.minWidth : "auto"}
                          align={headCell.align ? headCell.align : "left"}
                          isSorted={headCell?.isSorted}
                        >
                          {headCell.Header}
                        </MDTableHeadCell>
                      ))}
                  </TableRow>
                </MDBox>
                <TableBody>
                  {table && table?.rows?.length > 0 ? (
                    <MDTableBodyCell
                      rows={table?.rows}
                      columns={table?.columns}
                      align="left"
                      padding="normal"
                    />
                  ) : (
                    <TableRow>
                      <TableCell
                        component="td"
                        colSpan="100%"
                        height="150px"
                        sx={{ borderBottom: 0 }}
                        width="100%"
                        maxWidth="100%"
                        className="table-col-cell"
                        align="center"
                        scope="row"
                      >
                        <MDTypography
                          variant="body"
                          color="secondary"
                          fontWeight="regular"
                          opacity={0.7}
                        >
                          No records found!
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {enteries && enteries?.totalPages >= 1 && (
              <MDBox
                my={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: { xs: "column", sm: "row", md: "row" },
                  mx: 3,
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                    alignItems: "center",
                    direction: { xs: "column", sm: "row", md: "row" },
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                        mb: { xs: 1, sm: 0, md: 0 },
                      }}
                    >
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        Showing {enteries?.enteriesStart} to {enteries?.enteriesEnd} of{" "}
                        {enteries?.totalItems} entries
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "center", sm: "flex-end", md: "flex-end" },
                      }}
                    >
                      <Pagination
                        size="large"
                        variant="gradient"
                        color="info"
                        count={enteries?.totalMediaCount}
                        page={currentPage}
                        onChange={(e, value) => setCurrentPage(value)}
                        className="custom-pagination"
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </Paper>
        </Box>
      </MDBox>
    </MDBox>
  );
};

// Setting default values for the props of DataTable
MDTable.defaultProps = {
  entriesPerPage: { entries: ["5", "10", "15", "20", "25"] },
  canSearch: true,
  canFilter: false,
};

// Typechecking props for the DataTable
MDTable.propTypes = {
  canSearch: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  canFilter: PropTypes.bool,
};

export default MDTable;
