import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Typography from "@mui/material/Typography";

const RemoveSession = ({ onDelete }) => {
  return (
    <Card sx={{ backgroundColor: "#F8D7DA", p: 1, mt: 1 }}>
      <MDBox sx={{ px: { xs: 0, sm: 2, md: 2, lg: 2 } }}>
        <MDTypography variant="subtitle2" color="dark" fontWeight="regular" textAlign="center">
          Session limit reached. Max 2 sessions allowed.{" "}
          <Typography
            component="span"
            variant="subtitle2"
            sx={{ cursor: "pointer", color: "#ff8210", textDecoration: "underline" }}
            fontWeight="medium"
            onClick={onDelete}
          >
            Logout all sessions
          </Typography>{" "}
          and log in again.
        </MDTypography>
      </MDBox>
    </Card>
    //
  );
};

export default RemoveSession;
