
// prop-type is a library for typechecking of props
import { ErrorMessage } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewUser page components
import FormField from "components/FormField";

const SubscriptionForm = ({ formData, detail, setFieldValue }) => {
    const { formField, values, errors, touched } = formData;
    const { name, status } = formField;
    const { name: nameV, status: statusV } = values;

    return (
        <MDBox>
            <MDBox mt={1.625}>
                <Grid container rowSpacing={1} columnSpacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            disableClearable={true}
                            options={status.visisbleOptions}
                            value={
                                status?.visisbleOptions?.find(
                                    (option) => option.value == statusV
                                ) || ""
                            }
                            onChange={(event, value) => {
                                if (value?.value) {
                                    setFieldValue("status", value?.value);
                                } else {
                                    setFieldValue("status", "");
                                }
                            }}
                            renderInput={(params) => (
                                <MDInput
                                    {...params}
                                    variant="standard"
                                    label={status.label}
                                />
                            )}
                        />
                        <MDBox mt={0.75}>
                            <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                            >
                                <ErrorMessage name={status.name} />
                            </MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
};

export default SubscriptionForm;
