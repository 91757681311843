/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";

function Chat({ chatId, chatPrompt, noGutter, onDelete, closeNew }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = (chatId==null)?useState(true):useState(false);
  const [editedHeading, setEditedHeading] = useState("");
  const [editedDescription, setEditedDescription] = useState("");


  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const addEntry=async(newHeading,newDescription)=>{
    try {
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chatgptprompt`,{
        method: "POST",
        body: JSON.stringify({
          heading: newHeading,
          description: newDescription
        }),
        headers: {
          "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      if(response.ok){
        toast.success("ChatGPT Prompt Created Successfully");
        closeNew();
      }
      else{
        let responseData = await response.json();
        throw new Error(responseData.message);
      } 
    }
    catch(error) {
      toast.error(error.message);
    }
  }

  const replaceEntry = async (newHeading,newDescription)=>{
    try {
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chatgptprompt/${chatId}`,{
        method: "PUT",
        body: JSON.stringify({
          id: chatId,
          heading: newHeading,
          description: newDescription
        }),
        headers: {
          "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      if(response.ok){
        toast.success("ChatGPT Prompt Updated Successfully");
        closeNew();
      }
      else{
        let responseData = await response.json();
        throw new Error(responseData.message);
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }

  const handleSave = async (e) => {
    e.preventDefault();
    if(!(editedHeading && editedDescription)) {
      let message;
      if( editedHeading === "") {
         message = "Heading field is required!";
      }
      else if( editedDescription === "") {
         message = "Description field is required!";
      }
      toast.error(message);
      return;
    }

    try{

      if(chatId==null){
        await addEntry(editedHeading,editedDescription);
      }
      else await replaceEntry(editedHeading,editedDescription);

      setIsEditing(false);
      setEditedHeading(editedHeading);
      setEditedDescription(editedDescription);
    }catch(err){
      toast.error(err?.message);
    }
  };

  const handleDelete = async() => {
    await onDelete();
  };

  useEffect(() => {
    if(chatPrompt && Object.keys(chatPrompt).length != 0) {
      setEditedHeading(chatPrompt.heading);
      setEditedDescription(chatPrompt.description);
    }
  }, [chatPrompt]);

  return (
    <MDBox component="form" onSubmit={handleSave}>
      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={noGutter ? 0 : 1}
        mt={2}
      >
        <MDBox width="100%" display="flex" flexDirection="column">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
          >
            {isEditing ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Heading"
                fullWidth
                value={editedHeading}
                onChange={(e) => setEditedHeading(e.target.value)}
              />
            ) : (
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {editedHeading}
              </MDTypography>
            )}
            <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
              <MDBox mr={1}>
                <MDButton variant="text" color="error" onClick={handleDelete}>
                  <Icon>delete</Icon>&nbsp;delete
                </MDButton>
              </MDBox>
              {isEditing ? (<MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                type="submit"
              >
                <Icon>save</Icon>&nbsp;Save
              </MDButton>):
              (
                <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                type={"button"}
                onClick={handleEdit}
              >
                <Icon>edit</Icon>&nbsp;Edit
              </MDButton>
              )}
            </MDBox>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="black">
              &nbsp;&nbsp;&nbsp;
              {isEditing ? (
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Description"
                  fullWidth
                  multiline
                  size="small"
                  color="black"
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                />
              ) : (
                <MDTypography variant="caption" fontWeight="medium">
                  {editedDescription}
                </MDTypography>
              )}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Chat.defaultProps = {
  noGutter: false,
};

Chat.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  newPreloaded: PropTypes.bool,
  noGutter: PropTypes.bool,
  onDelete: PropTypes.func,
  closeNew: PropTypes.func,
};

export default Chat;
