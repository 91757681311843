/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import React, { useEffect, useState, useContext } from "react";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";
import DataTable from "examples/Tables/DataTable";
import MyCalendar from "../MyCalendar";
import { toast } from "react-hot-toast";
import SyncingModal from "components/SyncingModal";
import AuthContext from "context/Authcontext";
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa";
import { SiTicktick } from "react-icons/si";
import { IoSettingsSharp } from "react-icons/io5";

function CalendarView() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [bills, setBills] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [events, setEvents] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const {calendarSync, activeAction, setActiveAction, profileDetails} = useContext(AuthContext)

  const refreshCallback = () => {
    setRefresh(true);
  }

  const handleSync = (sync) => {
    calendarSync(sync)
    setActiveAction("sync calendar");
    localStorage.setItem("activeAction", "sync calendar");
    if(profileDetails?.time_zone) {
      localStorage.setItem("timezone", profileDetails.time_zone);
    }
  }

  useEffect(() => {
    async function setup() {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/my-appointments`
        , {
          method: "GET",
          headers: {
            "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
            "Content-type": "application/json; charset=UTF-8"
          }
        })
        .then(res => res.json())
        .then(data => {
          setEvents(data)
        })
        .catch((err) => {
          toast.error(err.message);
        })
    }
    if (refresh) {
      setup();
      setRefresh(false)
    }
  }, [refresh])
  return (
    <>
    <Card id="delete-account">
      <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="medium">
          My Appointments
        </MDTypography>

              <MDButton variant="outlined" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} color={profileDetails?.calendar_info ? 'success' : 'info' } onClick={()=>{setShowDialog(true)}}>
              {
                  profileDetails?.calendar_info ? (
                    profileDetails?.calendar_info === 'google' ? (
                      <><SiTicktick size={16} className="mr-10" /><FcGoogle size={16} className="mr-5" /> <MDTypography variant="body2" color="success" fontWeight="medium">Synced with Google</MDTypography><IoSettingsSharp size={16} className="ml-20" /></>
                    ) : (
                      <><SiTicktick size={16} className="mr-10" /><FaMicrosoft size={16} className="mr-5" /> <MDTypography variant="body2" color="success" fontWeight="medium">Synced with Microsoft</MDTypography><IoSettingsSharp size={16} className="ml-20" /></>
                    )
                  ) : (
                    <><Icon className="mr-5" >add</Icon><MDTypography variant="body2" color="info" fontWeight="medium"> Sync your calendar</MDTypography><IoSettingsSharp size={16} className="ml-20" /></>
                  )
                }
              </MDButton>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MyCalendar events={events} updateRefresh={refreshCallback} />
        </MDBox>

      </MDBox>
      <MDBox py={1}>

      </MDBox>
    </Card>
    <SyncingModal showDialog={showDialog} handleGoogleSync={()=> handleSync('google')} handleMicrosoftSync={()=> handleSync('microsoft')} setShowDialog={setShowDialog}/>
    </>
  );
}

export default CalendarView;