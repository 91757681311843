import { useState, useEffect, useContext } from "react";

// react-router-dom components
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import logo from "assets/crmimages/logo.png";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { useNavigate, useLocation } from "react-router-dom";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Grid from "@mui/material/Grid";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import skoopCrmBanner from "assets/crmimages/bg-sign-in-cover.jpeg";

import API_ENDPOINTS from "../../../../apiConfig";
import AuthContext from "context/Authcontext";
import toast from "react-hot-toast";
import StripeSecure from "components/StripeSecure";
import RemoveSession from "components/RemoveSession";
import MDLoader from "components/MDLoader";
import Confirmation from "components/ConfirmDelete";
import Footer from "examples/Footer";
import Link from "@mui/material/Link";
import { FcGoogle } from "react-icons/fc";
import { FaLinkedin } from "react-icons/fa";
import ConfirmDelete from "components/ConfirmDelete";
function Cover() {
  const [rememberMe, setRememberMe] = useState(true);
  const {
    setIsAutheticated,
    verifyToken,
    isAutheticated,
    showClearSessionDialog,
    setShowClearSessionDialog,
    deleteMyAllJwtSessions,
    getMySubscription,
    isPro,
    handleSocialLogin,
    setSocial,
    social,
    deleteMyAllJwtSessionsBySocial,
    isLoading,
    setIsLoading,
    setGracePeriod,
    setPageType,
    recieveVerificationMail,
  } = useContext(AuthContext);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEmailVerificationPopup, setShowEmailVerificationPopup] = useState(false);
  const location = useLocation();

  const handleGoogleLogin = () => {
    handleSocialLogin(2);
    setSocial(2);
    setPageType("sign-in");
    localStorage.setItem("social", 2);
    localStorage.setItem("pageType", "sign-in");
  };

  const handleLinkedInLogin = () => {
    handleSocialLogin(1);
    setSocial(1);
    setPageType("sign-in");
    localStorage.setItem("social", 1);
    localStorage.setItem("pageType", "sign-in");
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("skoopCrmAccessToken");
    if (accessToken != null && !isAutheticated) {
      verifyToken(false);
    }
    if (isAutheticated && !isPro) {
      if (!location.pathname.includes("/subscription")) {
        navigate("/subscription");
      }
    }
    if (isAutheticated && accessToken) {
      navigate("/pages/profile/profile-overview");
    }
  }, [isAutheticated]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSocial(null);
    setShowEmailVerificationPopup(false);
    localStorage.removeItem("social");

    const data = new FormData(event.currentTarget);

    if (!data.get("email")) {
      toast.error("Please enter your email");
      return;
    }

    if (!data.get("password")) {
      toast.error("Please enter your password");
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: "POST",
        body: JSON.stringify({
          username: data.get("email"),
          password: data.get("password"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      let responseJson = await response.json();
      if (response.ok) {
        toast.success("Login Successfull");
        localStorage.setItem("skoopCrmAccessToken", JSON.stringify(responseJson.accessToken));
        localStorage.removeItem("userIsLoggedOut");
        setIsAutheticated(true);
        setGracePeriod(responseJson.gracePeriod);
        const subs = await getMySubscription();
        if (!subs) {
          navigate("/subscription");
        } else {
          navigate("/pages/account/messaging");
        }
      } else if (response.status == 401) {
        setShowClearSessionDialog(true);
        setUsername(data.get("email"));
        setPassword(data.get("password"));
      } else if (response.status == 402) {
        setUsername(data.get("email"));
        setPassword(data.get("password"));
        setShowEmailVerificationPopup(true);
        toast.error("Please verify your email to login.");
      } else {
        throw new Error(responseJson.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSessions = () => {
    if (!social) {
      deleteMyAllJwtSessions(username, password);
    } else {
      deleteMyAllJwtSessionsBySocial(social);
    }
  };

  const handleConfirmation = (confirm) => {
    if (confirm) {
      deleteSessions();
    }
    setShowConfirmationModal(false);
  };

  const handleEmailVerification = (confirm) => {
    if(confirm){
      setShowEmailVerificationPopup(false);
      recieveVerificationMail(username);
    }
  };

  useEffect(() => {
    const socialData = localStorage.getItem("social");
    if (socialData) {
      setSocial(socialData);
    }

    setTimeout(() => {
      const accessToken = localStorage.getItem("skoopCrmAccessToken");
    if (accessToken != null && !isAutheticated) {
      verifyToken(false);
    }
    }, 500)
  }, []);

  return (
    <>
      <ConfirmDelete
        title="Email Verification"
        message={
          <>
            <p>Email not verified.</p>
            <p>Please verify your email to continue. </p>
          </>
        }
        confirm={handleEmailVerification}
        showDialog={showEmailVerificationPopup}
        closeButtonText={"Cancel"}
        confirmButtonText={"Send Link"}
      />
      {isLoading && <MDLoader />}
      <Confirmation
        title="Delete Sessions?"
        message="Are you sure you want to delete all sessions?"
        confirm={handleConfirmation}
        showDialog={showConfirmationModal}
      />
      <CoverLayout image={skoopCrmBanner} buttonText={"Sign Up"}>
        <Card>
          <MDBox sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}} px={3}>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={1}
              mb={2}
              textAlign="center"
              sx={{ width: { xs: "80%", md: "70%", lg: "60%" } }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white">
                Sign In
              </MDTypography>
            </MDBox>
          </MDBox>
          {showClearSessionDialog && (
            <MDBox px={3} mt={-1} mb={1}>
              <RemoveSession onDelete={() => setShowConfirmationModal(true)} />
            </MDBox>
          )}

          <Grid direction="column" container spacing={2} py={1} px={3}>
            <Grid item xs={12} lg={12}>
              <MDButton
                fullWidth
                variant="outlined"
                color="black"
                size="medium"
                sx={{ fontSize: "24px", padding: "15px 13px" ,fontWeight:500, textTransform: "none"}}
                onClick={handleGoogleLogin}
              >
                <FcGoogle style={{ transform: "scale(1.5)", marginRight: "22px" }} size={24} />
                Sign in with Google
              </MDButton>
            </Grid>
            <Grid item xs={12}>
              <MDButton
                fullWidth
                variant="outlined"
                color="black"
                size="medium"
                sx={{ fontSize: "24px", padding: "15px 13px" ,fontWeight:500, textTransform: "none" }}
                onClick={handleLinkedInLogin}
              >
                <FaLinkedin
                  style={{ transform: "scale(1.5)", marginRight: "13px"  }}
                  size={24}
                  className="linkedin-icon"
                />
                Sign in with LinkedIn
              </MDButton>
            </Grid>
          </Grid>
          <Divider />
          <MDTypography variant="h4" gutterBottom align="center">
            OR
          </MDTypography>
          <MDBox pb={1} px={3} sx={{ display: "flex", justifyContent: "center" }}>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSubmit}
              sx={{ width: { xs: "80%", md: "70%", lg: "50%" } }}
            >
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  name="email"
                  placeholder="john@example.com"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  name="password"
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              <MDBox mt={2}>
                <MDButton variant="outlined" color="black" fullWidth type="submit" sx={{ textTransform: "none"}}>
                  Or Sign in with Email
                </MDButton>
              </MDBox>
              <MDBox mt={0} mb={3} textAlign="center">
                <Link href="/authentication/reset-password">
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                    Reset Password
                    </MDTypography>
                </Link>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <StripeSecure />
      </CoverLayout>
    </>
  );
}

export default Cover;
