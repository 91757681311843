import { useContext, useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { Opacity } from "@mui/icons-material";
import { position } from "stylis";
function Loader() {
  useEffect(() => {
    // When the component mounts, hide the overflow
    document.body.style.overflow = 'hidden';

    // When the component unmounts, reset the overflow
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.5)", Opacity:0.6, position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999}}
    >
      <CircularProgress size="7rem" />
    </MDBox>
  );
}

export default Loader;
