import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

export default function MDSearch({ onChange, value, placeholder }) {
  return (
    <FormControl sx={{ m: 1, width: "15ch" }} variant="outlined">
      <InputLabel size="small" htmlFor="outlined-adornment-password">
        {placeholder}
      </InputLabel>
      <OutlinedInput
        size="small"
        id="outlined-adornment-password"
        type="text"
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon fontSize="small"/>
          </InputAdornment>
        }
        label={placeholder}
      />
    </FormControl>
  );
}
