import React, { useState } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDMenu from "components/MDMenu";
import { FaFilter } from "react-icons/fa";
import DropDownMenu from "../DropDownMenu";

const FilterMenu = ({ filteringParams, setFilteringParams,  roleOptions, subscriptionStatusOptions}) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  return (
    <MDBox>
      <MDMenu
        key={filteringParams}
        openMenu={showFilterMenu}
        setOpenMenu={setShowFilterMenu}
        menuWidth={192}
        triggerElement={
          <>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDBox
                className="icon-button cursor-pointer"
                sx={{ ml:{xs:0, sm:0, md:0.5}, display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start"} }}
              >
                <FaFilter color="white" size={15} />
              </MDBox>
            </MDBox>
          </>
        }
      >
        <MDBox>
          <Grid container sx={{ display: "flex", alignItems: "center" }} spacing={2}>
              <Grid item xs={12}>
              <DropDownMenu
              filteringParams={filteringParams}
              setFilteringParams={setFilteringParams}
              options={subscriptionStatusOptions}
              type="subscription_status"
            />
              </Grid>
              <Grid item xs={12}>
              <DropDownMenu
              filteringParams={filteringParams}
              setFilteringParams={setFilteringParams}
              options={roleOptions}
              type="role"
            />
              </Grid>
          </Grid>
        </MDBox>
      </MDMenu>
    </MDBox>
  );
};

export default FilterMenu;
