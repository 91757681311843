export const questions = [
  {
    id: 1,
    title: "AFFILIATE PROGRAM REGISTRATION",
    description:
      "To register for the Affiliate Program, the Recipient must complete and submit to the Owner an executed copy of this Agreement.",
  },
  {
    id: 2,
    title: "TERMINATION AFTER ACCEPTANCE",
    description:
      "Even after the Owner has accepted the Recipient as an Affiliate Program member, the Owner reserves the absolute right to rescind or terminate the Recipient’s affiliate status for any reason in its sole and absolute discretion, including but not limited to the following: (i) if the Recipient’s website contains images or content that is not acceptable to Owner or is inconsistent with the image that the Owner wishes to create in association with the Owner’s website, (ii) if the Recipient’s website contains any illegal, immoral, repulsive, defamatory, derogatory, harassing, harmful, threatening, obscene, vulgar, pornographic, or racially or ethnically objectionable materials, depicts sexual situations, promotes discrimination on the basis of race, sex, sexual preference, national origin, ethnicity, nationality, disability, or religious preference, or (iii) if the Recipient’s site contains any material that appears to Owner to violate any patent, trademark, copyright, trade secret, confidential information, or other property rights of any other party.",
  },
  {
    id: 3,
    title: "FINANCIAL RESPONSIBILITIES",
    description:
      "The Recipient will be fully responsible for all costs and expenses of maintaining and marketing the Affiliate Program, including but not limited to all costs associated with the creations, hosting, modification, and improvements to the Recipient’s website, costs of search engine placement and other Internet marketing, costs of inserting the Owner’s links into the Owner’s website, offline marketing costs, postage costs, and all other costs and expenses, and the Recipient hereby holds the Owner harmless from or against the same.",
  },
  {
    id: 4,
    title: "NO REPRESENTATIONS REGARDING INCOME POTENTIAL",
    description:
      "The Owner makes no representations and warranties regarding potential income that may result from participation in this Affiliate Program and specifically disclaims any and all warranties relative to earning potential from the Recipient affiliate status.",
  },
  {
    id: 5,
    title: "RESPONSIBILITY TO LINK TO THE OWNER’S SITE",
    description:
      "As an Affiliate Program member, the Recipient will have the obligations to place links on its site directing users to the Owner’s site. The Owner will make available to the Recipient button links, text links, and banner advertisements to be placed on the Recipient’s website which will direct users to Owner’s website via hypertext link. As an Affiliate Program member, the Recipient is given a non-exclusive, limited term license, during the term of the Recipient’s active participation as an Affiliate Program member, to utilize the Owner’s logo images provided to the Recipient on the Recipient’s website.",
  },
  {
    id: 6,
    title: "ANTI-SPAM POLICY",
    description:
      "The Owner strictly forbids the use of unsolicited commercial email (UCE) or SPAM campaigns. The Owner maintains a “Zero-Tolerance” policy against SPAM, be it direct, a third party or any affiliate of Recipient or similar agent acting on the Recipient's behalf. As such, the Owner reserves the right to terminate any violating Recipient’s account or any part thereof, without notice or compensation.",
  },
  {
    id: 7,
    title: "CUSTOMER SERVICE",
    description:
      "The Owner will be responsible for handling all customer inquiries, Merchandise orders, customer billing and collection, Merchandise shipment relative to customers that enter the Owner’s site through the links from the Recipient’s site. Pricing of the Owner’s Merchandise and services is solely within its discretion and the Owner reserves the right to change the pricing structure, terminate any special offers, discontinue Merchandise or services, or change the terms under which Merchandise or services are offered at any time, without any advanced notice to the Recipient or users accessing the Owner’s site. The Owner’s sole responsibility to the Recipient in this regard is to track customer orders that occur through links from the Recipient’s website and provide reports to the Recipient of the referral fees due to the Recipient as a result thereof. All such reports shall be un-audited. The Owner will have no obligation to provide the Recipient with any specific information relative to any customer, regardless of whether they access the Owner’s site through the link from the Recipient’s site.",
  },
  {
    id: 8,
    title: "COMPENSATION",
    description:
      "Referral fees will be payable to the Recipient based upon a percentage of sales made to users who access the Owner’s site through the Recipient’s site. Referral fees will be calculated based upon the gross sales price, but not including any shipping and handling, merchant fees, sales tax, special service fees such as gift wrapping or packaging, late charges, collection costs, imports/export duties, and any other payment made to the Owner that is not the purchase price for the Merchandise that is purchased. Referral fees will not be calculated based upon amounts that are attributable to credit card fraud, credits given to customers, bad debt right-off and returned goods. The Owner reserves the right to deduct in subsequent months for any referral fee that the Owner paid to Recipient that is for Merchandise that is subsequently returned or refunded, or for any other reason if the previous monthly referral fee was overpaid or later subject to reduction.",
  },
  {
    id: 9,
    title: "CUSTOMERS’ PROVENANCE",
    description:
      "All parties who make purchases through the Owner’s website, regardless of whether they may have reached the Owner’s website through the link from the Recipient’s website, are deemed to be the Owner’s customers and not the Recipient’s customers relative to the Owner’s Merchandise and services. The Owner will have the right to contact these customers and send future marketing offers to them. The Recipient will have no right to referral fees on subsequent purchases that may be made by these customers, except for subsequent purchases that may be traced at the time of purchase through a link from the Recipient’s website. Additionally, all such customers and purchases will be subject to the Owner’s policies, procedures, rules and regulations and the Recipient has no right or authority to amend or offer any different offers relative to the purchase of Merchandise from the Owner’s website. The Owner, however, reserves the right to amend any of its terms, conditions, policies, procedures, pricing, payment policies, collection policies, and all other items relative to the Owner’s business and sale of Merchandise at any time in its sole discretion.",
  },
  {
    id: 10,
    title: "TRADEMARKS AND COPYRIGHTS",
    description:
      "The Recipient will have a non-exclusive, limited term license to use the trademarks, logos, and copyrighted material that the Owner provided to the Recipient for use solely on the Recipient’s website, and any owned social media accounts, email programs and other marketing campaigns associated with said website or websites, that the Recipient indicates on Schedule B. The Recipient may only use the images that the Owner specifically makes available to Affiliate Program members at the area of the Owner’s website that is specifically designated as approved images. The Recipient may not distribute, reproduce, modify, or amend these images in any way. The Recipient may use these images only for the purposes of promoting the Owner’s website and Merchandise on the Recipient’s website in compliance with the Affiliate Program policies and procedures and the terms of this Agreement. The license so granted is subject to complete compliance with all terms and conditions of this Agreement and any policies the Owner may create and amend from time to time regarding the Affiliate Program.",
  },
  {
    id: 11,
    title: "MERCHANDISE AVAILABILITY",
    description:
      "The Owner cannot guarantee Merchandise availability or the term of any price or special promotion or offer.",
  },
  {
    id: 12,
    title: "RESPONSIBILITIES",
    description:
      "The Recipient is responsible for all matters pertaining to the Recipient’s website including its development, maintenance, operation and placing links on the Recipient’s site in compliance with the terms of the Affiliate Program. The Recipient is solely responsible for all items that appear on its site and for assuring that such items do not infringe upon or violate the rights of any other party. The Owner is not responsible for any matter pertaining to the Recipient’s site or the content thereof and the Recipient holds the Owner harmless and indemnifies the Owner from any and all claims, suits, threats, demands, liabilities, actions, causes of action related in any way to the Recipient’s website and business. Such indemnity includes the Owner’s costs and attorney fees in defending any such matter. The Recipient represents and warrants to the Owner that its site does not and will not contain any materials that are illegal and that the Recipient’s site is not operated for an illegal purpose or in an illegal manner. The Recipient further agrees to comply with all applicable laws, rule and regulations in connection with the performance of its obligations under this Agreement and that failure to comply therewith shall constitute a material breach of this Agreement for which the Owner may terminate this Agreement immediately.",
  },
  {
    id: 13,
    title: "REPRESENTATIONS AND WARRANTIES",
    description:
      "The Recipient hereby represents and warrants to the Owner to have the complete power and authority to enter into this Agreement and that this Agreement constitutes a valid and legally enforceable agreement. The entry of this Agreement has been duly and validly authorized by all necessary corporate or other organizational actions and approvals. The Recipient’s entry of this Agreement is not prohibited by the terms of any document, is not contrary to any law, rule or regulations, and is not in violation of any court or administrative order.",
  },
  {
    id: 14,
    title: "TERM",
    description:
      "The effectiveness of this Agreement shall not commence until the Recipient’s signature to this Agreement is accepted by the Owner. This Agreement shall remain in full force and effect until terminated by the Recipient or by the Owner. Either the Owner or the Recipient may terminate this Agreement at any time, with or without cause, by giving the other party written notice of termination in compliance with this Agreement. Notices sent hereunder shall be via Email to the Recipient at the Email address on Schedule B. Any and all notices to the Recipient via Email at such address shall be deemed to be effective notice to the Recipient for all purposes.",
  },
  {
    id: 15,
    title: "TERMINATION",
    description:
      "The Recipient will forfeit all right to receive past referral fees that may have accrued to the Recipient if this Agreement is terminated as a result of the Recipient’s failure to comply with the terms of this Agreement or any policies and procedures of the Affiliate Program that may be established and amended by the Owner in its discretion from time to time. If this Agreement is terminated for any other reason, the Recipient will have a right to receive its accrued referral fees through the effective date of termination; provided, that if the Recipient total referral fees due hereunder do not exceed the Minimum Referral Fee, such accrued referral fees shall be forfeited. The Owner has the right to withhold final referral fee payments for sufficient time in order to assure that the amount paid to the Recipient is accurate and not subject to later adjustment for returns or any other reason. If following final payment the Owner determines that the amount of referral fees that the Owner paid to Recipient that is for Merchandise that is subsequently returned or refunded, or for any other reason if the previous monthly referral fee was overpaid or later subject to reduction, the differential shall be a debt from the Recipient to the Owner and the Owner shall have all legal right to receive a refund of such overpaid referral fees from the Recipient.",
  },
  {
    id: 16,
    title: "MODIFICATIONS",
    description:
      "The Owner reserves the right in its sole and absolute discretion, to modify any terms and conditions of the Affiliate Program and the terms and conditions of this Agreement upon notice to the Recipient. Notice of any changes may be given via Email to the Recipient or by posting such changes in the Affiliate Program sections of the Owner’s website. Such changes and modifications will take effect upon transmission of Email or posting on the Owner’s website. The Recipient may terminate participation in the Affiliate Program in the event that any of these modifications are unacceptable to the Recipient and such termination shall be the Recipient’s sole and exclusive remedy. In the event that the Recipient continues to participate in the Affiliate Program following such modifications, the Recipient will be deemed by the Recipient’s continued participation to accept any and all such changes.",
  },
  {
    id: 17,
    title: "LIABILITIES",
    description:
      "THE OWNER HEREBY DISCLAIMS ANY AND ALL WARRANTIES AND LIABILITY RELATED TO ANY DOWNTIME OR FAILURE FOR USERS TO BE ABLE TO ACCESS THE OWNER’S WEBSITE OR TO ACCESS THE OWNER’S WEBSITE USING THE LINK FROM THE RECIPIENT’S WEBSITE. FURTHERMORE, THE OWNER SHALL NOT BE RESPONSIBLE FOR AND HEREBY DISCLAIMS ANY AND ALL WARRANTIES RELATED TO THE OWNER’S WEBSITE, THE AFFILIATE PROGRAM, THE RECIPIENT’S PARTICIPATION IN THE AFFILIATE PROGRAM, THE RECIPIENT’S ABILITY TO MAKE ANY REFERRAL FEES OR OTHERWISE PROFIT THROUGH PARTICIPATION IN THIS AFFILIATE PROGRAM, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF FITNESS FOR ANY PARTICULAR PURPOSE OR MERCHANTIBILITY, NON-INFRINGEMENT, OR ANY CLAIM MADE BASED UPON THE OWNER’S COURSE OF DEALING OR USAGE OF TRADE. THE OWNER DOES NOT REPRESENT OR WARRANT THAT THE OWNER’S WEBSITE OR ANY APPLICATION, INCLUDING BUT NOT LIMITED TO ITS LINK TRACKING FEATURES, WILL BE ERROR FREE OR THAT THE OWNER’S WEBSITE OR ANY APPLICATION WILL FUNCTION WITHOUT INTERRUPTION.",
  },
  {
    id: 18,
    title: "CONFIDENTIALITY",
    description:
      "In the event that any information is disclosed to the Recipient through the Recipient participation in the Affiliate Program related in any way to the Owner and its business which the Owner deems to be confidential and proprietary, the Recipient agrees to hold such information in the strictest of confidence and not to disclose such information to any other party or to use any such information for the Recipient’s own purposes. Confidential information will include any information regarding the Owner’s changes or modifications to this Agreement or this Affiliate Program (which the Owner shall have no obligation to make) or any special treatment that the Recipient may receive (which the Owner reserves the right to provide in its sole discretion to any affiliate). Confidential information shall also include any and all information related to the Owner’s business, business plans, marketing plans, user statistics, financial information, pricing, profits, membership information, affiliations, sales information, and all other information which the Owner considers to be confidential and proprietary.",
  },
  {
    id: 19,
    title: "INDEMNIFICATION",
    description:
      "The Recipient hereby indemnifies and holds the Owner, and all of the Owner stockholders, officers, directors, employees, contractors, affiliates, agents, successors and assigns harmless from and against any and all claims, liabilities, damages, actions, causes of action, suits, threats, demands, settlements, including all costs and attorney fees related thereto, that the Owner may incur and which are based in whole or in part upon the Recipient’s participation in the Affiliate Program, any claims that any of the Recipient’s trademarks and other proprietary material infringe upon the rights of any other party, the Recipient’s breach of any term, covenants, condition, representation or warranty contained in this Agreement or any policies of participation in the Affiliate Program, or any claim related directly or indirectly to the Recipient’s use, operation or the content of the Recipient’s website.",
  },
  {
    id: 20,
    title: "GOVERNING LAW",
    description:
      "This Agreement shall be interpreted under the laws of state of Delaware. Any and all legal actions relative hereto shall be in the state and federal courts of Delaware.",
  },
  {
    id: 21,
    title: "RELATIONSHIP OF THE PARTIES",
    description:
      "The parties hereto are independent contractors and nothing contained herein shall be interpreted as creating any relationship other than that of independent contracting parties. The parties shall not be construed as being partners, joint ventures, shareholders, employer/employee, agent/servant. Recipient has no power or authority to bind the Owner to any obligation, agreement, debt or liability. The Recipient shall not hold itself out as an agent or representative of the Owner.",
  },
  {
    id: 22,
    title: "NOTICES",
    description:
      "Notices to the Owner shall be by certified mail, return receipt requested addressed to the address contained in this Agreement, with a copy (that shall not constitute notice) to Troy Hipolito 10111 Eden Mountain St., Las Vegas NV 89141, United States, or such other address that the Owner provides notice of to the Recipient via Email or by posting the same on the Affiliate section of the Owner website. Notices to the Recipient shall be by Email addressed to the Email address that the Recipient provided to the Owner on Schedule B or by posting such notices on the Affiliate section of the Owner’s website. It shall be the Recipient responsibility to check the Affiliate section of the Owner website periodically to monitor all notices set forth thereon.",
  },
  {
    id: 23,
    title: "ASSIGNMENT",
    description:
      "This Agreement is only for the benefit of the Recipient. The Recipient shall have not right to assign this Agreement or any benefits or obligation hereunder to any other party or legal entity. Any attempted assignment shall be void.",
  },
  {
    id: 24,
    title: "ENTIRE AGREEMENT",
    description:
      "This Agreement sets forth the entire agreement and understanding between the parties with respect to the Affiliate Program and supersedes any and all prior discussions, understandings, agreements, representations, warranties or covenants between the parties related to the Affiliate Program only. This Agreement may only be amended by a writing signed by the authorized representative of each of the parties, except as otherwise set forth herein. Any waiver of a breach or default under this Agreement shall not constitute a waiver of any subsequent or other breach or default and shall not serve to modify the agreements set forth herein. If any provision or term of this Agreement is held to be invalid for any reason, it shall not affect the enforceability of the remainder of this Agreement or any other term or condition of this Agreement.",
  },
];
