import React from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/crmimages/bg-reset-cover.jpeg";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function Cover() {
  const [step, setStep] = React.useState(1);
  const [email, setEmail] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [OTP, setOTP] = React.useState("");

  const navigate = useNavigate();

  const getOtpForPasswordReset = async (username) => {
    try {
      console.log("Sending OTP request...");
      const responseCode = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/getOtpForPasswordReset?${new URLSearchParams({
          username: username,
        })}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      if (responseCode.ok) {
        console.log("OTP request successful");
        return true;
      } else {
        console.error("OTP request failed with status:", responseCode.status);
        return false;
      }
    } catch (err) {
      console.error("Error occurred in getting the OTP for password reset", err);
      return false;
    }
  };

  const resetPasswordUsingOtp = async (username, otp, newPassword) => {
    try {
      const responseCode = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/resetPasswordUsingOtp`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            username: username,
            otp: otp,
            newPassword: newPassword,
          }),
        }
      );
      if (responseCode.ok) return true;
      else return false;
    } catch (err) {
      console.log("could not make the call to reset password", err);
      return false;
    }
  };

  const handleSubmitForm1 = async (e) => {
    e.preventDefault();
    const isOtpSent = await getOtpForPasswordReset(email);
    if (!isOtpSent) {
      console.log("Could not send OTP", "error");
      return;
    }
    console.log("OTP sent successfully");
    setStep(2);
  };

  const handleSubmitForm2 = async (e) => {
    e.preventDefault();
    if (confirmPassword !== newPassword) {
      console.log("Confirm password and new password fields do not match", "error");
      toast.error("Password and Confirm password don't match!");
      return;
    }
    const isPasswordReset = await resetPasswordUsingOtp(email, OTP, newPassword);
    if (!isPasswordReset) {
      toast.error("Password reset failed");
    } else {
      navigate("/pages/account/messaging");
      toast.success("Password reset successful", "success");
    }
  };

  return (
    <CoverLayout coverHeight="40vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {step === 1 && (
            <MDBox component="form" role="form" onSubmit={handleSubmitForm1}>
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Next
                </MDButton>
              </MDBox>
            </MDBox>
          )}

          {step === 2 && (
            <MDBox component="form" role="form" onSubmit={handleSubmitForm2}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Enter OTP"
                  variant="standard"
                  fullWidth
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                  required
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Enter New Password"
                  variant="standard"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Confirm Password"
                  variant="standard"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </MDBox>
              <MDBox mt={2} mb={1}>
                <MDButton variant="gradient" type="submit" color="info" fullWidth>
                  Submit
                </MDButton>
              </MDBox>
              <MDBox mt={2} display="flex" justifyContent="space-between">
                <MDButton onClick={handleSubmitForm1} color="info" fullWidth>
                  Send OTP Again
                </MDButton>
                <MDBox mx={1}></MDBox>
                <MDButton onClick={() => setStep(1)} color="dark" fullWidth>
                  Back
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
